import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectCasesByExamSectionAndTopicId, selectDetailExam } from 'src/app/redux/selectors/app.selectors'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
})
export class TopicsComponent implements OnInit {
  cases$!: Observable<any>
  detailExam!: any
  examId = localStorage.getItem('examID') || ''
  itemSectionID = localStorage.getItem('itemSectionID') || ''
  itemTeilID = localStorage.getItem('itemTeilID') || ''

  constructor(
    private store: Store,
    private pageStateService: PageStateService
  ) {}

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('breadcrumbs')
    if (this.examId && this.itemTeilID && this.itemSectionID) {
      this.cases$ = this.store.select(selectCasesByExamSectionAndTopicId(this.examId, this.itemSectionID, this.itemTeilID))
      this.store.select(selectDetailExam(this.examId, this.itemSectionID, this.itemTeilID)).subscribe(val => (this.detailExam = val))
    }
  }
}
