import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-faq-block',
  templateUrl: './faq-block.component.html',
  styleUrls: ['./faq-block.component.scss'],
})
export class FaqBlockComponent implements OnInit {
  @Input() item!: any

  ngOnInit(): void {}
}
