<div class="container">
  <div class="nav">
    <div class="header">
      <h5>{{ conversationInfo.case_title }}</h5>
      <p>{{ conversationInfo.exam_title }}</p>
      <p>{{ conversationInfo.section_title }}</p>
      <p>{{ conversationInfo.topic_title }}</p>
    </div>

    <!-- <div class="date">
      <h6>Datum:</h6>
      <div class="date-nav">
        <p>7.02.24</p> Нужно подставить [routerLink]="['/result', id]" 
        <p class="active">7.02.24</p>
        <p>7.02.24</p>
        <p>6.02.24</p>
        <p>5.02.24</p>
        <p>1.02.24</p>
        <p>1.02.24</p>
        <p>31.01.24</p>
        <p >31.01.24</p>
        <p>31.01.24</p>
        <p>27.12.23</p>
        <p>27.12.23</p>
        <p>27.12.23</p>
        <p>27.12.23</p>
        <p>27.12.23</p>
      </div>
    </div> -->
  </div>

  <div class="content">
    <app-custom-scroll class="conversation" appScrollToBottom>
      <div *ngIf="conversationText || conversationDialog" class="audio-converstion-antwort">
        <ng-container *ngIf="conversationType === 'audio_dialog'">
          <div *ngFor="let message of conversationDialog" class="audioDialog">
            <div [ngClass]="{ user: message.role === 'user', assistant: message.role === 'assistant' }">
              {{ message.content }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="conversationType === 'text_single'">
          <app-conversation-answer class="result-text">
            <div class="antwort" *ngFor="let pharagraph of conversationText | splitParagraphs">{{ pharagraph }}</div>
          </app-conversation-answer>
        </ng-container>
      </div>
    </app-custom-scroll>
  </div>
</div>
