import { Component, ElementRef, ViewChild } from '@angular/core'
import { ScrollService } from '../../services/scroll/scroll.service'

@Component({
  selector: 'app-custom-scroll',
  templateUrl: './custom-scroll.component.html',
  styleUrls: ['./custom-scroll.component.scss'],
})
export class CustomScrollComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef

  askedQuestions!: any

  constructor(public scrollService: ScrollService) {
    this.scrollService.isScroll.subscribe(val => {
      if (val && this.scrollContainer.nativeElement.parentElement.className === 'conversation') {
        this.scrollTo()
      }
    })
  }

  scrollTo(val?: string) {
    const container = this.scrollContainer.nativeElement
    const heightTopic: number = 80

    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      })
    }, 100)

    this.scrollService.changeScroll(false)
  }
}
