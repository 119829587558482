import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { AuthService } from '../../../../services/auth.service'
import { ForbiddenPasswordValidator } from '../../../../directives/forbidden-password.directive'
import { checkboxesValidator } from '../../../../directives/checkbox-validation.directive'
import { passwordMatchValidator } from '../../../../directives/password-match.directive'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  registrationForm!: FormGroup
  isVisiblePassword = false
  isVisibleRepeatPassword = false

  constructor(private authService: AuthService) {
    this.registrationForm = new FormGroup(
      {
        userName: new FormControl('', [Validators.required]),
        userLastName: new FormControl('', [Validators.required]),
        userEmail: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, ForbiddenPasswordValidator()]),
        passwordRepeat: new FormControl('', [Validators.required]),
        captcha: new FormControl(false, [Validators.required]),
        agreement: new FormControl(false, [Validators.required]),
      },
      { validators: [passwordMatchValidator('password', 'passwordRepeat'), checkboxesValidator()] }
    )
  }

  get userName() {
    return this.registrationForm.get('userName')
  }

  get userLastName() {
    return this.registrationForm.get('userLastName')
  }

  get userEmail() {
    return this.registrationForm.get('userEmail')
  }

  get password() {
    return this.registrationForm.get('password')
  }

  get confirmPassword() {
    return this.registrationForm.get('passwordRepeat')
  }

  get captcha() {
    return this.registrationForm.get('captcha')
  }

  get agreement() {
    return this.registrationForm.get('agreement')
  }

  toggleVisiblePassword() {
    this.isVisiblePassword = !this.isVisiblePassword
  }

  toggleVisibleRepeatPassword() {
    this.isVisibleRepeatPassword = !this.isVisibleRepeatPassword
  }

  submit() {
    if (this.registrationForm.valid) {
      console.log(this.registrationForm.value)
      this.authService.registration(this.registrationForm.value)
      //redirect to verify
    }
  }

  canShowCustomErrors() {
    if (this.password && this.password.errors) {
      const keys = Object.keys(this.password.errors)
      return !keys.includes('required')
    }
    return false
  }

  showPasswordErrors() {
    const errors = this.password?.errors
    if (errors) {
      const keys = Object.keys(errors)
      const customKeys = keys.filter(elem => elem !== 'required')
      const message = customKeys.map(elem => {
        return errors[elem].message
      })
      return message.join(', ')
    }
    return ''
  }
}
