<div class="item-header" *ngIf="item">
  <h5>{{ item.case_title | truncate: 25 }}</h5>
  <span *ngIf="item.conversation_type === 'audio_dialog'; else schreiben" class="material-symbols-outlined">mic</span>
  <ng-template #schreiben>
    <span class="material-symbols-outlined">edit</span>
  </ng-template>
</div>

<div class="content">
  <div class="text">
    <p>{{ item.topic_title }}</p>
    <p>{{ item.exam_title }}</p>
  </div>
  <div class="date">{{ item.started_at | date }}</div>
</div>
