import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable, switchMap, take } from 'rxjs'
import {
  selectAllQuestions,
  selectAnswersForCase,
  selectAntwortByCaseId,
  selectAskedQuestions,
  selectIsOption,
  selectIsVisiblePreloader,
  selectTypeConversation,
  selectUserData,
} from '../../../redux/selectors/app.selectors'
import { ExamService } from '../../pages/levels/service/exam.service'
import * as examsActions from '../../../redux/actions/exams.actions'
import { ScrollService } from '../../../shared/services/scroll/scroll.service'
import { Answer } from '../../../redux/state.models'
import { ChatService } from './services/chat.service'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  caseId!: string

  cases$!: Observable<any>
  examId = localStorage.getItem('examID') || ''
  itemSectionID = localStorage.getItem('itemSectionID') || ''
  itemTeilID = localStorage.getItem('itemTeilID') || ''

  userAnswers: string[] = []
  answersCase: Answer[] = []
  answerForm!: FormGroup

  existingQuestions!: any[]
  currentQuestionIndex = 0
  questionIforAnswer = ''
  typeConversation: string = ''

  currentAnswer = ''
  askedQuestions$!: Observable<any>
  isOption$!: Observable<any>
  isQuestions: boolean = true
  userId!: string
  text: string = ''
  antwortText$!: Observable<any>

  extraInfo!: any[]

  isVisiblePreloader$!: Observable<boolean>

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    private examService: ExamService,
    private router: Router,
    private scrollService: ScrollService,
    private chatService: ChatService
  ) {
    this.store.select(selectTypeConversation).subscribe(val => (this.typeConversation = val))
    this.store.select(selectUserData).subscribe(s => (this.userId = s.userId))
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.caseId = params.get('id') || ''
      localStorage.setItem('caseId', this.caseId)
      this.chatService.caseId.next(this.caseId)

      if (this.caseId) {
        this.loadExtraInfo(this.caseId)
        this.askedQuestions$ = this.store.select(selectAskedQuestions(this.caseId))
      }
      this.isVisiblePreloader$ = this.store.select(selectIsVisiblePreloader())
      this.antwortText$ = this.store.select(selectAntwortByCaseId(this.caseId))
      this.initForm()

      setTimeout(() => {
        this.scrollService.changeScroll(true)
      }, 1000)
    })
  }

  initForm(): void {
    this.answerForm = this.fb.group({
      answer: ['', Validators.required],
    })
  }

  loadExtraInfo(caseId: string): void {
    this.store.dispatch(examsActions.loadingPreloader({ isVisible: false }))
    this.store.select(selectAllQuestions(caseId)).subscribe(questions => {
      if (questions) {
        this.existingQuestions = questions
        this.isOption$ = this.store.select(selectIsOption(this.caseId))
      }
      if (questions.length === 0) {
        this.isQuestions = false
      }
    })

    if (!this.existingQuestions || this.existingQuestions.length === 0) {
      this.examService.getExtraInfo(caseId).subscribe(
        res => {
          this.store.dispatch(examsActions.setAllQuestions({ caseId: caseId, questions: res }))
        },
        error => {
          console.error('Error loading extra info:', error)
        }
      )
    }
  }

  writeAnswer(answer: string) {
    this.askedQuestions$
      .pipe(
        take(1),
        switchMap(arr => {
          const lastObj = arr[arr.length - 1]
          this.questionIforAnswer = lastObj.id

          return this.store.select(selectAnswersForCase())
        })
      )
      .subscribe(answers => {
        this.answersCase = answers

        this.store.dispatch(
          examsActions.addQuestionAnswer({
            caseId: this.caseId,
            questionId: this.questionIforAnswer,
            answer,
          })
        )

        setTimeout(() => {
          this.store.dispatch(examsActions.setNextQuestion({ caseId: this.caseId }))
          this.isOption$ = this.store.select(selectIsOption(this.caseId))

          this.scrollService.changeScroll(true)
        }, 1000)
      })
  }

  sendAnswer(): void {
    if (this.answerForm.valid) {
      const answer = this.answerForm.get('answer')?.value

      this.writeAnswer(answer)
      this.answerForm.reset()
    }
  }

  startConversation() {
    console.log('start conversation')

    if (this.typeConversation === 'text_single') {
      this.store.select(selectAskedQuestions(this.caseId)).subscribe(res => {
        if (res) {
          this.extraInfo = res.map(item => {
            if (item) {
              return { title: item.title, answer: item.answer }
            } else {
              // проверить нужно ли это вообще
              this.extraInfo = []
              return this.extraInfo
            }
          })
        }
      })

      if (this.extraInfo) {
        this.store.dispatch(examsActions.loadingPreloader({ isVisible: true }))
        this.examService.startConversation(this.userId, this.caseId, this.extraInfo).subscribe(res => {
          if (res) {
            // this.text = res.message
            this.store.dispatch(examsActions.setConversationsAntwort({ caseId: this.caseId, antwort: res.message }))
            this.antwortText$ = this.store.select(selectAntwortByCaseId(this.caseId))
            this.store.dispatch(examsActions.loadingPreloader({ isVisible: false }))
          }
        })
      }

      // this.router.navigate(['/text', this.caseId]);
    } else {
      this.router.navigate(['/audio', this.caseId])
    }
  }

  sendAnswerFromButtons(option: string) {
    const answer = option.trim().slice(1, -1)

    this.writeAnswer(answer)
  }

  resetConversation() {
    this.store.dispatch(examsActions.resetConversation({ caseId: this.caseId }))
    this.store.dispatch(examsActions.setConversationsAntwort({ caseId: this.caseId, antwort: '' }))
    this.loadExtraInfo(this.caseId)
  }
}
