import { Component, Input, OnInit } from '@angular/core'
import { IResultItem } from 'src/app/redux/state.models'

@Component({
  selector: 'app-result-item',
  templateUrl: './result-item.component.html',
  styleUrls: ['./result-item.component.scss'],
})
export class ResultItemComponent implements OnInit {
  @Input() item!: IResultItem

  ngOnInit(): void {}
}
