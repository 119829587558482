import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ForbiddenPasswordValidator } from '../../../../directives/forbidden-password.directive'
import { passwordMatchValidator } from '../../../../directives/password-match.directive'

@Component({
  selector: 'app-save-password',
  templateUrl: './save-password.component.html',
  styleUrls: ['./save-password.component.scss'],
})
export class SavePasswordComponent {
  addNewPasswordForm!: FormGroup
  isVisiblePassword = false
  isVisibleRepeatPassword = false

  constructor() {
    this.addNewPasswordForm = new FormGroup(
      {
        password: new FormControl('', [Validators.required, ForbiddenPasswordValidator()]),
        passwordRepeat: new FormControl('', [Validators.required]),
      },
      { validators: [passwordMatchValidator('password', 'passwordRepeat')] }
    )
  }

  get password() {
    return this.addNewPasswordForm.get('password')
  }

  get confirmPassword() {
    return this.addNewPasswordForm.get('passwordRepeat')
  }

  toggleVisiblePassword() {
    this.isVisiblePassword = !this.isVisiblePassword
  }

  toggleVisibleRepeatPassword() {
    this.isVisibleRepeatPassword = !this.isVisibleRepeatPassword
  }

  submit() {
    if (this.addNewPasswordForm.valid) {
      console.log(this.addNewPasswordForm.value)

      // Запрос на сохранение пароля
    }
  }

  resetForm(): void {
    this.addNewPasswordForm.reset()
  }
}
