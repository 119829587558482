<!-- <div class="back-container">
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
    <path d="M15 1.75L4 15.6651L15 29.25" stroke="#2E294E" stroke-width="5" />
  </svg>
  <span class="btn-back" (click)="goBack()" (keyup)="goBack()" tabindex="1">Back</span>
</div> -->

<div class="wrapper">
  <router-outlet></router-outlet>

  <!-- <div class="exam-container">
    <app-exams-component class="conversation-page"></app-exams-component>
  </div> -->
</div>
