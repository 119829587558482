import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

// import { ContactsHelpComponent } from './contacts-help/contacts-help.component';
import { FaqComponent } from './faq/faq.component'
import { ContactComponent } from './contact/contact.component'

import { ContactsRoutingModule } from './contacts-routing.module'
// import { AuthGuard } from 'src/app/guards/auth.guard';
import { SharedModule } from 'src/app/shared/shared.module'

@NgModule({
  declarations: [
    // ContactsHelpComponent,
    FaqComponent,
    ContactComponent,
  ],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, ContactsRoutingModule, SharedModule],
  // providers: [AuthGuard]
})
export class ContactsModule {}
