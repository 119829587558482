import { Component } from '@angular/core'
import { Subscription, timer } from 'rxjs'
import { TimerService } from '../../services/timer.service'

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent {
  private msec30: number = 30000 // 30 секунд
  private msec10: number = 10000 // 10 секунд

  public countDown: number | undefined = 600000 // 10 минут в миллисекундах
  public is30: boolean = false
  public is10: boolean = false

  constructor(public timerService: TimerService) {
    this.timerService.timerSubject.subscribe(val => {
      if (val === this.msec30) {
        this.is30 = true
      }
      if (val === this.msec10) {
        this.is10 = true
      }

      if (val === 0) {
        this.is10 = false
        this.is30 = false
      }
      this.countDown = val
    })
  }
}
