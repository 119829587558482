<!-- <app-breadcrumb class="conversation-breadcrumb"></app-breadcrumb> -->
<div class="container">
  <div class="topics-container">
    <div class="input-wrapper">
      <span class="search"></span>
      <input class="topics-input" type="text" placeholder="Suchen" [(ngModel)]="valuesSearch" (ngModelChange)="changeSearch(valuesSearch)" />
    </div>
    <div class="topics-wrapper">
      <div #scrollContainer class="scroll-container">
        <div *ngIf="cases$">
          <div *ngFor="let case of cases$ | async | search: settingsSearchValue">
            <app-thema-item-component #themaItem [ngClass]="{ 'conversation-topics': true, activeTopics: case.id === caseId }" [item]="case" (click)="changeConversation(case.id)">
            </app-thema-item-component>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="conversation-container">
    <router-outlet></router-outlet>
  </div>

  <div class="exam-container">
    <app-exams-component [detailData]="detailExam" class="conversation-page"></app-exams-component>
  </div>
</div>
