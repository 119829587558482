import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-conversation-result',
  templateUrl: './conversation-result.component.html',
  styleUrls: ['./conversation-result.component.scss'],
})
export class ConversationResultComponent implements OnInit {
  constructor(
    private location: Location,
    private pageStateService: PageStateService
  ) {}

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('back')
  }

  goBack(): void {
    this.location.back()
  }
}
