import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IExamsFull } from '../../models/levels.model'
import { environment } from 'environment'

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  httpOptions = {
    withCredentials: true,
  }

  private mainApiEndpoint = environment.backend.baseUrl + ''

  constructor(private http: HttpClient) {}

  getData(): Observable<IExamsFull[]> {
    return this.http.get<IExamsFull[]>(`${this.mainApiEndpoint}/exams/full`, this.httpOptions)
  }

  getExtraInfo(caseId: string): Observable<any> {
    // return this.http.get(`${this.apiUrlExtra}/${caseId}/extras`, this.httpOptions)
    return this.http.get(`${this.mainApiEndpoint}/cases/${caseId}/extras`, this.httpOptions)
  }

  startConversation(userId: string, caseId: string, extraInfo: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('accept', 'application/json').set('Content-Type', 'application/json')

    const options = { headers: headers, withCredentials: true }
    console.log(extraInfo[0].length)
    if (extraInfo[0].length === 0) {
      extraInfo = []
    }

    const body = {
      user_id: userId,
      case_id: caseId,
      // "extra_info_json": "[]"
      extra_info_json: JSON.stringify(extraInfo),
    }

    return this.http.post(`${this.mainApiEndpoint}/conversations/`, body, options)
  }

  startAudioConversation(userId: string, caseId: string, extraInfo: any): Observable<any> {
    const body = {
      user_id: userId,
      case_id: caseId,
      extra_info_json: JSON.stringify(extraInfo),
    }

    return this.http.post(`${this.mainApiEndpoint}/conversations/audio/`, body, this.httpOptions)
  }

  sendAudioMessage(idConversation: string, audioMessage: string) {
    const headers: HttpHeaders = new HttpHeaders().set('accept', 'application/json').set('Content-Type', 'application/json')

    const body = {
      id: idConversation,
      audio: audioMessage,
    }
    return this.http.post(`${this.mainApiEndpoint}/conversations/audio/${idConversation}`, body, {
      headers,
    })
  }

  stopAudioConversation(idConversation: string) {
    const headers: HttpHeaders = new HttpHeaders().set('accept', 'application/json')

    return this.http.put(`${this.mainApiEndpoint}/conversations/${idConversation}/stop/`, {
      headers,
    })
  }

  getAllConversation(userId: string): Observable<any> {
    return this.http.get(`${this.mainApiEndpoint}/users/${userId}/conversations`, this.httpOptions)
  }

  getOneConversation(conversationId: string): Observable<any> {
    return this.http.get(`${this.mainApiEndpoint}/conversations/${conversationId}`, this.httpOptions)
  }

  authMe(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('accept', 'application/json')

    return this.http.get(`${this.mainApiEndpoint}/users/me`, { headers })
  }
}
