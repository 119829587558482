import { Component, OnInit } from '@angular/core'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-contacts-help',
  templateUrl: './contacts-help.component.html',
  styleUrls: ['./contacts-help.component.scss'],
})
export class ContactsHelpComponent implements OnInit {
  constructor(private pageStateService: PageStateService) {}

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('')
  }
}
