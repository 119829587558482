import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './lernberg/pages/home/home.component'
import { LevelsComponent } from './lernberg/pages/levels/levels.component'
import { TopicsComponent } from './lernberg/pages/topics/topics.component'
import { ConversationComponent } from './lernberg/pages/conversation/conversation.component'
import { DetailsexamComponent } from './lernberg/pages/detailsexam/detailsexam.component'
import { AudioComponent } from './lernberg/pages/audio/audio.component'
import { TeilsComponent } from './lernberg/components/teils/teils.component'
import { RatesComponent } from './lernberg/pages/rates/rates.component'
import { TextComponent } from './lernberg/pages/text/text.component'
import { ChatComponent } from './lernberg/components/chat/chat.component'
import { ResultsComponent } from './lernberg/pages/results/results.component'
import { ConversationResultComponent } from './lernberg/pages/conversation-result/conversation-result.component'
import { ChatResultComponent } from './lernberg/components/chat-result/chat-result.component'
// import { AuthGuard } from './guards/auth.guard'
import { WelcomeComponent } from './lernberg/pages/welcome/welcome.component'
import { UserProfileComponent } from './lernberg/pages/user-profile/user-profile.component'
import { UserInfoComponent } from './lernberg/pages/user-info/user-info.component'
import { NotFoundComponent } from './lernberg/pages/not-found/not-found.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'exams',
    component: LevelsComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'skills',
    component: DetailsexamComponent,
    children: [
      {
        path: ':skillId',
        component: TeilsComponent,
      },
    ],
  },
  {
    path: 'topics/:id',
    component: TopicsComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'conversation',
    component: ConversationComponent,
    // canActivate: [AuthGuard],
    children: [{ path: ':id', component: ChatComponent }],
  },
  {
    path: 'results',
    component: ResultsComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'result',
    component: ConversationResultComponent,
    // canActivate: [AuthGuard],
    children: [{ path: ':id', component: ChatResultComponent }],
  },
  {
    path: 'audio/:id',
    component: AudioComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'rates',
    component: RatesComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'text/:id',
    component: TextComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'contacts',
    loadChildren: () => import('./features/contacts/contacts.module').then(m => m.ContactsModule),
  },
  {
    path: 'rechtliches',
    loadChildren: () => import('./features/juridical/rechtliches.module').then(m => m.RechtlichesModule),
  },
  {
    path: 'user',
    component: UserInfoComponent,
    // canActivate: [AuthGuard],
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '**', component: NotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
