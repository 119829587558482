<div class="blob">
  <div class="filter-circle"></div>
</div>

<div class="square-container">
  <div class="square">
    <div class="circle1"></div>
    <div class="circle2"></div>
  </div>
</div>
