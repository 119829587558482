import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'splitParagraphs',
})
export class SplitParagraphsPipe implements PipeTransform {
  transform(value: string): string[] {
    if (!value) {
      return []
    }
    return value.split('\n\n')
  }
}
