import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-rates-item',
  templateUrl: './rates-item.component.html',
  styleUrls: ['./rates-item.component.scss'],
})
export class RatesItemComponent implements OnInit {
  @Input() item?: any
  isOrganization: boolean = false

  ngOnInit() {
    this.isOrganization = this.item.id === 'Organization'
  }
}
