import { Component, OnInit } from '@angular/core'
import { PageStateService } from 'src/app/lernberg/pages/conversation/service/page-state.service'

@Component({
  selector: 'app-rehtliches',
  templateUrl: './rehtliches.component.html',
  styleUrls: ['./rehtliches.component.scss'],
})
export class RechtlichesComponent implements OnInit {
  constructor(private pageStateService: PageStateService) {}

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('')
  }
}
