import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectTopicsByExamAndSectionId } from 'src/app/redux/selectors/app.selectors'
import * as examsActions from '../../../redux/actions/exams.actions'
import { ITopic } from 'src/app/redux/state.models'

@Component({
  selector: 'app-teils',
  templateUrl: './teils.component.html',
  styleUrls: ['./teils.component.scss'],
})
export class TeilsComponent implements OnInit {
  teil$!: Observable<any> | null
  isTeilSelected = false
  activeTheilBlock: string | null = null
  sectionId!: string
  itemId!: string
  skillId!: string

  @Input() id!: string | null

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.itemId = localStorage.getItem('examID') || ''
    this.route.params.subscribe(params => {
      this.skillId = params['skillId'] || ''

      if (this.itemId && this.skillId) {
        this.teil$ = this.store.select(selectTopicsByExamAndSectionId(this.itemId, this.skillId))
      }
    })
  }

  isSelectedTeil(itemTeilID: string) {
    this.isTeilSelected = true
    localStorage.setItem('itemTeilID', itemTeilID)

    this.activeTheilBlock = this.activeTheilBlock === itemTeilID ? null : itemTeilID
    this.teil$?.subscribe(teil => {
      if (teil) {
        const selectedTeil = teil.filter((t: ITopic) => t.id === itemTeilID)
        this.store.dispatch(examsActions.setTypeConversation({ conversation: selectedTeil[0].conversation_type }))
      }
    })
    this.router.navigate(['/topics', itemTeilID], { relativeTo: this.route.root })
  }
}
