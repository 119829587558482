export const faqData: any = [
  {
    id: '1111',
    title: 'Ich verstehe nichts',
    text: 'Pizza bell red pineapple roll pesto thin ham onions sautéed. Dolor string steak steak banana thin lot style. Hand sauce green wing Philly fresh tossed marinara pepperoni anchovies. Bianca sautéed cheese black pie melted NY party chicken. Chicken lasagna sautéed tomato pineapple tomato wing large sausage. Pizza meatball chicken thin extra mayo. Tomato garlic anchovies rib ham garlic. Ricotta green extra cheese beef pizza NY lovers. NY sausage broccoli pan ricotta pepperoni peppers marinara. Hand an',
  },
  {
    id: '2111',
    title: 'Ich verstehe nichts',
    text: 'Pizza bell red pineapple roll pesto thin ham onions sautéed. Dolor string steak steak banana thin lot style. Hand sauce green wing Philly fresh tossed marinara pepperoni anchovies. Bianca sautéed cheese black pie ',
  },
  {
    id: '3111',
    title: 'Ich verstehe nichts',
    text: 'Chicken lasagna sautéed tomato pineapple tomato wing large sausage. Pizza meatball chicken thin extra mayo. Tomato garlic anchovies rib ham garlic. Ricotta green extra cheese beef pizza NY lovers. NY sausage broccoli pan ricotta pepperoni peppers marinara. Hand and peppers dolor ranch crust onions green peppers ricotta.',
  },
  {
    id: '4111',
    title: 'Ich verstehe nichts',
    text: 'hytrgdesaw htegrefwadw gbrhjkasl btrnskLAJ jhbdfksvclzxzZ jhdfisodzk egiuwrjfoadsKZ4',
  },
  {
    id: '5111',
    title: 'Ich verstehe nichts ',
    text: 'Chicken lasagna sautéed tomato pineapple tomato wing large sausage. Pizza meatball chicken thin extra mayo. Tomato garlic anchovies rib ham garlic. Ricotta green extra cheese beef pizza NY lovers. NY sausage broccoli pan ricotta pepperoni peppers marinara. Hand and peppers dolor ranch crust onions green peppers ricotta.',
  },
  {
    id: '6111',
    title: 'Ich verstehe nichts',
    text: 'hytrgdesaw htegrefwadw gbrhjkasl btrnskLAJ jhbdfksvclzxzZ jhdfisodzk egiuwrjfoadsKZ4',
  },
  {
    id: '7111',
    title: 'Ich verstehe nichts',
    text: 'hytrgdesaw htegrefetrweq3wertgt h53t4wr3qewrgthrweaesfrd wadw gbrhjkasl btrnskLAJ jhbdfksvclzxzZ jhdfisodzk egiuwrjfoadsKZ4',
  },
  {
    id: '8111',
    title: 'Ich verstehe nichts',
    text: 'hytrgdesaw hterwaert hewgterqwret4yyju kujtyr5esdfg6re5s waa htegrefwadw gbrhjkasl btrnskLAJ jhbdfksvclzxzZ jhdfisodzk egiuwrjfoadsKZ4',
  },
  {
    id: '9111',
    title: 'Ich verstehe nichts',
    text: 'hytrgdesaw hterwaert hewgterqwret4yyju kujtyr5esdfg6re5s waa htegrefwadw gbrhjkasl btrnskLAJ jhbdfksvclzxzZ jhdfisodzk egiuwrjfoadsKZ4',
  },
  {
    id: '10111',
    title: 'Ich verstehe nichts',
    text: 'Pizza bell red pineapple roll pesto thin ham onions sautéed. Dolor string steak steak banana thin lot style. Hand sauce green wing Philly fresh tossed marinara pepperoni anchovies. Bianca sautéed cheese black pie melted NY party chicken. Chicken lasagna sautéed tomato pineapple tomato wing large sausage. Pizza meatball chicken thin extra mayo. Tomato garlic anchovies rib ham garlic. Ricotta green extra cheese beef pizza NY lovers. NY sausage broccoli pan ricotta pepperoni peppers marinara. Hand an',
  },
  {
    id: '11111',
    title: 'Ich verstehe nichts',
    text: 'Pizza bell red pineapple roll pesto thin ham onions sautéed. Dolor string steak steak banana thin lot style. Hand sauce green wing Philly fresh tossed marinara pepperoni anchovies. Bianca sautéed cheese black pie melted NY party chicken. Chicken lasagna sautéed tomato pineapple tomato wing large sausage. Pizza meatball chicken thin extra mayo. Tomato garlic anchovies rib ham garlic. Ricotta green extra cheese beef pizza NY lovers. NY sausage broccoli pan ricotta pepperoni peppers marinara. Hand an',
  },
  {
    id: '12111',
    title: 'Ich verstehe nichts',
    text: 'Pizza bell red pineapple roll pesto thin ham onions sautéed. Dolor string steak steak banana thin lot style. Hand sauce green wing Philly fresh tossed marinara pepperoni anchovies. Bianca sautéed cheese black pie melted NY party chicken. Chicken lasagna sautéed tomato pineapple tomato wing large sausage. Pizza meatball chicken thin extra mayo. Tomato garlic anchovies rib ham garlic. Ricotta green extra cheese beef pizza NY lovers. NY sausage broccoli pan ricotta pepperoni peppers marinara. Hand an',
  },
]
