import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectSectionsById, selectTopicsByExamAndSectionId } from 'src/app/redux/selectors/app.selectors'

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {
  sections$: Observable<any> | undefined
  activeSkillBlock: string | null = null
  isSkillSelected = false
  itemId!: string
  teil$!: Observable<any> | null
  isTeilSelected = false
  activeTheilBlock: string | null = null
  sectionId!: string

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.itemId = localStorage.getItem('examID') || ''
    console.log(this.itemId)
    if (this.itemId) {
      this.sections$ = this.store.select(selectSectionsById(this.itemId))
    }
  }

  isSelectedSkill(itemSectionID: string) {
    this.isSkillSelected = false

    this.router.navigate(['teil', itemSectionID], { relativeTo: this.route })

    // this.activeTheilBlock = null
    // this.teil$ = this.store.select(selectTopicsByExamAndSectionId(this.itemId, itemSectionID))
    localStorage.setItem('itemSectionID', itemSectionID)
    this.activeSkillBlock = this.activeSkillBlock === itemSectionID ? null : itemSectionID
    this.isSkillSelected = true
  }
}
