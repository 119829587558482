export const ratesData = [
  {
    id: 'Free',
    title: 'Kostenlos, zum Ausprobieren',
    popular: false,
    priceFull: null,
    price: null,
    isCurrent: true,
    options: {
      limits: '1 Thema aus jedem Abschnitt in einer Prüfung',
      history: ' ',
      something1: ' ',
      something2: false,
      something3: false,
    },
  },
  {
    id: 'Optimum',
    title: 'Ausreichende Funktionalität für effektives Training',
    popular: true,
    price: 22.7,
    isCurrent: false,
    options: {
      limits: 'alle Themen aus jedem Abschnitt in einer Prüfung',
      history: '3 Mal mit unterschiedlicher Komplexität',
      something1: '3 Mal mit unterschiedlicher Komplexität',
      something2: false,
      something3: false,
    },
  },
  {
    id: 'Premium',
    title: 'Maximale Funktionalität für eine komfortable Vorbereitung in kürzester Zeit',
    popular: false,
    price: 49.7,
    isCurrent: false,
    options: {
      limits: 'alle Themen aus jedem Abschnitt in einer Prüfung',
      history: 'mehrmals mit unterschiedlicher Komplexität',
      something1: 'mehrmals mit unterschiedlicher Komplexität',
      something2: true,
      something3: true,
    },
  },
]
