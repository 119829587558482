<div class="shadow-container">
  <app-main-shadow></app-main-shadow>
  <app-main-shadow class="shadow-up"></app-main-shadow>
</div>

<div class="home-container">
  <div class="content-wrapper">
    <p>Hallo und herzlich willkommen.</p>
    <p>Ich bin Lera Berg, deine KI-Trainerin.</p>
    <p>Ich helfe dir <span class="start-title">bei der Prüfungsvorbereitung.</span></p>

    <div class="join-button">
      <button (click)="start()">Jetzt starten</button>
    </div>
  </div>
</div>
