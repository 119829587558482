<app-breadcrumb class="conversation-breadcrumb"></app-breadcrumb>
<div class="container">
  <div class="topics-container">
    <!-- <div class="input-wrapper">
      <span class="seqrch"></span>
      <input class="topics-input" type="text" placeholder="Suchen" [(ngModel)]="valuesSearch" (ngModelChange)="change(valuesSearch)" />
    </div>
    <div class="topics-wrapper">
      <app-custom-scroll>
        <div *ngIf="cases$">
          <div *ngFor="let case of (cases$ | async) | search:settingsSearchValue">
            <app-thema-item-component
              [ngClass]="{'conversation-topics': true, activeTopics: case.id === caseId}"
              [item]="case"
              (click)="changeConversation(case.id)">
            </app-thema-item-component>
          </div>
        </div>
        
      </app-custom-scroll>
    </div>
  </div> -->

    <div class="conversation-container">
      <div class="conversation-wrapper" *ngIf="text">
        <app-custom-scroll class="conversation" appScrollToBottom>
          <app-conversation-answer>
            {{ text }}
          </app-conversation-answer>
        </app-custom-scroll>
      </div>
    </div>

    <!-- <app-thema-item-component class="conversation-topics"></app-thema-item-component>-->

    <!--  <ng-container *ngIf="askedQuestions$ | async as questions">
    <div class="conversation-container">
      <ng-container *ngIf="questions[0] !== null; else startBlock">  
        <div class="conversation-wrapper">   
          
         
          <app-custom-scroll class="conversation" appScrollToBottom>
            <app-conversation-answer>
              Um den Beitrag besser zu machen, beantworte bitte einige Fragen.
            </app-conversation-answer>
            <ng-container *ngFor="let message of questions; let i = index"> 
             
            <app-conversation-answer *ngIf="message">
              <p> {{ message.question }} </p>
            </app-conversation-answer>
           
            <div *ngIf="!message.answer" class="option-block">
            <div *ngIf="message.options">
              <div *ngFor="let option of message.options.split('],')" >
                <button (click)="sendAnswerFromButtons(option)">{{ option.trim().slice(1, -1) }}</button>
              </div>  
            </div>
            </div>
            <ng-container *ngIf="message.answer">
              <app-conversation-answer class="user">
                <p> {{ message.answer }}</p>
              </app-conversation-answer>
            <ng-container *ngIf="((existingQuestions.length-1) === i || questions[0] === null)">
               
                  <app-conversation-answer >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                  </app-conversation-answer>
                  <app-conversation-answer>
                    Saepe dolorem rlaborum maiores nulla quasi, soluta voluptate</app-conversation-answer>
                  <button type="button" (click)="startConversation()" class="startButton">Starten</button>
             
            </ng-container>
            
            </ng-container>
          </ng-container> 
        
          </app-custom-scroll>
          
     
        </div>  
        
      </ng-container>
      <ng-template #startBlock >
        
        <div class="startBlock">
          <app-conversation-answer> 
            ipsum dolor sit amet consectetur adipisicing elit. 
          </app-conversation-answer>
       
          <app-conversation-answer> ipsum dolor sit amet consectetur adipisicing elit. </app-conversation-answer>
        </div>
  
          <div>
          <button type="button" (click)="startConversation()" class="startButton">Starten</button>
          </div>         
      </ng-template>


      <div class="conversation-input-block" [formGroup]="answerForm" *ngIf="!((isOption$ | async) || (existingQuestions.length === 0))">
        <input type="text" placeholder="Write a message..." formControlName="answer"  (keydown.enter)="sendAnswer()" />
        <div class="button-block">
          <button [disabled]="isOption$ | async" type="button" (click)="sendAnswer()">Senden</button>
        </div>
      </div>
    </div>

   
  </ng-container> -->

    <!--   <div class="exam-container">
    <app-exams-component class="conversation-page"></app-exams-component>
  </div> -->
  </div>
</div>
