import { ActionReducer, createReducer, on } from '@ngrx/store'
import { AppState } from '../state.models'
import {
  addQuestionAnswer,
  hideToast,
  loadConversationsSuccess,
  loadDataSuccess,
  loadUserData,
  loadingPreloader,
  resetConversation,
  setAllQuestions,
  setConversationsAntwort,
  setNextQuestion,
  setTimer,
  setTypeConversation,
  showToast,
} from '../actions/exams.actions'
import { localStorageSync } from 'ngrx-store-localstorage'

export const initialState: AppState = {
  data: [],
  user: {
    userId: '7f494865-a946-449b-bb49-f1db170ce2ea',
    name: 'Masha',
  },
  allQuestions: [],
  answers: [],
  questions: {},
  typeConversation: '',
  isVisiblePreloader: false,
  toast: {
    message: '',
    isVisible: false,
    duration: 0,
    timerId: null,
    type: '',
  },
  conversations: [],
  conversationAntwort: {},
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['data', 'allQuestions', 'answers', 'questions', 'typeConversation', 'isVisiblePreloader', 'toast', 'conversationAntwort', 'conversations', 'user'],
    rehydrate: true,
  })(reducer)
}

export const appReducer = localStorageSyncReducer(
  createReducer(
    initialState,
    on(
      loadDataSuccess,
      (state, { data }): AppState => ({
        ...state,
        data: [...data],
      })
    ),
    on(
      loadUserData,
      (state, { data }): AppState => ({
        ...state,
        user: { ...data },
      })
    ),
    on(
      loadConversationsSuccess,
      (state, { conversations }): AppState => ({
        ...state,
        conversations: conversations,
      })
    ),
    on(
      setTypeConversation,
      (state, { conversation }): AppState => ({
        ...state,
        typeConversation: conversation,
      })
    ),

    on(setConversationsAntwort, (state, { caseId, antwort }) => ({
      ...state,
      // Заменяем только текущий текст ответа по заданному caseId, оставляя остальные нетронутыми !!! предыдущие не сохранились
      conversationAntwort: {
        ...state.conversationAntwort,
        [caseId]: antwort, // Заменяем или добавляем текст ответа для заданного caseId
      },
    })),

    on(loadingPreloader, (state, { isVisible }) => ({ ...state, isVisiblePreloader: isVisible })),

    on(showToast, (state, { messageToast, typeToast }) => ({ ...state, message: messageToast, type: typeToast, isVisible: true })),
    on(hideToast, state => ({ ...state, message: '', type: '', isVisible: false, duration: 0 })),
    on(setTimer, (state, { duration }) => ({ ...state, duration })),

    on(setAllQuestions, (state, { caseId, questions }) => {
      const caseQuestions = state.questions[caseId]
      const firstQuestion = questions.length > 0 ? questions[0] : null

      return {
        ...state,
        questions: {
          ...state.questions,
          [caseId]: {
            allQuestions: questions,
            askedQuestions: caseQuestions ? [...caseQuestions.askedQuestions] : [firstQuestion],
            isOption: firstQuestion && firstQuestion.options, // Проверяем наличие опций в первом вопросе
            // isOption: firstQuestion && firstQuestion.options ? true : false
          },
        },
      }
    }),

    on(resetConversation, (state, { caseId }) => {
      const updatedQuestions = { ...state.questions }
      delete updatedQuestions[caseId]

      return {
        ...state,
        questions: updatedQuestions,
      }
    }),
    on(addQuestionAnswer, (state, { caseId, questionId, answer }) => {
      const caseQuestions = state.questions[caseId]

      if (!caseQuestions) {
        return state
      }

      const questionToUpdate = caseQuestions.askedQuestions.find(q => q.id === questionId)

      if (!questionToUpdate) {
        return state
      }

      const updatedQuestion = { ...questionToUpdate, answer }
      const updatedAskedQuestions = caseQuestions.askedQuestions.map(q => (q.id === questionId ? updatedQuestion : q))

      const updatedCaseQuestions = {
        ...caseQuestions,
        askedQuestions: updatedAskedQuestions,
      }

      return {
        ...state,
        questions: {
          ...state.questions,
          [caseId]: updatedCaseQuestions,
        },
      }
    }),
    on(setNextQuestion, (state, { caseId }) => {
      const caseQuestions = state.questions[caseId]

      if (!caseQuestions) {
        return state
      }

      const unansweredQuestions = caseQuestions.allQuestions.filter(q => !caseQuestions.askedQuestions.some(askedQ => askedQ.id === q.id))
      const nextQuestion = unansweredQuestions.length > 0 ? unansweredQuestions[0] : null

      const setIsOption = nextQuestion ? !!nextQuestion.options : false

      const updatedCaseQuestions = {
        ...caseQuestions,
        askedQuestions: nextQuestion ? [...caseQuestions.askedQuestions, nextQuestion] : caseQuestions.askedQuestions,
        isOption: setIsOption,
      }

      return {
        ...state,
        questions: {
          ...state.questions,
          [caseId]: updatedCaseQuestions,
        },
      }
    })
  )
)
