import { AbstractControl, ValidatorFn } from '@angular/forms'

export function checkboxesValidator(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const captchaControl = formGroup.get('captcha')
    const agreementControl = formGroup.get('agreement')

    const errors: any = {}

    if (captchaControl?.value !== true) {
      errors.captchaInvalid = true
    }

    if (agreementControl?.value !== true) {
      errors.agreementInvalid = true
    }

    return Object.keys(errors).length > 0 ? errors : null
  }
}
