import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { selectAllQuestions, selectIsOption } from '../../../../redux/selectors/app.selectors'
import { BehaviorSubject, Observable } from 'rxjs'
import { ExamService } from '../../../pages/levels/service/exam.service'
import * as examsActions from '../../../../redux/actions/exams.actions'
import { ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  caseId: BehaviorSubject<string> = new BehaviorSubject('')
  constructor() {}
}
