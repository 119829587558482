import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm!: FormGroup
  isVisiblePassword = false

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      userEmail: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    })
  }

  get userEmailInput() {
    return this.loginForm.get('userEmail')
  }

  get passwordInput() {
    return this.loginForm.get('password')
  }

  submit() {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value)
      this.authService.login(this.loginForm.value)
      if (this.authService.isAuth()) {
        this.router.navigate(['/verify'])
      }

      //redirect to verify
    }
  }

  resetForm(): void {
    this.loginForm.reset()
  }

  toggleVisiblePassword() {
    this.isVisiblePassword = !this.isVisiblePassword
  }
}
