import { Injectable } from '@angular/core'

import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ExamService } from '../../lernberg/pages/levels/service/exam.service'
import * as examsActions from '../actions/exams.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'

@Injectable()
export class ExamsEffects {}
