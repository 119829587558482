import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FaqBlockComponent } from './components/faq-block/faq-block.component'
import { TitleBlockComponent } from './components/title-block/title-block.component'

@NgModule({
  declarations: [FaqBlockComponent, TitleBlockComponent],
  imports: [CommonModule],
  exports: [FaqBlockComponent, TitleBlockComponent],
})
export class SharedModule {}
