<div class="level-container" [routerLink]="['/skills']" [class.level-container-selected]="selected" (click)="saveToLS(item.id)">
  <h4 class="level-title">
    {{ item?.title }}
  </h4>
  <div>
    <img class="img-prufung" [src]="getImageUrl(item.id)" alt="img-prufung gast" />
  </div>

  <div class="level-description">
    {{ item.description }}
  </div>
  <h6 class="level"><span>Niveau </span>{{ item.level }}</h6>
</div>

<!-- 
    [routerLink]="['/exams', item.id]"
 -->
