<div>
  <app-title-block title="Allgemeine Geschäftsbedingungen" class="agb-title title-width"></app-title-block>
</div>
<div class="agb-section">
  <h5>1. Allgemeines. Geltungsbereich.</h5>
  <div class="abg-blok">
    <div>
      <span>1.1</span> Diese Nutzungsbedingungen ("Bedingungen") gelten für alle Verträge zwischen Lernberg und Kunden, die Verbraucher sind und keine geschäftlichen oder
      selbständigen beruflichen Zwecke verfolgen ("Kunde" oder "Kundin"). Sie regeln die Bereitstellung von Diensten durch Lernberg.
    </div>
    <div>
      <span>1.2</span> Diese Bedingungen gelten für alle Bestellvorgänge, die online, per E-Mail oder über eine App zwischen der Kundschaft und Lernberg abgeschlossen werden. Sie
      werden Bestandteil jeder Bestellung.
    </div>
    <div>
      <span>1.3</span> Diese Bedingungen gelten auch für zukünftige Verträge zwischen der Kundschaft und Lernberg, selbst wenn im Einzelfall nicht explizit darauf hingewiesen wird.
      Sie gelten spätestens dann als akzeptiert, wenn die Kundschaft die Dienste in Anspruch nimmt.
    </div>
    <div>
      <span>1.4</span> Diese Bedingungen sind ausschließlich anwendbar. Abweichende oder ergänzende Bedingungen werden nur Bestandteil einer Bestellung, wenn Lernberg ihnen
      schriftlich zugestimmt hat.
    </div>
    <div><span>1.5</span> Individuelle Vereinbarungen zwischen der Kundschaft und Lernberg, die schriftlich getroffen wurden, haben Vorrang vor diesen Bedingungen.</div>
    <div>
      <span>1.6</span> Begriffe wie "schriftlich" oder "in Schriftform" beziehen sich auf die Schriftform gemäß Bürgerlichem Gesetzbuch. Der elektronische Austausch von Dokumenten
      oder deren einfache elektronische Signatur (z. B. über DocuSign oder Adobe Sign) ist ausreichend. Der Austausch einfacher E-Mails ist nicht ausreichend, es sei denn, es wird
      ausdrücklich etwas anderes bestimmt.
    </div>
    <div>
      <span>1.7</span> Sofern in diesen Bedingungen nichts anderes bestimmt ist, müssen Mitteilungen an Lernberg mindestens in Textform erfolgen (eine einfache E-Mail ist
      ausreichend).
    </div>
  </div>

  <h5>2. Kostenfreie Dienste und Kostenpflichtige Dienste. Updates. Probenutzung.</h5>
  <div class="abg-blok">
    <div>
      <span>2.1</span> Lernberg stellt je nach Bestellung Zugang zu ihrer Sprachlernplattform als Software-as-a-Service bereit, inklusive aller zugehörigen Websites, Anwendungen
      und Funktionen ("Dienste"). Diese Dienste können kostenlos mit eingeschränkten Funktionen ("Kostenfreie Dienste") oder gegen Gebühr mit erweiterten Funktionen
      ("Kostenpflichtige Dienste") angeboten werden.
    </div>
    <div>
      <span>2.2</span> Lernberg behält sich das Recht vor, kostenpflichtige Dienste zu aktualisieren und zu verbessern, einschließlich Änderungen im Design, in den Funktionen und
      technischen Spezifikationen, um sicherzustellen, dass die Dienste den aktuellen rechtlichen und technischen Standards entsprechen.
    </div>
    <div>
      <span>2.3</span> Kostenfreie Dienste können von Lernberg jederzeit ohne Vorankündigung geändert, eingeschränkt oder eingestellt werden. Für diese kostenlosen Angebote
      übernimmt Lernberg keine Garantie-, Entschädigungs-, Wartungs- oder Supportpflichten.
    </div>
    <div>
      <span>2.4</span> Lernberg kann nach eigenem Ermessen eine Testphase anbieten, in der die kostenpflichtigen Dienste kostenlos oder zu reduzierten Gebühren genutzt werden
      können ("Probenutzung"). Diese Testzugänge können ebenfalls ohne Vorankündigung geändert oder eingestellt werden. Es bestehen keine Garantie-, Entschädigungs-, Wartungs- oder
      Supportpflichten für die Probenutzung. Nach Ablauf der Testphase kann der Zugang zu den regulären kostenpflichtigen Diensten übergehen, sofern dies in der Bestellung
      vereinbart wurde.
    </div>
    <div><span>2.5</span> Zur Erfüllung der vertraglichen Verpflichtungen kann Lernberg Subunternehmer, einschließlich externer Softwareanbieter, einsetzen.</div>
  </div>

  <h5>3. Nutzerkonto. Abschluss einer Bestellung.</h5>
  <div class="abg-blok">
    <div>
      <span>3.1</span> Um die Dienste nutzen zu können, müssen Kunden ein Nutzerkonto erstellen. Dabei werden diese Bedingungen akzeptiert, persönliche Daten angegeben und entweder
      ein Passwort festgelegt oder ein bereits bestehendes Konto eines Drittanbieters (z. B. Google, Facebook oder Apple) verwendet. Nach der Registrierung erhält der Kunde eine
      Bestätigungs-E-Mail zur Aktivierung des Nutzerkontos. Mit der erfolgreichen Erstellung eines Nutzerkontos kommt ein verbindlicher Vertrag zustande, der den Zugang zu den
      kostenfreien Diensten ermöglicht.
    </div>
    <div>
      <span>3.2</span> Kunden müssen mindestens 18 Jahre alt sein, um ein Nutzerkonto erstellen und/oder eine Bestellung aufgeben zu dürfen. Minderjährige ab 14 Jahren dürfen dies
      nur mit schriftlicher Einwilligung ihrer gesetzlichen Vertreter. Lernberg kann jederzeit einen Altersnachweis anfordern.
    </div>
    <div>
      <span>3.3</span> Um die kostenpflichtigen Dienste zu nutzen, müssen diese über das Nutzerkonto bestellt werden. Die Angebote von Lernberg für kostenpflichtige Dienste sind
      unverbindlich. Der Bestellvorgang umfasst die Auswahl der gewünschten Option, die Eingabe relevanter Daten, die Auswahl der Zahlungsart und die Zustimmung zu den
      Geschäftsbedingungen. Die Bestellung wird verbindlich, sobald Lernberg die Annahme des Angebots per E-Mail bestätigt. Diese Bestellbestätigung enthält auch weitere Details
      zur Bestellung und eine Bestellnummer.
    </div>
    <div>
      <span>3.4</span> Diese Bedingungen und alle weiteren Abreden, die Teil der Bestellung sind, können gespeichert werden. Lernberg speichert den Vertragstext nach Abschluss der
      Bestellung nicht, sodass er für den Kunden nicht mehr zugänglich ist.
    </div>
  </div>

  <h5>4. Nutzungsrechte. Nutzungsbeschränkungen.</h5>
  <div class="abg-blok">
    <div>
      <span>4.1</span> Lernberg gewährt den Kunden während des kostenfreien Zeitraums, der anfänglichen Laufzeit, der Laufzeit einer lebenslangen Lizenz und der verlängerten
      Laufzeit ein einfaches, nicht übertragbares, nicht unterlizenzierbares, weltweites Recht zur Nutzung der Dienste ausschließlich zu privaten Zwecken ("Lizenz"). Die Dienste
      werden in ihrer jeweils aktuellen Form auf der Website von Lernberg beschrieben, einschließlich des Nutzer-On-Boarding-Prozesses und der häufig gestellten Fragen (FAQ).
    </div>
    <div>
      <span>4.2</span> Kunden dürfen die Dienste oder Teile davon weder direkt noch indirekt:
      <ol type="a">
        <li>
          unterlizenzieren, verkaufen, weiterverkaufen, übertragen, abtreten, verteilen, teilen, verpachten, vermieten, extern geschäftlich nutzen, outsourcen oder anderweitig
          Einnahmen daraus generieren;
        </li>
        <li>die Dienste auf ein öffentliches oder verteiltes System kopieren;</li>
        <li>
          Teile der Dienste dekompilieren, im Wege des Reverse Engineerings rekonstruieren, zerlegen oder auf eine andere Weise versuchen, Quellcodes, Objektcodes oder zugrunde
          liegende Ideen, Know-how oder Algorithmen zu erlangen, es sei denn, dies ist aufgrund zwingender gesetzlicher Vorschriften zulässig;
        </li>
        <li>die Dienste modifizieren, anpassen, übersetzen oder abgeleitete Werke erstellen, die auf den Diensten basieren, außer im durch Lernberg gestatteten Umfang;</li>
        <li>Hinweise auf Eigentumsrechte in den Diensten oder Teilen davon modifizieren;</li>
        <li>die Dienste so nutzen, dass geltende Gesetze und Vorschriften verletzt werden;</li>
        <li>
          die Dienste verwenden, um unrechtmäßiges oder unerlaubtes Material zu speichern, herunterzuladen oder zu übertragen, Phishing, Spamming, Denial-of-Service-Angriffe oder
          andere betrügerische oder kriminelle Aktivitäten zu betreiben, die Integrität oder die Leistungsfähigkeit von Systemen Dritter zu beeinträchtigen oder unerlaubten Zugriff
          auf die Dienste oder die Systeme von Lernberg zu erlangen.
        </li>
      </ol>
    </div>
    <div>
      <span>4.3</span> Die Dienste können den Export- und Reexportkontrollgesetzen der Europäischen Union, der Vereinigten Staaten oder ähnlichen Gesetzen unterliegen. Kunden
      garantieren, dass sie sich nicht in einem Land befinden, gegen das ein Embargo verhängt wurde, und dass sie keine ausgeschlossene Partei gemäß den geltenden Export- und
      Reexportgesetzen sind.
    </div>
    <div>
      <span>4.4</span> Kunden dürfen die Dienste oder damit zusammenhängende Daten nicht exportieren oder reexportieren und damit gegen geltende Gesetze und Vorschriften verstoßen.
      Sie müssen alle erforderlichen behördlichen Genehmigungen und Zulassungen auf eigene Kosten einholen.
    </div>
    <div>
      <span>4.5</span> Kunden dürfen die Inhalte der Dienste nicht ohne schriftliche Zustimmung von Lernberg vervielfältigen, verarbeiten, teilen oder öffentlich wiedergeben, es
      sei denn, dies wird durch integrierte Funktionen der Dienste ausdrücklich gestattet.
    </div>
    <div><span>4.6</span> Die Nutzung der Dienste ist ausschließlich gemäß der in der Dokumentation beschriebenen Weise gestattet.</div>
    <div>
      <span>4.7</span> Lernberg behält sich das Recht vor, die Nutzung der Dienste zu überwachen und kann diese untersagen oder sperren, wenn Verstöße gegen diese Bestimmungen
      festgestellt werden.
    </div>
  </div>

  <h5>5. Pflichten des Nutzers.</h5>
  <div class="abg-blok">
    <div>
      <span>5.1</span> Der Kunde oder die Kundin ist dafür verantwortlich, auf eigene Kosten alle technischen Geräte und Zusatzdienste zu beschaffen und instand zu halten, die für
      den Zugang zu und die Nutzung der Dienste erforderlich sind. Dazu gehören insbesondere Hardware, Software, Netzwerke und Internetverbindungen. Lernberg stellt auf der Website
      aktuelle Anforderungen an die Systemvoraussetzungen zur Verfügung.
    </div>
    <div>
      <span>5.2</span> Die persönlichen Daten im Nutzerkonto müssen stets aktuell und korrekt sein. Der Kunde oder die Kundin muss sicherstellen, dass Zugangsdaten wie Benutzername
      und Passwort sicher aufbewahrt werden und nicht von unbefugten Dritten eingesehen oder genutzt werden können. Bei Verlust oder unbefugter Nutzung ist Lernberg unverzüglich zu
      informieren, damit entsprechende Maßnahmen ergriffen werden können.
    </div>
    <div>
      <span>5.3</span> Der Kunde oder die Kundin ist allein verantwortlich für alle Inhalte, die über die Dienste angezeigt, veröffentlicht, hochgeladen, gespeichert, ausgetauscht
      oder übertragen werden. Lernberg hat keine Kontrolle über die vom Kunden oder der Kundin übermittelten Informationen und übernimmt keine Verantwortung für deren Richtigkeit.
    </div>
    <div>
      <span>5.4</span> Lernberg behält sich das Recht vor, ohne vorherige Ankündigung Beschwerden oder mutmaßlichen Verstößen gegen die Nutzungsbedingungen nachzugehen. Dazu kann
      das Entfernen von Inhalten, das Sperren oder Kündigen des Zugangs zu den Diensten gehören.
    </div>

    <div>
      <span>5.5</span> Der Kunde oder die Kundin haftet für Verluste oder Schäden, die Lernberg entstehen, weil Zugangsdaten vorsätzlich oder fahrlässig Dritten zugänglich gemacht
      wurden oder unbefugten Personen auf andere Weise bekannt geworden sind.
    </div>
  </div>

  <h5>6. Nutzung der Plattform. Datenübertragung. Organisatorische Aspekte.</h5>
  <div class="abg-blok">
    <div>
      <span>6.1</span> Lernberg stellt die kostenpflichtigen Dienste in Form eines Zugangs zur Online-Plattform bereit, über die Kunden ihre Sprachkenntnisse verbessern können.
      Diese Dienste umfassen die Nutzung von Video- und Audioinhalten sowie interaktiven Übungen.
    </div>
    <div>
      <span>6.2</span> Der Zugang zur Plattform ist personenbezogen und nicht übertragbar. Der Kunde ist verantwortlich für die Geheimhaltung der Zugangsdaten und die sichere
      Nutzung der Plattform.
    </div>
    <div>
      <span>6.3</span> Lernberg kann die Plattform über verschiedene Technologien bereitstellen, einschließlich Webbrowser, mobiler Apps und anderer geeigneter Software. Es liegt
      in der Verantwortung des Kunden, sicherzustellen, dass die technischen Anforderungen erfüllt sind, um die Dienste nutzen zu können.
    </div>
    <div>
      <span>6.4</span> Lernberg behält sich das Recht vor, Änderungen und Updates an der Plattform vorzunehmen, um technische Verbesserungen und Anpassungen an gesetzliche
      Anforderungen umzusetzen.
    </div>

    <div>
      <span>6.5</span> Der Kunde darf keine Inhalte, die über die Plattform zugänglich sind, ohne ausdrückliche schriftliche Genehmigung von Lernberg vervielfältigen, verbreiten
      oder öffentlich wiedergeben.
    </div>
    <div>
      <span>6.6</span> Die Nutzung der Plattform zur Übertragung, Verteilung oder Speicherung von rechtswidrigem oder unzulässigem Material ist strengstens untersagt. Der Kunde
      darf keine Handlungen vornehmen, die die Funktionsfähigkeit der Plattform beeinträchtigen oder andere Nutzer stören könnten.
    </div>
    <div>
      <span>6.7</span> Lernberg behält sich das Recht vor, die Nutzung der Plattform zu überwachen und den Zugang zu sperren, wenn Verstöße gegen die Nutzungsbedingungen
      festgestellt werden.
    </div>
    <div>
      <span>6.8</span> Für Video- und Audioinhalte sowie für interaktive Übungen gelten besondere Datenschutzbestimmungen, die sicherstellen, dass die persönlichen Daten der Nutzer
      geschützt werden. Kunden sind verpflichtet, diese Datenschutzbestimmungen zu beachten.
    </div>
    <div>
      <span>6.9</span> Lernberg kann aufgrund von Wartungsarbeiten oder technischen Verbesserungen den Zugang zur Plattform vorübergehend einschränken. Solche Unterbrechungen
      werden, soweit möglich, im Voraus angekündigt.
    </div>
    <div>
      <span>6.10</span> Jegliche Kommunikation mit Lernberg, einschließlich technischer Unterstützung, erfolgt über die bereitgestellten Kontaktmöglichkeiten wie E-Mail oder das
      Kontaktformular auf der Website.
    </div>
  </div>

  <h5>7. Wartung und Support für Kostenpflichtige Dienste.</h5>
  <div class="abg-blok">
    <div>
      <span>7.1</span> Vorbehaltlich der Zahlung der Gebühren durch den Kunden bietet Lernberg für kostenpflichtige Dienste Wartungs- und Supportleistungen an. Der Support umfasst
      die Unterstützung bei der Fehleridentifikation und die Beantwortung berechtigter Supportanfragen. Die Wartung umfasst die Behebung von Störungen und die Bereitstellung von
      Updates und Upgrades.
    </div>
    <div>
      <span>7.2</span> Der Support steht den Kunden per E-Mail über support&#64;lernberg.com oder über andere von Lernberg zur Verfügung gestellte Kontaktmöglichkeiten zur
      Verfügung. Der Support erfolgt in der Regel von Montag bis Freitag während der regulären Geschäftszeiten.
    </div>
    <div>
      <span>7.3</span> Lernberg unternimmt angemessene Anstrengungen, um die Dienste so zu warten, dass Störungen und Unterbrechungen minimiert werden. Kunden können Störungen per
      E-Mail oder über die Kontaktmöglichkeiten auf der Website melden. Lernberg bemüht sich, gemeldete Fehler zeitnah zu beheben, abhängig von der Priorität des Fehlers
    </div>
  </div>

  <h5>8. Verfügbarkeit der Kostenpflichtigen Dienste.</h5>
  <div class="abg-blok">
    <div><span>8.1</span> Vorbehaltlich der Zahlung der Gebühren stellt Lernberg die kostenpflichtigen Dienste gemäß dieser Bestimmungen zur Verfügung.</div>
    <div>
      <span>8.2</span> Lernberg bemüht sich, die kostenpflichtigen Dienste mindestens 99,5 % der Zeit im Kalenderjahr (24 Stunden pro Tag, 7 Tage die Woche) verfügbar zu halten,
      ausgenommen sind planmäßige oder außerplanmäßige Wartungsarbeiten und Ereignisse, die außerhalb der Kontrolle von Lernberg liegen. Planmäßige Unterbrechungen werden, soweit
      möglich, im Voraus angekündigt.
    </div>
    <div><span>8.3</span> Während der Durchführung von Wartungsarbeiten oder der Behebung technischer Fehler bietet Lernberg keine Entschädigungen oder Vergütungen an.</div>

    <div>
      <span>8.4</span> Kunden sind sich bewusst, dass die Erfüllung der Zugangsvoraussetzungen, wie eine stabile Internetverbindung, nicht von Lernberg gewährleistet werden kann.
      Lernberg haftet nicht für Ausfälle der Internetverbindung oder des Systems des Kunden.
    </div>
  </div>

  <h5>9. Gebühren. Zahlungsbedingungen. Aufrechnungsbeschränkung.</h5>
  <div class="abg-blok">
    <div><span>9.1</span> Der Kunde ist verpflichtet, die in der Bestellung vereinbarten Gebühren für die kostenpflichtigen Dienste zu zahlen.</div>
    <div>
      <span>9.2</span> Lernberg kann die Gebühren für die kommende Verlängerungsperiode ändern. Diese Änderungen werden mindestens 60 Tage vor Ablauf der aktuellen Periode
      angekündigt.
    </div>
    <div><span>9.3</span> Die Zahlung der Gebühren erfolgt über die auf der Website von Lernberg angebotenen Zahlungsmethoden.</div>

    <div><span>9.4</span> Rechnungen werden in der Regel elektronisch versendet und sind innerhalb von 30 Tagen nach Versand zu bezahlen.</div>
    <div><span>9.5</span> Lernberg behält sich das Recht vor, den Zugang zu den Diensten zu sperren, wenn Zahlungen nicht innerhalb von 14 Tagen nach Fälligkeit eingehen.</div>
    <div><span>9.6</span> Der Kunde kann nur mit unbestrittenen oder rechtskräftig festgestellten Forderungen gegen Forderungen von Lernberg aufrechnen.</div>
  </div>

  <h5>10. Laufzeit. Kündigung.</h5>
  <div class="abg-blok">
    <div><span>10.1</span> Die Laufzeit einer Bestellung beginnt zu dem im Vertrag bezeichneten Zeitpunkt.</div>
    <div>
      <span>10.2</span> Bestellungen über kostenfreie Dienste bleiben auf unbestimmte Zeit in Kraft, sofern sie nicht gemäß den Bedingungen gekündigt werden. Sowohl der Kunde als
      auch Lernberg können die kostenfreien Dienste jederzeit mit sofortiger Wirkung kündigen.
    </div>
    <div>
      <span>10.3</span> Bestellungen über kostenpflichtige Dienste bleiben für die in der Bestellung festgelegte ursprüngliche Laufzeit in Kraft. Diese Laufzeit verlängert sich
      automatisch um einen weiteren Zeitraum, es sei denn, der Kunde oder Lernberg kündigen die Bestellung mit einer Frist von sieben (7) Tagen vor Ablauf der aktuellen Laufzeit.
    </div>

    <div>
      <span>10.4</span> Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt. Ein wichtiger Grund liegt vor, wenn eine Partei wesentliche
      Vertragspflichten verletzt und diese Verletzung nicht innerhalb von dreißig (30) Tagen nach Erhalt einer schriftlichen Anzeige behoben wird. Ein wichtiger Grund besteht auch,
      wenn der Kunde mehr als fünfundvierzig (45) Tage im Zahlungsverzug ist, gegen Nutzungsbeschränkungen verstößt oder die fälligen Zahlungen einstellt.
    </div>
  </div>

  <h5>11. Eingeschränkte Gewährleistung für Kostenpflichtige Dienste.</h5>
  <div class="abg-blok">
    <div>
      <span>11.1</span> Der Kunde sichert zu und garantiert, dass er überprüft hat, ob die Dienste, wie in der Dokumentation beschrieben, seinen Bedürfnissen und Wünschen
      entsprechen. Ihm sind die wesentlichen Funktionen und Merkmale der Dienste bekannt. Der Umfang, die Art und die Qualität der Dienste werden durch diese Bedingungen, die
      Bestellung und die Dokumentation bestimmt. Andere Informationen oder Bedingungen werden nur dann Bestandteil der Bestellung, wenn dies ausdrücklich schriftlich vereinbart
      wurde.
    </div>
    <div>
      <span>11.2</span> Lernberg gewährleistet, dass die kostenpflichtigen Dienste die wesentlichen Funktionen aufweisen, die in der jeweils aktuellen Dokumentation der jeweiligen
      Version beschrieben sind. Bei einem Verstoß gegen diese Gewährleistung ist Lernberg verpflichtet, angemessene Anstrengungen zu unternehmen, um die Dienste so anzupassen, dass
      sie im Wesentlichen der Dokumentation entsprechen. Sofern Lernberg dies nicht innerhalb von 30 Tagen nach schriftlicher Anzeige des Verstoßes gelingt, kann der Kunde die
      Bestellung kündigen und eine anteilige Rückzahlung der im Voraus gezahlten Gebühren für den nicht genutzten Zugang verlangen.
    </div>
    <div><span>11.3</span> Der Kunde ist unter keinen Umständen berechtigt, den Quellcode der Dienste zu erhalten.</div>
  </div>

  <h5>12. Eigentumsrechte. Nutzerdaten.</h5>
  <div class="abg-blok">
    <div>
      <span>12.1</span> Lernberg und dessen Auftragnehmer behalten alle Eigentumsrechte, einschließlich Urheberrechte, Datenbankrechte, Patente, Geschäftsgeheimnisse, Marken und
      aller anderen Rechte an geistigem Eigentum und technischen Lösungen im Zusammenhang mit den Diensten, insbesondere den Lehrinhalten und didaktischen Methoden. Die nach einer
      Bestellung gewährten Rechte verschaffen dem Kunden keine Eigentumsrechte an den Diensten.
    </div>
    <div>
      <span>12.2</span> Alle Rechte an den Nutzerdaten und Inhalten sowie an Daten, die auf den Nutzerdaten oder Inhalten basieren oder davon abgeleitet sind, verbleiben beim
      Kunden. Der Kunde gewährt Lernberg ein einfaches, nicht übertragbares, unterlizenzierbares Recht, die Nutzerdaten und Inhalte ausschließlich in Verbindung mit der Erbringung
      der Dienste zu nutzen. Lernberg haftet nicht für die Nutzerdaten oder Inhalte.
    </div>
  </div>

  <h5>13. Datenschutz.</h5>
  <div class="abg-blok">
    <div>
      <span>13.1</span> Die Verarbeitung personenbezogener Daten durch Lernberg wird in der Datenschutzerklärung von Lernberg erläutert, die auf der Website von Lernberg abrufbar
      ist.
    </div>
  </div>

  <h5>14. Freistellungsverpflichtung.</h5>
  <div class="abg-blok">
    <div>
      <span>14.1</span> Unbeschadet sonstiger vertraglicher oder gesetzlicher Haftungspflichten ist der Kunde verpflichtet, Lernberg, dessen verbundene Unternehmen, Mitarbeiter,
      Subunternehmer, Lieferanten und Händler auf eigene Kosten gegen Forderungen Dritter zu verteidigen und schadlos zu halten, soweit solche Forderungen aus einem Verstoß gegen
      die Regelungen dieser Nutzungsbedingungen oder anderweitig im Zusammenhang mit der Nutzung der Dienste durch den Kunden entstehen oder geltend gemacht werden. Der Kunde trägt
      alle Kosten und Schadensersatzforderungen, die durch ein zuständiges Gericht rechtskräftig festgestellt werden.
    </div>
    <div>
      <span>14.2</span> Lernberg wird den Kunden umgehend über Forderungen Dritter informieren, aufgrund derer Lernberg eine Freistellung verlangt. Lernberg kann die
      Rechtsverteidigung unterstützen, jedoch obliegt es dem Kunden, geeignete Maßnahmen zur Verteidigung zu ergreifen und etwaige Vergleichsverhandlungen zu führen. Ein Vergleich,
      der Lernberg rechtlich bindet, wird erst wirksam, wenn Lernberg schriftlich zustimmt.
    </div>
    <div>
      <span>14.3</span> Lernberg wird den Kunden auf eigene Kosten gegen Forderungen Dritter verteidigen (oder diese vergleichsweise beilegen), sofern behauptet wird, dass die
      Dienste ein Patent, ein Urheberrecht, ein Markenrecht oder ein Geschäftsgeheimnis Dritter verletzen. Lernberg trägt alle Kosten und Schadensersatzpflichten, die durch ein
      zuständiges Gericht rechtskräftig festgestellt werden. Falls die Nutzung der Dienste Gegenstand einer solchen Forderung wird, kann Lernberg nach eigenem Ermessen die
      relevanten Dienste durch funktional gleichwertige Technologie ersetzen, ein Recht zur weiteren Nutzung einholen oder die Bestellung kündigen und im Voraus gezahlte Gebühren
      anteilig zurückzahlen.
    </div>
    <div>
      <span>14.4</span> Die Regelungen in Abschnitt 14.3 gelten nicht, wenn die Dienste vom Kunden oder einem Dritten verändert wurden, mit anderen Produkten kombiniert wurden, die
      nicht von Lernberg bereitgestellt werden, oder wenn die Dienste nicht autorisiert genutzt wurden.
    </div>
    <div>
      <span>14.5</span> Die Rechte des Kunden hinsichtlich Forderungen aufgrund einer Verletzung von geistigen Eigentumsrechten Dritter sind abschließend in diesem Abschnitt
      geregelt.
    </div>
  </div>

  <h5>15. Haftungsbeschränkung.</h5>
  <div class="abg-blok">
    <div>
      <span>15.1</span> Lernberg haftet unbeschränkt für Schäden durch Verletzung des Lebens, des Körpers oder der Gesundheit sowie für vorsätzlich oder grob fahrlässig verursachte
      Schäden durch Lernberg, dessen gesetzliche Vertreter oder Erfüllungsgehilfen.
    </div>
    <div>
      <span>15.2</span> Für Schäden aus der Verletzung wesentlicher Vertragspflichten haftet Lernberg auch bei leichter Fahrlässigkeit, jedoch nur bis zur Höhe des vorhersehbaren
      Schadens. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde
      regelmäßig vertrauen darf.
    </div>
    <div>
      <span>15.3</span> Vorbehaltlich der Regelungen in Abschnitt 15.1 und 15.2 haftet Lernberg nicht für Schäden aus der Verletzung von Pflichten, die keine wesentlichen
      Vertragspflichten darstellen und die leicht fahrlässig verursacht wurden.
    </div>
    <div>
      <span>15.4</span> Für Datenverluste haftet Lernberg nur bis zu einem Betrag, der die typischen Kosten für die Wiederherstellung abdeckt, wenn der Kunde ordnungsgemäße und
      regelmäßige Datensicherungen vorgenommen hat.
    </div>
    <div>
      <span>15.5</span> Vorbehaltlich der Regelungen in Abschnitt 15.1 ist die Haftung von Lernberg für Schäden aus oder im Zusammenhang mit einer Bestellung auf 150 % der vom
      Kunden gezahlten oder zahlbaren Gebühren unter der betroffenen Bestellung beschränkt.
    </div>
    <div><span>15.6</span> Lernberg steht nicht dafür ein, dass der Kunde durch die Nutzung der Dienste einen bestimmten Lernfortschritt oder Lernerfolg erzielt.</div>
    <div>
      <span>15.7</span> Weder Lernberg noch der Kunde haften für Ausfälle oder Verzögerungen bei der Erfüllung der Bestellung, soweit diese durch höhere Gewalt verursacht werden.
    </div>
    <div><span>15.8</span> Eine über die in diesem Abschnitt geregelten Fälle hinausgehende Haftung von Lernberg ist ausgeschlossen.</div>
  </div>

  <h5>16. Haftungsbeschränkung.</h5>
  <div class="abg-blok">
    <div>
      <span>16.1</span> Dem Kunden steht folgendes Widerrufsrecht zu:
      <h6>Widerrufsrecht</h6>
      <div>Der Kunde hat das Recht, binnen vierzehn (14) Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</div>
      <div>
        Die Widerrufsfrist beträgt vierzehn (14) Tage ab dem Tag des Vertragsabschlusses. Um das Widerrufsrecht auszuüben, muss der Kunde Lernberg (Adresse, Telefonnummer,
        E-Mail-Adresse) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über den Entschluss, diesen Vertrag zu widerrufen, informieren.
        Der Kunde kann dafür das auf der Website verfügbare Musterformular verwenden, das jedoch nicht vorgeschrieben ist.
      </div>
      <div>Zur Wahrung der Widerrufsfrist reicht es aus, dass der Kunde die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.</div>
      <h6>Folgen des Widerrufs</h6>
      <div>
        Wenn der Kunde diesen Vertrag widerruft, hat Lernberg alle Zahlungen, die vom Kunden erhalten wurden, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten,
        die sich daraus ergeben, dass der Kunde eine andere Art der Lieferung als die von Lernberg angebotene Standardlieferung gewählt hat), unverzüglich und spätestens binnen
        vierzehn (14) Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf dieses Vertrags bei Lernberg eingegangen ist. Für diese Rückzahlung verwendet
        Lernberg dasselbe Zahlungsmittel, das der Kunde bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, es wurde ausdrücklich etwas anderes vereinbart; in keinem
        Fall werden dem Kunden wegen dieser Rückzahlung Entgelte berechnet.
      </div>
      <h6>Ende der Widerrufsbelehrung</h6>
    </div>
    <div>
      <span>16.2</span> Der Kunde erklärt sich ausdrücklich damit einverstanden, dass Lernberg vor Ablauf der in Abschnitt 16.1 genannten Widerrufsfrist mit der Durchführung des
      Vertrages beginnt. Der Kunde erkennt an, dass durch diese Zustimmung das Widerrufsrecht erlischt, sobald Lernberg mit der Vertragsdurchführung begonnen hat. Widerruft der
      Kunde dennoch einen Vertrag, so ist er verpflichtet, Lernberg den Wert der bis zum Widerruf erbrachten Dienste zu erstatten.
    </div>

    <div>
      <span>16.3</span> Lernberg haftet nicht für Gebühren, die dem Kunden anlässlich des Widerrufs im Zusammenhang mit der Beauftragung Dritter (z. B. Banken und
      Zahlungsdienstleister) entstehen, oder für etwaige nicht erstattungsfähige Steuern.
    </div>
  </div>

  <h5>17. Übertragung. Novation.</h5>
  <div class="abg-blok">
    <div>
      <span>17.1</span> Lernberg kann eine Bestellung und/oder damit verbundene Rechte ganz oder teilweise auf Dritte übertragen, vorausgesetzt, dass eine solche Übertragung die
      Rechte des Kunden aus der Bestellung nicht beeinträchtigt.
    </div>
    <div>
      <span>17.2</span> Auf Verlangen von Lernberg wird der Kunde für jede Bestellung mit einem Dritten unverzüglich eine Novationsvereinbarung abschließen, um Lernberg die
      Ausübung der Rechte gemäß Abschnitt 17.1 zu ermöglichen.
    </div>
    <div>
      <span>17.3</span> Der Kunde darf seine Rechte oder Verpflichtungen aus einer Bestellung und diesen Bedingungen nicht ohne das vorherige schriftliche Einverständnis von
      Lernberg abtreten. Dies gilt nicht für Zahlungsforderungen.
    </div>
  </div>

  <h5>18. Vollständige Vereinbarung. Salvatorische Klausel. Änderungen.</h5>
  <div class="abg-blok">
    <div>
      <span>18.1</span> Eine Bestellung einschließlich dieser Bedingungen stellt die vollständige Vereinbarung zwischen dem Kunden und Lernberg in Bezug auf ihren
      Vertragsgegenstand dar und ersetzt alle früheren schriftlichen oder mündlichen Vereinbarungen zwischen den Parteien.
    </div>
    <div>
      <span>18.2</span> Sollte eine Regelung einer Bestellung einschließlich dieser Bedingungen unwirksam oder nichtig sein oder werden, so bleibt die Wirksamkeit der übrigen
      Regelungen davon unberührt. Anstelle der unwirksamen oder nichtigen Regelung tritt eine Regelung, die dem am nächsten kommt, was die Parteien nach dem Sinn und Zweck der
      ursprünglichen Regelung in gesetzlich zulässiger Weise vereinbart hätten. Das gilt auch für Regelungslücken.
    </div>
    <div>
      <span>18.3</span> Änderungen oder Ergänzungen einer Bestellung bedürfen der schriftlichen Zustimmung beider Parteien, um wirksam zu sein. Dasselbe gilt für Abweichungen von
      diesem Schriftformerfordernis.у
    </div>
  </div>

  <h5>19. Änderung dieser Bedingungen.</h5>
  <div class="abg-blok">
    <div>
      <span>19.1</span> Lernberg kann diese Bedingungen für bestehende Bestellungen mit Wirkung für die Zukunft ändern oder aktualisieren, wenn dies aus technischen,
      wirtschaftlichen oder rechtlichen Gründen erforderlich ist. Jede Änderung wird dem Kunden mindestens sechs (6) Wochen vor ihrem Inkrafttreten angekündigt. Die Änderung gilt
      als angenommen, wenn der Kunde nicht vor dem Inkrafttreten widerspricht. Lernberg wird den Kunden in der Ankündigung ausdrücklich auf die Bedeutung seines Schweigens
      hinweisen
    </div>
    <div><span>19.2</span> Lernberg kann diese Bedingungen jederzeit für zukünftige Bestellungen ändern oder aktualisieren.</div>
  </div>

  <h5>20. Geltendes Recht. Verbraucherschlichtungsstelle.</h5>
  <div class="abg-blok">
    <div>
      <span>20.1</span> Jede Bestellung und alle Streitigkeiten oder Ansprüche, die durch oder in Verbindung mit der Bestellung entstehen, unterliegen den zwingenden Gesetzen des
      Staates, in dem der Kunde seinen gewöhnlichen Aufenthalt hat. Im Übrigen gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der kollisionsrechtlichen
      Bestimmungen. Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG) findet keine Anwendung.
    </div>
    <div>
      <span>20.2</span> Lernberg ist nicht verpflichtet, an einem Streitbeilegungsverfahren vor der Schiedsstelle der Europäischen Kommission oder vor einer anderen
      Verbraucherschlichtungsstelle teilzunehmen und ist dazu auch nicht bereit.
    </div>
  </div>

  <h5>21. Schlussbestimmungen.</h5>
  <div class="abg-blok">
    <div>
      <span>21.1</span> Soweit in diesen Bedingungen nicht ausdrücklich anders geregelt, bedürfen alle Änderungen oder Ergänzungen dieser Bedingungen der Schriftform. Dies gilt
      auch für eine Änderung des Schriftformerfordernisses selbst.
    </div>
    <div>
      <span>21.2</span> Lernberg behält sich das Recht vor, diese Nutzungsbedingungen aus wichtigen Gründen, insbesondere bei Änderungen der Gesetzeslage oder der
      höchstrichterlichen Rechtsprechung, jederzeit zu ändern. Änderungen dieser Bedingungen werden dem Kunden mindestens sechs Wochen vor ihrem Inkrafttreten per E-Mail
      angekündigt. Widerspricht der Kunde den Änderungen nicht innerhalb von sechs Wochen nach Zugang der Änderungsmitteilung, gelten die Änderungen als akzeptiert. Lernberg wird
      den Kunden in der Änderungsmitteilung auf die Bedeutung seines Schweigens hinweisen.
    </div>
    <div>
      <span>21.3</span> Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam oder nicht durchsetzbar sein oder nach Vertragsschluss unwirksam oder undurchsetzbar
      werden, bleibt davon die Wirksamkeit der übrigen Bestimmungen unberührt. Anstelle der unwirksamen oder undurchsetzbaren Bestimmungen treten die gesetzlichen Vorschriften.
    </div>
    <div>
      <span>21.4</span> Erfüllungsort und ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist der Sitz von Lernberg, sofern der
      Kunde Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist.
    </div>
  </div>
</div>

<div class="agb__data">Stand: 19.07.2024</div>
