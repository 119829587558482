<div class="wrapper">
  <h1>Passwort vergessen</h1>

  <p>Teilen Sie uns bitte Ihre E-Mail-Adresse mit. Sie erhalten innerhalb von wenigen Minuten per E-Mail einen Link, um ein neues Passwort festzulegen.</p>

  <form [formGroup]="resetPasswordFrom">
    <div class="input-container">
      <div class="error" *ngIf="userEmailInput?.invalid && (userEmailInput?.touched || userEmailInput?.dirty); else emailLabel">
        <span *ngIf="userEmailInput?.hasError('required')">Please enter a login email</span>
        <span *ngIf="userEmailInput?.hasError('email')">The login email is invalid</span>
      </div>

      <ng-template #emailLabel><label for="email">E-Mail-Adresse</label></ng-template>

      <input type="email" id="email" formControlName="userEmail" />
    </div>

    <div class="action-buttons-wrapper">
      <button (click)="submit()" [disabled]="resetPasswordFrom.invalid">Passwort anfordern</button>
      <span>Zurück zur Anmeldung</span>
    </div>
  </form>
</div>
