import { Component } from '@angular/core'
import { AudioService } from '../../services/audio.service'

@Component({
  selector: 'app-audio-animation',
  templateUrl: './audio-animation.component.html',
  styleUrls: ['./audio-animation.component.scss'],
})
export class AudioAnimationComponent {
  isRecording!: boolean
  isPlaying!: boolean

  isLoading!: boolean
  isShadowAnimation!: boolean

  constructor(public audioService: AudioService) {
    // this.audioService.isRecording.subscribe((val) => this.isRecording = val);
    // this.audioService.isPlaying.subscribe(val => (this.isPlaying = val, console.log(val)));
    this.audioService.isLoading.subscribe(val => (this.isLoading = val))
    this.audioService.isShadowAnimation.subscribe(val => (this.isShadowAnimation = val))
  }
}
