import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private timerSubscription!: Subscription
  private startedTime: number = 600000 // 10 минут в миллисекундах 600000

  public isTimeOver: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public isStopTimer: BehaviorSubject<boolean> = new BehaviorSubject(false)

  public timerSubject = new Subject<number | undefined>()
  public countDown: number = this.startedTime

  constructor() {}

  startTimer(): void {
    this.resetTimer()
    this.timerSubscription = timer(0, 1000).subscribe(() => {
      this.countDown -= 1000

      if (this.countDown === 0) {
        this.isStopTimer.next(true)
        this.stopTimer()
      }
      if (this.countDown === this.startedTime / 4) {
        this.isTimeOver.next(true)
      }
      this.timerSubject.next(this.countDown)
    })
  }

  stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubject.next(this.startedTime)
      this.isTimeOver.next(false)
      this.timerSubscription.unsubscribe()
    }
    this.timerSubject.next(this.countDown) // Обновляем значение таймера
  }

  private resetTimer(): void {
    this.countDown = this.startedTime
    this.isTimeOver.next(false)
    this.isStopTimer.next(false)
    this.timerSubject.next(this.countDown)
  }
}
