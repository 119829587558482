import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import { selectAskedQuestions, selectUserData } from '../../../redux/selectors/app.selectors'
import { ExamService } from '../levels/service/exam.service'

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  caseId!: string

  userId!: string
  text: string = ''

  extraInfo!: any[]

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private examService: ExamService
  ) {
    this.route.paramMap.subscribe(params => {
      this.caseId = params.get('id') || ''
    })
    this.store.select(selectUserData).subscribe(s => (this.userId = s.userId))
    this.startConversation()
  }

  startConversation() {
    this.store.select(selectAskedQuestions(this.caseId)).subscribe(res => {
      if (res) {
        this.extraInfo = res.map(item => ({ title: item.title, answer: item.answer }))
      } else {
        this.extraInfo = []
      }
    })

    if (this.extraInfo) {
      console.log(this.caseId)
      console.log(this.userId)
      console.log(this.extraInfo)
      this.examService.startConversation(this.userId, this.caseId, this.extraInfo).subscribe(res => (this.text = res.message))
    }
  }
}
