<div class="wrapper" [ngClass]="{ sec30: is30, sec10: is10 }">
  <div class="image-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
      <g clip-path="url(#clip0_1739_1789)">
        <path
          d="M50 8.33203C53.9029 8.33203 57.7062 8.77999 61.3596 9.62907C63.6012 10.15 64.9958 12.3894 64.475 14.6308C63.9542 16.8722 61.715 18.267 59.4733 17.7461C57.4472 17.2752 55.3626 16.9518 53.231 16.7886L52.919 16.7773C59.5897 17.2909 65.7717 19.5498 71.0088 23.0982L73.535 20.5719C75.1621 18.9447 77.8004 18.9447 79.4275 20.5719C81.0546 22.1991 81.0546 24.8373 79.4275 26.4645L77.3658 28.5261C83.6513 35.232 87.5 44.2491 87.5 54.1654C87.5 74.8762 70.7108 91.6654 50 91.6654C29.2893 91.6654 12.5 74.8762 12.5 54.1654C12.5 34.2827 27.9736 18.0141 47.5355 16.7451L46.9668 16.7752C44.7661 16.932 42.6152 17.2607 40.5265 17.7461C38.285 18.267 36.0457 16.8722 35.5247 14.6308C35.0038 12.3894 36.3986 10.15 38.64 9.62907C42.2937 8.77999 46.0971 8.33203 50 8.33203ZM50 24.9987C33.8917 24.9987 20.8333 38.0571 20.8333 54.1654C20.8333 70.2737 33.8917 83.332 50 83.332C66.1083 83.332 79.1667 70.2737 79.1667 54.1654C79.1667 38.0571 66.1083 24.9987 50 24.9987ZM50 33.332C52.1369 33.332 53.898 34.9406 54.1386 37.0128L54.1667 37.4987V54.1654C54.1667 56.4666 52.3012 58.332 50 58.332C47.8631 58.332 46.102 56.7236 45.8614 54.6513L45.8333 54.1654V37.4987C45.8333 35.1975 47.6987 33.332 50 33.332Z"
          fill="#2E294E" />
      </g>
      <defs>
        <clipPath id="clip0_1739_1789">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <p>
    <span>{{ countDown | date: 'mm:ss' }}</span>
  </p>
</div>
