<h2 class="border">{{ detailData.audioTitle }}</h2>

<p class="border text">{{ detailData.audioIntro }}</p>

<div class="border list">
  <div class="list-container">
    <div class="detailList" *ngFor="let pharagraph of detailData.description_full | splitParagraphs">
      <div class="pharagraph">{{ pharagraph }}</div>
    </div>
    <!-- <p>
    Sie sind Arbeitskolleg:innen.
    Sie führen ein Gespräch in der Pause.
  </p>

  <div class="list-container">
    <p>Worauf musst du achten: </p>
    <ul>
      <li>die Korrektheit</li>
      <li>deine Aussprache</li>
      <li>einen abwechslungsreichen Wortschatz</li>
      <li>verschiedene grammatische Strukturen </li>
    </ul>
  </div> -->
  </div>
</div>

<div class="switcher border">
  <p>Ergebnis speichern</p>
  <div class="switcher-container">
    <label class="switch">
      <input type="checkbox" />
      <span class="slider round"></span>
    </label>
  </div>
</div>
