import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PageStateService {
  currentPage: number = 1
  breadcrumbs: string = '' // 'back', 'breadcrumbs'
  private breadcrumbsSubject: BehaviorSubject<string> = new BehaviorSubject<string>('')

  constructor() {}

  get breadcrumbs$() {
    return this.breadcrumbsSubject.asObservable()
  }

  setBreadcrumbs(value: string) {
    this.breadcrumbsSubject.next(value)
  }
}
