import { createAction, props } from '@ngrx/store'

export interface Question {
  title: string
  question: string
  options: string
  id: string
}

export const loadDataSuccess = createAction('[DATA] Load data Success', props<{ data: any }>())

export const loadUserData = createAction('[USER DATA] Load user data Success', props<{ data: any }>())

export const loadConversationsSuccess = createAction('[Conversations] Load conversations Success', props<{ conversations: any }>())

// export const setAllQuestions = createAction('[Conversation] Set All Questions', props<{ questions: any }>());
export const addSequentialQuestion = createAction('[Conversation] Set Sequential Questions', props<{ question: any }>())
export const setAnswers = createAction('[Conversation] Set Answer', props<{ questionId: string; answer: string }>())
export const setCurrentQuestionIndex = createAction('[Conversation] Set Current index')
// export const setNextQuestion =  createAction('[Conversation] Set next question');

export const setAllQuestions = createAction('[Conversation] Set All Questions', props<{ caseId: string; questions: any[] }>())

export const setConversationsAntwort = createAction('[Conversation] Set text Conversation', props<{ caseId: string; antwort: string }>())

export const resetConversation = createAction('[Conversation] reset All Questions', props<{ caseId: string }>())

export const setNextQuestion = createAction('[Conversation] Set Next Question', props<{ caseId: string }>())

export const addQuestionAnswer = createAction('[Questions] Add Question Answer', props<{ caseId: string; questionId: any; answer: any }>())

export const setTypeConversation = createAction('[Conversation] add Type', props<{ conversation: string }>())
// export const loadExams = createAction('[Exams] Load Exams')
// export const loadExamsSuccess = createAction('[Exams] Load Exams Success', props<{ exams: any }>())
// export const loadExamsFailure = createAction('[Exams] Load Exams Failure', props<{ error: any }>())

export const loadingPreloader = createAction('[PRELOADER] Loading', props<{ isVisible: boolean }>())

export const showToast = createAction('[Toast] Show Toast', props<{ messageToast: string; typeToast: string }>())
export const hideToast = createAction('[Toast] Hide Toast')
export const setTimer = createAction('[Toast] Set Timer', props<{ duration: number }>())
