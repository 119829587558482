// export const environment = {
//   production: false,
//   keycloak: {
//     authority: 'http://localhost:8081',
//     redirectUri: 'http://localhost:4200/auth',
//     postLogoutRedirectUri: 'http://localhost:4200/logout',
//     realm: 'lernberg-realm',
//     clientId: 'lernberg-frontend-client',
//   },
//   // idleConfig: { idle: 10, timeout: 60, ping: 10 },
// }

export const environment = {
  production: false,
  backend: {
    baseUrl: import.meta.env['NG_APP_API_URL'],
  },
}
