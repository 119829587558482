import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  resetPasswordFrom!: FormGroup

  constructor() {
    this.resetPasswordFrom = new FormGroup({
      userEmail: new FormControl('', [Validators.required, Validators.email]),
    })
  }

  get userEmailInput() {
    return this.resetPasswordFrom.get('userEmail')
  }

  submit() {
    if (this.resetPasswordFrom.valid) {
      console.log(this.resetPasswordFrom.value)

      // Отправка письма на почту
    }
  }

  resetForm(): void {
    this.resetPasswordFrom.reset()
  }
}
