import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private isFirstVisit: boolean = true

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false)
  isShadowAnimation: BehaviorSubject<boolean> = new BehaviorSubject(false)

  isLeraBerg: BehaviorSubject<boolean> = new BehaviorSubject(true)
  isUser: BehaviorSubject<boolean> = new BehaviorSubject(false)
  isUserRecording: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor() {}

  getIsFirstVisit(): boolean {
    return this.isFirstVisit
  }

  setFirstVisit(val: boolean): void {
    this.isFirstVisit = val
  }

  setIsLoading(val: boolean) {
    this.isLoading.next(val)
  }

  setIsLeraBerg(val: boolean) {
    this.isLeraBerg.next(val)
  }

  setIsShadowAnimation(val: boolean) {
    this.isShadowAnimation.next(val)
  }

  setIsUser(val: boolean) {
    this.isUser.next(val)
  }

  setIsUserRecording(val: boolean) {
    this.isUserRecording.next(val)
  }
}
