import { Component } from '@angular/core'
import { ExamService } from './lernberg/pages/levels/service/exam.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private examService: ExamService) {}

  title = 'lernberg-front'
  // ngOnInit(): void {
  //   this.examService.authMe().subscribe(res => console.log(res))
  // }
}
