import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component'
import { ResetPasswordComponent } from './components/reset-password-page/components/reset-password/reset-password.component'
import { PasswordResultComponent } from './components/reset-password-page/components/password-result/password-result.component'
import { SavePasswordComponent } from './components/reset-password-page/components/save-password/save-password.component'
import { LoginComponent } from './components/login/login.component'
import { RegistrationComponent } from './components/registration/components/registration/registration.component'
import { RegistrationPageComponent } from './components/registration/registration-page.component'
import { VerifyPasswordComponent } from './components/registration/components/verify-password/verify-password.component'

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'registration',
    component: RegistrationPageComponent,
    // canActivate: [AuthGuard]
    children: [
      {
        path: '',
        component: RegistrationComponent,
      },
      {
        path: 'verify-password',
        component: VerifyPasswordComponent,
      },
    ],
  },
  {
    path: 'reset-password',
    component: ResetPasswordPageComponent,
    // canActivate: [AuthGuard]
    children: [
      {
        path: '',
        component: ResetPasswordComponent,
      },
      {
        path: 'save-password',
        component: SavePasswordComponent,
      },
      {
        path: 'success-change-password',
        component: PasswordResultComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
