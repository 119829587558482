<div class="wrapper">
  <div class="user-info-wrapper">
    <app-title-block title="Einstellungen" class="user-info-title"></app-title-block>

    <div class="user-info-container">
      <div class="info-avatar-section">
        <div class="user-avatar" *ngIf="userAvatar; else iconAvatar">
          <img [src]="userAvatar" alt="User avatar" />
        </div>

        <ng-template #iconAvatar>
          <div class="user-avatar">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#737088">
              <path
                d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
            </svg>
          </div>
        </ng-template>

        <div class="main-action" *ngIf="!isEditMode; else editUserInfoMode">
          <span (click)="handleChangeEditUserInfoMode(true)">Ändern</span>
        </div>

        <ng-template #editUserInfoMode>
          <div class="main-action">
            <span (click)="handleSaveChangesUserInfo()">Save changes</span>
          </div>
        </ng-template>
      </div>

      <div class="info-fields-section">
        <form [formGroup]="editUserInfoForm">
          <div class="form-items">
            <label for="">Nutzername</label>
            <span *ngIf="!isEditMode; else isEditModeInputNickname">{{ userProfile?.username }}</span>

            <ng-template #isEditModeInputNickname>
              <input type="text" formControlName="username" />
            </ng-template>
          </div>

          <div class="form-items">
            <label for="">Name</label>
            <span *ngIf="!isEditMode; else isEditModeInputFirstName">{{ userProfile?.firstName }}</span>

            <ng-template #isEditModeInputFirstName>
              <input type="text" formControlName="firstName" />
            </ng-template>
          </div>

          <div class="form-items">
            <label for="">Nachname</label>
            <span *ngIf="!isEditMode; else isEditModeInputLastName">{{ userProfile?.lastName }}</span>

            <ng-template #isEditModeInputLastName>
              <input type="text" formControlName="lastName" />
            </ng-template>
          </div>

          <div class="form-items">
            <label for="">E-mail</label>
            <span *ngIf="!isEditMode; else isEditModeInputEmail">{{ userProfile?.email }}</span>

            <ng-template #isEditModeInputEmail>
              <input type="email" formControlName="email" />
            </ng-template>
          </div>

          <!-- <div class="form-items">
            <label for="">Telefonnummer</label>
            <span *ngIf="!isEditMode; else isEditModeInputPhone">+12 234 456 67 78</span>

            <ng-template #isEditModeInputPhone>
              <input type="tel" formControlName="phone">
            </ng-template>

          </div>

          <div class="form-items">
            <label for="">Passwort</label>
            <span *ngIf="!isEditMode; else isEditModeInputPassword">*********</span>

            <ng-template #isEditModeInputPassword>
              <input type="password" formControlName="password">
            </ng-template>

          </div> -->
        </form>
      </div>
    </div>
  </div>

  <div class="info-plan-wrapper">
    <div class="info-plan">
      <h4>Aktueller plan Premium</h4>
      <ul>
        <li>Все темы во всех разделах</li>
        <li>Возможность повтора диалогов неограниченное количество раз разной степени сложности</li>
        <li>Возможность сохранить текст / диалог в Word-документ и скачать его</li>
        <li>Возможность загрузить свой текст для проверки</li>
        <li>Возможность загрузить свою тему для дальнейшей проработки</li>
        <li>Рекомендации по устойчивым выражениям</li>
        <li>Индивидуальный контроль ошибок и обратная связь</li>
      </ul>
    </div>
    <div class="actions">
      <button class="actions-plan">Aktuelle pläne Premium</button>
      <button class="actions-education">Weiter lernen</button>
    </div>
  </div>
</div>
