import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  userAvatar = '' // Путь к реальному фото пользователя
  isEditMode = false

  userProfile: any = null
  userRoles: string[] = []

  editUserInfoForm!: FormGroup

  constructor() {
    // this.createEditUserInfoForm();
  }

  async ngOnInit() {
    // this.userProfile = await this.keycloakUserService.loadUserProfile()
    // this.userRoles = this.keycloakUserService.getUserRoles()

    console.log(this.userProfile)
    await this.createEditUserInfoForm()
  }

  private createEditUserInfoForm() {
    this.editUserInfoForm = new FormGroup({
      username: new FormControl(this.userProfile.username),
      firstName: new FormControl(this.userProfile.firstName),
      lastName: new FormControl(this.userProfile.lastName),
      email: new FormControl(this.userProfile.email),
      /*   phone: new FormControl(''),
      password: new FormControl(''), */
    })
  }

  handleChangeEditUserInfoMode(flag: boolean) {
    this.isEditMode = flag
  }

  async handleSaveChangesUserInfo() {
    this.handleChangeEditUserInfoMode(false)

    console.log(this.editUserInfoForm.value)
    console.log(this.userRoles)
    console.log(this.userProfile)

    try {
      const updatedProfile = { ...this.userProfile, ...this.editUserInfoForm.value }

      console.log(JSON.stringify(updatedProfile))
      // await this.keycloakAdminService.updateUserProfile(updatedProfile)
      console.log('User profile updated successfully')
    } catch (error) {
      console.error('Error updating user profile', error)
    }
  }
}
