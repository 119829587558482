export enum Roles {
  Admin = 'admin',
  Free = 'user_free',
  Optimum = 'optimum_account',
  Premium = 'premium_account',
}

export const allTextItems = {
  [Roles.Free]: {
    first: `In jedem Abschnitt der von dir gewählten Prüfung stehen dir mehrere Themen zur Verfügung. \n Wähle zuerst den Skill, dann den Teil und schließlich das Thema aus.`,
    second:
      'Jetzt kannst du ein Thema auswählen und trainieren. \n Bitte beachte, dass du im Free-Tarif nur ein Thema auswählen und dieses nur einmal trainieren kannst. \n Suche daher ein besonders relevantes Thema für dich aus, um zu sehen, wie das System funktioniert. \n Mehr Optionen sind im Optimum-Tarif verfügbar.',
    third:
      'An dieser Stelle fragt dich Lera nach fehlenden Informationen. \n Bitte antworte vollständig und korrekt, da das Ergebnis und dein Erfolg davon abhängen. \n Am Ende der Konversation erhältst du eine Transkription deines Dialogs.',
  },
  [Roles.Optimum]: {
    first: 'In jedem Abschnitt der von dir gewählten Prüfung stehen dir mehrere Themen zur Verfügung. \n Wähle zuerst den Skill, dann den Teil und schließlich das Thema aus.',
    second:
      'Du kannst jedes Thema maximal dreimal trainieren. Dazu hast du auch die Option, jedes Thema in einem normalen, vereinfachten oder komplizierten Modus zu üben. \n Mehr Optionen sind im Premium-Tarif verfügbar.',
    third:
      'An dieser Stelle fragt dich Lera nach fehlenden Informationen. \n Bitte antworte vollständig und korrekt, da das Ergebnis und dein Erfolg davon abhängen. \n Am Ende der Konversation erhältst du eine Transkription deines Dialogs. \n Alle Transkriptionen findest Du in Deinem persönlichen Bereich.',
  },
  [Roles.Premium]: {
    first: 'In jedem Abschnitt der von dir gewählten Prüfung stehen dir mehrere Themen zur Verfügung. \n Wähle zuerst den Skill, dann den Teil und schließlich das Thema aus.',
    second:
      'Du kannst jedes Thema mehrmals trainieren. Dazu hast du auch die Option, jedes Thema in einem normalen, vereinfachten oder komplizierten Modus zu üben. \n Nach der Konversation erhältst du auch Feedback mit einem personalisierten Fehlerbericht sowie Empfehlungen, z. B. welche Redewendungen und Sprichwörter du anwenden kannst.',
    third:
      'Alle deine Konversationen stehen dir zum Download in deinem persönlichen Bereich zur Verfügung. \n Du kannst gerne ein eigenes Thema vorschlagen. Wir bereiten dein Thema vor und benachrichtigen dich, sobald es fertig ist. \n Möchtest du deinen Text korrigieren lassen, ist das ebenfalls möglich. Lade den Text hoch und erhalte eine Korrektur sowie Empfehlungen zur Verbesserung.',
  },
}

export const welcomeTitle = 'Glückwunsch!'
export const sliderTitle = 'Fangen wir an?'
