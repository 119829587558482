import { NgModule, isDevMode, APP_INITIALIZER } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { HomeComponent } from './lernberg/pages/home/home.component'
import { ProfileLayoutsComponent } from './shared/components/profile-layouts/profile-layouts.component'
import { HeaderComponent } from './shared/components/layouts/header.component'
import { LevelsComponent } from './lernberg/pages/levels/levels.component'
import { LevelsBlockComponent } from './shared/components/levels-block/levels-block.component'
import { StoreModule } from '@ngrx/store'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { EffectsModule } from '@ngrx/effects'
import { ExamsEffects } from './redux/effects/app.effects'
import { appReducer } from './redux/reducers/app.reducer'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { TopicsComponent } from './lernberg/pages/topics/topics.component'
import { ExamsComponent } from './lernberg/components/exams-component/exams-component.component'
import { TopicItemComponent } from './lernberg/components/thema-item-component/thema-item-component.component'
import { ConversationComponent } from './lernberg/pages/conversation/conversation.component'
import { ConversationAnswerComponent } from './lernberg/components/conversation-answer/conversation-answer.component'
import { ExamService } from './lernberg/pages/levels/service/exam.service'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { DetailsexamComponent } from './lernberg/pages/detailsexam/detailsexam.component'
import { SectionsBlockComponent } from './shared/components/sections-block/sections-block.component'
import { TeilBlockComponent } from './shared/components/teil-block/teil-block.component'
import { RouterModule } from '@angular/router'
import { MainShadowComponent } from './shared/components/main-shadow/main-shadow.component'
import { CustomScrollComponent } from './shared/components/custom-scroll/custom-scroll.component'
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component'
import { AudioComponent } from './lernberg/pages/audio/audio.component'
import { AudioUserComponent } from './shared/components/audio-user/audio-user.component'
import { TruncatePipe } from './shared/pipe/truncate.pipe'
import { SkillsComponent } from './lernberg/components/skills/skills.component'
import { TeilsComponent } from './lernberg/components/teils/teils.component'
import { AudioAnimationComponent } from './lernberg/components/audio-animation/audio-animation.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TimerComponent } from './lernberg/components/timer/timer.component'
import { ExerciseComponent } from './lernberg/components/exercise-component/exercise-component.component'
import { RatesComponent } from './lernberg/pages/rates/rates.component'
import { RatesItemComponent } from './lernberg/components/rates-item/rates-item.component'
import { ReactiveFormsModule } from '@angular/forms'
import { CustomLoaderComponent } from './shared/components/custom-loader/custom-loader.component'
import { FormsModule } from '@angular/forms'
import { SearchPipe } from './lernberg/pages/conversation/pipes/search.pipe'
import { TextComponent } from './lernberg/pages/text/text.component'
import { ChatComponent } from './lernberg/components/chat/chat.component'
import { CustomToastComponent } from './shared/components/custom-toast/custom-toast.component'
import { ResultsComponent } from './lernberg/pages/results/results.component'
import { ResultItemComponent } from './shared/components/result-item/result-item.component'
import { ConversationResultComponent } from './lernberg/pages/conversation-result/conversation-result.component'
import { ChatResultComponent } from './lernberg/components/chat-result/chat-result.component'
import { SplitParagraphsPipe } from './shared/pipe/splitParagraphs.pipe'
import { SearchConversationPipe } from './lernberg/pages/conversation/pipes/searchConveration.pipe'
import { NgxPaginationModule } from 'ngx-pagination'
// import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
// import { environment } from 'environment'
import { WelcomeComponent } from './lernberg/pages/welcome/welcome.component'
// import { TokenInterceptor } from './services/token.interceptor'
import { UserProfileComponent } from './lernberg/pages/user-profile/user-profile.component'
import { ContactsHelpComponent } from './lernberg/pages/contacts-help/contacts-help.component'
import { TooltipDirective } from './shared/directive/tooltip.directive'
import { CommonModule } from '@angular/common'
import { TooltipComponent } from './shared/components/tooltip/tooltip.component'
import { HighlightDirective } from './lernberg/pages/welcome/directives/highlight.directive'
import { UserInfoComponent } from './lernberg/pages/user-info/user-info.component'
import { SharedModule } from './shared/shared.module'
import { RechtlichesModule } from './features/juridical/rechtliches.module'
import { NotFoundComponent } from './lernberg/pages/not-found/not-found.component'
import { AuthModule } from './auth/auth.module'
import { ContactsModule } from './features/contacts/contacts.module'

// export const initializeKeycloak = (keycloak: KeycloakService) => async () =>
//   keycloak.init({
//     config: {
//       url: environment.keycloak.authority,
//       realm: environment.keycloak.realm,
//       clientId: environment.keycloak.clientId,
//     },
//     initOptions: {
//       onLoad: 'check-sso',
//       silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
//       // checkLoginIframe: false,
//       // redirectUri: environment.keycloak.redirectUri,
//       scope: 'openid profile email users:me',
//     },
//     loadUserProfileAtStartUp: true,
//   })

@NgModule({
  declarations: [
    AppComponent,
    TruncatePipe,
    HomeComponent,
    ProfileLayoutsComponent,
    HeaderComponent,
    LevelsComponent,
    LevelsBlockComponent,
    TopicsComponent,
    ExamsComponent,
    TopicItemComponent,
    ConversationComponent,
    ConversationAnswerComponent,
    DetailsexamComponent,
    SectionsBlockComponent,
    TeilBlockComponent,
    MainShadowComponent,
    CustomScrollComponent,
    BreadcrumbComponent,
    AudioComponent,
    AudioUserComponent,
    AudioAnimationComponent,
    SkillsComponent,
    TeilsComponent,
    TimerComponent,
    ExerciseComponent,
    RatesComponent,
    RatesItemComponent,
    CustomLoaderComponent,
    SearchPipe,
    SearchConversationPipe,
    TextComponent,
    ChatComponent,
    CustomToastComponent,
    ResultsComponent,
    ResultItemComponent,
    ConversationResultComponent,
    ChatResultComponent,
    SplitParagraphsPipe,
    WelcomeComponent,
    UserProfileComponent,
    ContactsHelpComponent,
    TooltipDirective,
    TooltipComponent,
    HighlightDirective,
    UserInfoComponent,
    NotFoundComponent,
  ],

  imports: [
    CommonModule,
    BrowserModule,
    NgxPaginationModule,
    // KeycloakAngularModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forRoot({ app: appReducer }, {}),
    EffectsModule.forRoot([ExamsEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    BrowserAnimationsModule,
    ContactsModule,
    RechtlichesModule,
    SharedModule,
    AuthModule,
  ],
  providers: [
    ExamService,
    Location,
    SearchPipe,
    SearchConversationPipe,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeKeycloak,
    //   multi: true,
    //   deps: [KeycloakService],
    // },
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
