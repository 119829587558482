import { RouterModule, Routes } from '@angular/router'
// import { AuthGuard } from 'src/app/guards/auth.guard'
import { NgModule } from '@angular/core'
import { JuridicalComponent } from 'src/app/lernberg/pages/juridical/juridical.component'
import { RechtlichesComponent } from './rechtlihes/rehtliches.component'
import { DatenschutzComponent } from './datenschutzerklärung/datenschutz.component'
import { AgbComponent } from './agb/agb.component'

const routes: Routes = [
  {
    path: '',
    component: RechtlichesComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'impressum', pathMatch: 'full' },
      { path: 'impressum', component: JuridicalComponent },
      { path: 'agb', component: AgbComponent },
      { path: 'datenschutzerklärung', component: DatenschutzComponent },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RechtlihesRoutingModule {}
