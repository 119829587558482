<div>
  <app-title-block title="FAQ" class="faq-title title-width"></app-title-block>
</div>
<div class="masonry-layout">
  <div class="masonry-layout__panel" *ngFor="let item of data">
    <div class="masonry-layout__panel-content">
      <app-faq-block [item]="item"></app-faq-block>
    </div>
  </div>
</div>
