<div class="animation-container">
  <div class="wrapper">
    <svg [ngClass]="{ svg1: isShadowAnimation }" xmlns="http://www.w3.org/2000/svg" width="311" height="318" viewBox="0 0 311 318" fill="none">
      <g filter="url(#filter0_f_1_736)">
        <path
          [ngClass]="{ shadow1: isShadowAnimation }"
          d="M276.5 168C276.5 221.019 238.519 283 185.5 283C132.481 283 35 221.019 35 168C35 114.981 97.4807 34.5 150.5 34.5C203.519 34.5 276.5 114.981 276.5 168Z"
          fill="url(#paint0_linear_1_736)" />
      </g>
      <defs>
        <filter id="filter0_f_1_736" x="0.730064" y="0.230064" width="310.04" height="317.04" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="17.135" result="effect1_foregroundBlur_1_736" />
        </filter>
        <linearGradient id="paint0_linear_1_736" x1="131" y1="72" x2="131" y2="264" gradientUnits="userSpaceOnUse">
          <stop [ngClass]="{ stop1: isShadowAnimation }" stop-color="#00FFA3" stop-opacity="0.48" />
          <stop [ngClass]="{ stop2: isShadowAnimation }" offset="1" stop-color="#3300FF" stop-opacity="0.2" />
        </linearGradient>
      </defs>
    </svg>
  </div>

  <div class="wrapper">
    <svg [ngClass]="{ svg2: isShadowAnimation }" xmlns="http://www.w3.org/2000/svg" width="357" height="246" viewBox="0 0 357 246" fill="none">
      <g filter="url(#filter0_f_1_734)">
        <path
          [ngClass]="{ shadow2: isShadowAnimation }"
          d="M322 149C322 198.706 202.706 211 153 211C103.294 211 34.5 106.205 34.5 56.4995C34.5 6.79388 124.294 56.4998 174 56.4998C223.706 56.4998 322 99.2944 322 149Z"
          fill="url(#paint0_linear_1_734)" />
        <path
          [ngClass]="{ shadow3: isShadowAnimation }"
          d="M322 149C322 198.706 202.706 211 153 211C103.294 211 34.5 106.205 34.5 56.4995C34.5 6.79388 124.294 56.4998 174 56.4998C223.706 56.4998 322 99.2944 322 149Z"
          fill="url(#paint1_linear_1_734)" />
      </g>
      <defs>
        <filter id="filter0_f_1_734" x="0.230064" y="0.138268" width="356.04" height="245.132" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="17.135" result="effect1_foregroundBlur_1_734" />
        </filter>
        <linearGradient id="paint0_linear_1_734" x1="81.6058" y1="43.4038" x2="207.231" y2="211" gradientUnits="userSpaceOnUse">
          <stop [ngClass]="{ stop5: isShadowAnimation }" stop-color="#FF2E00" />
          <stop [ngClass]="{ stop6: isShadowAnimation }" offset="1" stop-color="#1400FF" stop-opacity="0.2" />
        </linearGradient>
        <linearGradient id="paint1_linear_1_734" x1="153" y1="31" x2="153" y2="211" gradientUnits="userSpaceOnUse">
          <stop [ngClass]="{ stop3: isShadowAnimation }" stop-color="#00FFA3" stop-opacity="0.58" />
          <stop [ngClass]="{ stop4: isShadowAnimation }" offset="1" stop-color="#3300FF" stop-opacity="0.2" />
        </linearGradient>
      </defs>
    </svg>
  </div>

  <div class="wrapper">
    <div class="bar-container" [ngClass]="{ loaderBlock: isLoading }">
      <div class="bar bar1" [ngClass]="{ bar1: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar2: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar3: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar4: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar5: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar6: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar7: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar8: isShadowAnimation }"></div>
      <div class="bar" [ngClass]="{ bar9: isShadowAnimation }"></div>
    </div>

    <div [ngClass]="{ loaderContainer: isLoading }">
      <div [ngClass]="{ loader: isLoading }"></div>
    </div>
  </div>
</div>
