<!-- <div class="back-container">
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none" (click)="goBack()">
    <path d="M15 1.75L4 15.6651L15 29.25" stroke="#2E294E" stroke-width="5" />
  </svg>
  <span class="btn-back" (click)="goBack()" (keyup)="goBack()" tabindex="1">Back</span>
</div> -->
<div class="container-details">
  <div *ngIf="exam$ | async as exam">
    <app-title-block title="Prüfung" [isSelected]="true"></app-title-block>
    <app-levels-block [item]="exam" [selected]="true"></app-levels-block>
  </div>

  <div>
    <app-title-block title="Skill" [isSelected]="isSkillSelected"></app-title-block>
    <div *ngFor="let item of sections$ | async" [ngClass]="{ active: activeSkillBlock === item.id }">
      <app-sections-block [item]="item" (click)="isSelectedSkill(item.id)"></app-sections-block>
    </div>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>

  <!-- <div *ngIf="isSkillSelected">
    <app-title-block title="Teil" [isSelected]="isTeilSelected"></app-title-block>
    <div *ngFor="let item of teil$ | async" [ngClass]="{ active: activeTheilBlock === item.id }">
      <app-teil-block [item]="item" (click)="isSelectedTeil(item.id)"></app-teil-block>
    </div>
  </div> -->
</div>
