<div class="container">
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <div>
    <div class="submenu">
      <h3>Hilfe und Kontakt</h3>
      <div>
        <a routerLink="faq" routerLinkActive="active">FAQ</a>
      </div>
      <div>
        <a routerLink="contact" routerLinkActive="active">Kontakt</a>
      </div>
    </div>
  </div>
</div>
