<div class="wrapper">
  <h1>Neues Passwort</h1>

  <p>Legen Sie bitte ein neues Passwort fest</p>

  <form [formGroup]="addNewPasswordForm">
    <div class="fields-wrapper">
      <div class="input-container">
        <div class="error" *ngIf="password?.invalid && (password?.touched || password?.dirty); else passwordLabel">
          <span *ngIf="password?.hasError('required')">Passwort</span>
        </div>

        <ng-template #passwordLabel><label for="password">Passwort</label></ng-template>

        <div class="input-wrapper">
          <div *ngIf="!isVisiblePassword; else visible">
            <span class="material-symbols-outlined password-icon" (click)="toggleVisiblePassword()"> visibility_off </span>
          </div>

          <ng-template #visible>
            <span class="material-symbols-outlined password-icon" (click)="toggleVisiblePassword()">visibility</span>
          </ng-template>

          <input
            id="password"
            [type]="!isVisiblePassword ? 'password' : 'text'"
            formControlName="password"
            [class.error-border]="password?.invalid && (password?.touched || password?.dirty)" />
        </div>
      </div>

      <div class="input-container">
        <div class="error" *ngIf="confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty); else passwordRepeatLabel">
          <span *ngIf="confirmPassword?.hasError('required')">Passwort bestätigen</span>
        </div>

        <span class="error" *ngIf="addNewPasswordForm.hasError('passwordMismatch') && confirmPassword?.touched"> Passwords do not match. </span>

        <ng-template #passwordRepeatLabel><label for="password">Passwort bestätigen</label></ng-template>

        <div class="input-wrapper">
          <div *ngIf="!isVisibleRepeatPassword; else visibleRepeat">
            <span class="material-symbols-outlined password-icon" (click)="toggleVisibleRepeatPassword()"> visibility_off </span>
          </div>

          <ng-template #visibleRepeat>
            <span class="material-symbols-outlined password-icon" (click)="toggleVisibleRepeatPassword()">visibility</span>
          </ng-template>

          <input
            id="passwordRepeat"
            [type]="!isVisibleRepeatPassword ? 'password' : 'text'"
            formControlName="passwordRepeat"
            [class.error-border]="confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty)" />
        </div>
      </div>
    </div>
    <button (click)="submit()" [disabled]="addNewPasswordForm.invalid">Passwort speichern</button>
  </form>
</div>
