<div class="content-wrapper">
  <div class="results-wrapper">
    <app-title-block title="Meine bisherige Konversationen" class="results-title"></app-title-block>
    <div class="results-container" *ngIf="allConversations$ | async; else noConversation">
      <div class="results-nav">
        <div class="selectors">
          <select name="exam" id="exam" [(ngModel)]="selectedExam" (change)="filterConversations()">
            <option value="all">alle Prüfungen</option>
            <option *ngFor="let option of dataExam" [value]="option">{{ option }}</option>
          </select>

          <select name="skill" id="skill" [(ngModel)]="selectedSkill" (change)="filterConversations()">
            <option value="all">alle Skills</option>
            <option *ngFor="let option of dataSkill" [value]="option">{{ option }}</option>
          </select>

          <select name="teil" id="teil" [(ngModel)]="selectedTeil" (change)="filterConversations()">
            <option value="all">alle Teilen</option>
            <option *ngFor="let option of dataTeils" [value]="option">{{ option }}</option>
          </select>
        </div>

        <div class="buttons">
          <!-- <button class="underline-text" (click)="resetFilters()">Filter zurücksetzen</button> -->
          <div class="input-wrapper">
            <span class="search"></span>
            <input class="topics-input" type="text" placeholder="Suchen" [(ngModel)]="valuesSearch" (ngModelChange)="changeSearch(valuesSearch)" />
          </div>
        </div>
      </div>

      <div>
        <div class="text-placeholder-no-data" *ngIf="!allConversations$ || (allConversations$ | async).length === 0; else unset">Noch keine abgeschlossenen Konversationen</div>

        <ng-template #unset>
          <div *ngIf="allConversations$" class="results">
            <div *ngFor="let result of filteredConversations | searchconversation: settingsSearchValue | paginate: { itemsPerPage: itemsPerPage, currentPage: p }">
              <app-result-item [item]="result" [routerLink]="['/result', result.id]"></app-result-item>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="pagination-wrapper">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  </div>

  <ng-template #noConversation>
    <div class="text-placeholder-no-data">Noch keine abgeschlossenen Konversationen</div>
  </ng-template>

  <!-- <app-exams-component class="topics-page"></app-exams-component> -->
</div>
