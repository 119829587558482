<div class="skills-block">
  <div>
    <app-title-block title="Skill" [isSelected]="isSkillSelected"></app-title-block>
    <div *ngFor="let item of sections$ | async" [ngClass]="{ active: activeSkillBlock === item.id }">
      <app-sections-block [item]="item" (click)="isSelectedSkill(item.id)"></app-sections-block>
    </div>
  </div>
  <div *ngIf="isSkillSelected">
    <router-outlet></router-outlet>
    <!-- <app-title-block title="Teil" [isSelected]="isTeilSelected"></app-title-block>
    <div *ngFor="let item of teil$ | async" [ngClass]="{ active: activeTheilBlock === item.id }">
      <app-teil-block [item]="item" (click)="isSelectedTeil(item.id)"></app-teil-block>
    </div> -->
  </div>
</div>
