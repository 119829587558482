<div class="wrapper">
  <div class="container">
    <div class="close-button">
      <button>
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>

    <div class="login-container">
      <div class="login-block">
        <h1>Anmelden</h1>

        <div class="form-container">
          <form [formGroup]="loginForm">
            <div class="fields-wrapper">
              <div class="form-input-container">
                <div class="input-container">
                  <div class="error" *ngIf="userEmailInput?.invalid && (userEmailInput?.touched || userEmailInput?.dirty); else emailLabel">
                    <span *ngIf="userEmailInput?.hasError('required')">Please enter a login email</span>
                    <span *ngIf="userEmailInput?.hasError('email')">The login email is invalid</span>
                  </div>

                  <ng-template #emailLabel><label for="email">E-Mail-Adresse</label></ng-template>

                  <input type="email" id="email" formControlName="userEmail" />
                </div>

                <div class="input-container">
                  <div class="error" *ngIf="passwordInput?.invalid && (passwordInput?.touched || passwordInput?.dirty); else passwordLabel">
                    <span *ngIf="passwordInput?.hasError('required')">Please enter a login email</span>
                  </div>

                  <ng-template #passwordLabel><label for="password">Passwort</label></ng-template>

                  <div class="input-wrapper">
                    <div *ngIf="!isVisiblePassword; else visible">
                      <span class="material-symbols-outlined password-icon" (click)="toggleVisiblePassword()"> visibility_off </span>
                    </div>

                    <ng-template #visible>
                      <span class="material-symbols-outlined password-icon" (click)="toggleVisiblePassword()">visibility</span>
                    </ng-template>
                    <input id="password" [type]="!isVisiblePassword ? 'password' : 'text'" formControlName="password" />
                  </div>
                </div>
              </div>

              <div class="form-link">Ich habe mein Passwort vergessen</div>
            </div>
          </form>

          <div class="action-buttons-wrapper">
            <button (click)="submit()" [disabled]="loginForm.invalid">Anmelden</button>
            <div class="link-wrapper">Noch nicht registriert? <span routerLink="/auth/registration">Registrieren</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
