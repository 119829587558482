import { Component, OnInit } from '@angular/core'
import { welcomeTitle, sliderTitle, Roles, allTextItems } from './constants/welcome-constants'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { selectLevelExam } from '../../../redux/selectors/app.selectors'
import { Router } from '@angular/router'
// import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  firstSlideImg = '../../../../assets/slider/first-slide.png'
  secondSlideFreeImg = '../../../../assets/slider/second-slide-free.png'
  secondSlideOptimumImg = '../../../../assets/slider/second-slide-optimum.png'
  secondSlidePremiumImg = '../../../../assets/slider/second-slide-premium.png'
  thirdSlideFreeImg = '../../../../assets/slider/third-slide-free.png'
  thirdSlideOptimumImg = '../../../../assets/slider/third-slide-optimum.png'
  thirdSlidePremiumImg = '../../../../assets/slider/third-slide-premium.png'

  rolesIno = {
    [Roles.Free as string]: {
      name: 'Free',
      items: [
        {
          text: this.getParagraphs(allTextItems[Roles.Free].first),
          highlightText: ['Prüfung stehen', 'den Skill', 'den Teil', 'das Thema'],
          image: this.firstSlideImg,
          active: true,
        },
        {
          text: this.getParagraphs(allTextItems[Roles.Free].second),
          highlightText: ['trainieren', 'nur einmal', 'System funktioniert', 'Optimum-Tarif'],
          image: this.secondSlideFreeImg,
          active: false,
        },
        {
          text: this.getParagraphs(allTextItems[Roles.Free].third),
          highlightText: ['fragt dich Lera', 'vollständig und korrekt', 'das Ergebnis', 'abhängen', 'Transkription'],
          image: this.thirdSlideFreeImg,
          active: false,
        },
      ],
    },
    [Roles.Optimum as string]: {
      name: 'Optimum',
      items: [
        {
          text: this.getParagraphs(allTextItems[Roles.Optimum].first),
          highlightText: ['Prüfung stehen', 'den Skill', 'den Teil', 'das Thema'],
          image: this.firstSlideImg,
          active: true,
        },
        {
          text: this.getParagraphs(allTextItems[Roles.Optimum].second),
          highlightText: ['maximal dreimal', 'Premium-Tarif'],
          image: this.secondSlideOptimumImg,
          active: false,
        },
        {
          text: this.getParagraphs(allTextItems[Roles.Optimum].third),
          highlightText: ['fragt dich Lera', 'das Ergebnis', 'Transkription', 'persönlichen Bereich'],
          image: this.thirdSlideOptimumImg,
          active: false,
        },
      ],
    },
    [Roles.Premium as string]: {
      name: 'Premium',
      items: [
        {
          text: this.getParagraphs(allTextItems[Roles.Premium].first),
          highlightText: ['Prüfung stehen', 'den Skill', 'den Teil', 'das Thema'],
          image: this.firstSlideImg,
          active: true,
        },
        {
          text: this.getParagraphs(allTextItems[Roles.Premium].second),
          highlightText: ['mehrmals trainieren', 'Feedback'],
          image: this.secondSlidePremiumImg,
          active: false,
        },
        {
          text: this.getParagraphs(allTextItems[Roles.Premium].third),
          highlightText: ['Download', 'Thema vorschlagen', 'Text korrigieren lassen'],
          image: this.thirdSlidePremiumImg,
          active: false,
        },
      ],
    },
  }

  userRoles = {
    Admin: 'admin',
    Free: 'user_free',
    Optimum: 'optimum_account',
    Premium: 'premium_account',
  }

  userRoles1 = ['admin', 'user_free', 'premium_account']

  welcomeTitleConst = welcomeTitle
  sliderTitleConst = sliderTitle
  premiumAcc: string = this.rolesIno[Roles.Premium].name
  role!: string // Текст для заголовка
  activeRole!: string // Активная роль пользователя

  isUserSelect = false
  userSelectLevel = ''
  levels$!: Observable<{ id: string; title: string }[]>

  isLastSlide = false
  roleAccount = ''
  items!: any

  ngOnInit(): void {
    this.getUserRole()
    this.getProfil()

    /*  this.role = this.rolesIno[Roles.Premium].name
     this.activeRole = Roles.Premium */

    this.role = this.rolesIno[this.roleAccount].name
    this.activeRole = this.roleAccount
  }

  constructor(
    private store: Store,
    private router: Router
    // private authService: AuthService
  ) {
    this.levels$ = this.store.select(selectLevelExam())
  }

  getUserRole() {
    // const roles = this.authService.getUserRoles()
    // roles.some(item => {
    //   this.userRoles1.includes(item) ? (this.roleAccount = item) : null
    // })
  }

  getProfil() {
    // const profil = this.authService.getProfile()
    // console.log(profil)
  }

  handleSelectedLevel(selectedLevel: { id: string; title: string }) {
    this.userSelectLevel = selectedLevel.id
  }

  handleSaveLevel() {
    this.isUserSelect = true

    // Отправляем выбранный уровень на сервер
    // this.userSelectLevel
  }

  handleRedirectToProfile() {
    this.router.navigate(['/rates'])
  }

  handleRedirectToNextStep() {
    this.router.navigate(['/exams'])
  }

  handleNextSlide() {
    const currentActiveIndex = this.rolesIno[this.activeRole].items.findIndex(item => item.active)
    let nextIndex = currentActiveIndex + 1
    if (nextIndex >= 2) {
      this.isLastSlide = true
      nextIndex = 2
    }
    this.rolesIno[this.activeRole].items[currentActiveIndex].active = false
    this.rolesIno[this.activeRole].items[nextIndex].active = true
  }

  getParagraphs(str: string): string[] {
    const arr = str.split('\n')
    return arr.map(elem => elem.trim())
  }
}
