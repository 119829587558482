import { Component, OnInit } from '@angular/core'
import { ILevel } from '../models/levels.model'
import { ExamService } from './service/exam.service'
import { Store } from '@ngrx/store'
import * as examsActions from '../../../redux/actions/exams.actions'
import { Observable } from 'rxjs'
import { selectData } from 'src/app/redux/selectors/app.selectors'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss'],
})
export class LevelsComponent implements OnInit {
  exams$: Observable<ILevel[]> | undefined
  data$: Observable<any> | undefined

  constructor(
    private examService: ExamService,
    private store: Store,
    private pageStateService: PageStateService
  ) {}

  ngOnInit(): void {
    this.getData()
    this.data$ = this.store.select(selectData)
    this.pageStateService.setBreadcrumbs('')
  }

  getData() {
    this.examService.getData().subscribe(res => this.store.dispatch(examsActions.loadDataSuccess({ data: res })))
  }
}
