<div>
  <app-title-block title="Kontakt" class="kontakt-title title-width"></app-title-block>
</div>
<div class="adress-container">
  <div>
    <p>221b, Baker street</p>
    <p>34132 London</p>
  </div>
  <div>
    <p>Tel. +45 8744 4000</p>
    <p>Mail. lera&#64;lera.de</p>
  </div>
  <div class="icon">
    <div>
      <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4577_74277)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.51491 15.1716C12.521 18.8571 16.2323 19.2607 17.3215 19.2976C18.6387 19.3421 19.9823 18.353 20.5629 17.3394C19.6362 16.3388 18.4291 15.5628 17.1065 14.7213C16.327 15.4385 15.3658 16.7708 14.0848 16.2942C13.3574 16.0235 11.5592 15.2617 9.98806 13.8163C8.41683 12.3707 7.58885 10.7165 7.29468 10.0474C6.77612 8.86776 8.2276 7.98179 9.00766 7.26413C8.09355 6.02722 7.2639 4.88835 6.17858 4.07858C5.06252 4.61571 3.98107 5.84239 4.03014 7.06946C4.07023 8.07155 4.50887 11.486 8.51491 15.1716ZM17.2449 21.2129C15.7439 21.1621 11.4932 20.6222 7.04178 16.5268C2.59033 12.4315 2.00345 8.52085 1.94823 7.13994C1.86406 5.03596 3.61593 2.99165 5.64053 2.19339C6.11559 2.00608 6.691 2.04036 7.14587 2.34561C8.82049 3.46942 9.97585 5.1731 10.9681 6.5071C11.3908 7.07545 11.321 7.84646 10.7843 8.34021L9.37139 9.64006C9.69932 10.3064 10.3684 11.4555 11.4612 12.4609C12.554 13.4664 13.8031 14.0818 14.5274 14.3836L15.9395 13.0843C16.4767 12.5902 17.3184 12.525 17.9376 12.9197C19.4167 13.8625 21.1539 14.9104 22.4206 16.4028C22.7763 16.822 22.8292 17.3702 22.6171 17.8258C21.7454 19.697 19.5395 21.2906 17.2449 21.2129Z"
            fill="#BAB8C3" />
        </g>
        <defs>
          <clipPath id="clip0_4577_74277">
            <rect width="25" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div>
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4577_74283)">
          <path
            d="M21.6719 4.32812C22.8141 4.32812 23.7499 5.21199 23.8326 6.33309L23.8385 6.49479V19.4948C23.8385 20.637 22.9547 21.5728 21.8336 21.6555L21.6719 21.6615H4.33854C3.19632 21.6615 2.26053 20.7776 2.17782 19.6565L2.17188 19.4948V6.49479C2.17188 5.35257 3.05574 4.41678 4.17684 4.33407L4.33854 4.32812H21.6719ZM21.6719 8.02688L14.1542 15.5445C13.5196 16.1791 12.4907 16.1791 11.8561 15.5445L4.33854 8.02688V19.4948H21.6719V8.02688ZM20.1398 6.49479H5.87047L13.0052 13.6295L20.1398 6.49479Z"
            fill="#BAB8C3" />
        </g>
        <defs>
          <clipPath id="clip0_4577_74283">
            <rect width="26" height="26" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4577_74289)">
          <path
            d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.2364 22 8.57709 21.5427 7.13692 20.7397L6.83171 20.5624L3.79975 21.4542C3.06936 21.6691 2.38822 21.0329 2.51987 20.3093L2.54581 20.2002L3.43756 17.1683C2.52505 15.6594 2 13.8896 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 13.5769 4.4552 15.0444 5.24098 16.2818C5.43935 16.5943 5.52374 16.9781 5.45652 17.3589L5.41832 17.5217L4.97667 19.0233L6.47827 18.5817C6.91075 18.4545 7.36114 18.5323 7.71817 18.759C8.95564 19.5448 10.4231 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM9.10162 7.18408C9.31746 7.09158 9.57889 7.1085 9.78556 7.25926C10.29 7.62722 10.6905 8.12076 11.0344 8.60344L11.3612 9.07746C11.4132 9.15417 11.4639 9.22933 11.5138 9.30228C11.6963 9.56921 11.6753 9.9239 11.4685 10.166L11.3927 10.2422L10.4693 10.928C10.3778 10.996 10.3473 11.1195 10.4022 11.2195C10.6112 11.5998 10.9834 12.1657 11.4093 12.5916C11.8357 13.018 12.4284 13.4143 12.8348 13.6467C12.9226 13.6969 13.0294 13.6811 13.101 13.6157L13.1394 13.5706L13.7402 12.6555C13.9705 12.349 14.4007 12.282 14.7134 12.4984L15.2563 12.8768C15.7957 13.2618 16.315 13.6757 16.7255 14.2014C16.8872 14.4085 16.9112 14.6792 16.8148 14.9042C16.4188 15.8283 15.4165 16.6153 14.374 16.5769L14.2155 16.5678L14.0235 16.5488C13.9889 16.5446 13.953 16.5399 13.9158 16.5346L13.6781 16.4952C12.7544 16.3208 11.2726 15.797 9.73827 14.2627C8.20397 12.7284 7.68017 11.2466 7.50573 10.3229L7.46632 10.0852L7.44126 9.87742L7.42756 9.70191C7.42606 9.67547 7.42491 9.65047 7.42404 9.62695C7.38562 8.58294 8.17688 7.5804 9.10162 7.18408Z"
            fill="#BAB8C3" />
        </g>
        <defs>
          <clipPath id="clip0_4577_74289">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4577_74295)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.7444 6.30373C22.9445 5.09051 21.7296 4.13225 20.5964 4.6094L2.7836 12.1096C1.5415 12.6325 1.47785 14.4281 2.78449 14.9698C3.75124 15.3705 5.23079 15.9581 6.71467 16.4474C7.93182 16.8486 9.25868 17.2211 10.3216 17.3265C10.6126 17.6747 10.9789 18.009 11.351 18.315C11.9208 18.7836 12.6069 19.2671 13.3151 19.7299C14.7335 20.6567 16.3072 21.5434 17.3679 22.1196C18.6356 22.8081 20.1531 22.0153 20.381 20.6336L22.7444 6.30373ZM4.78133 13.5288L20.5352 6.8956L18.3293 20.2709C17.2892 19.7053 15.7898 18.8582 14.4547 17.9859C13.7793 17.5445 13.1624 17.1074 12.6742 16.7059C12.5003 16.5629 12.3532 16.4327 12.2306 16.3161L16.3567 12.1899C16.7635 11.7831 16.7635 11.1235 16.3567 10.7168C15.9499 10.31 15.2903 10.31 14.8836 10.7168L10.3646 15.2357C9.60057 15.138 8.53553 14.854 7.367 14.4688C6.46771 14.1723 5.56351 13.8355 4.78133 13.5288Z"
            fill="#BAB8C3" />
        </g>
        <defs>
          <clipPath id="clip0_4577_74295">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</div>
<div class="fragen-container">
  Haben Sie Fragen zur Datenverarbeitung oder sollten Sie der Auffassung sein, dass eine Verarbeitung Ihrer personenbezogenen Daten durch die Bundesverwaltung Ihre Rechte verletzt,
  wenden Sie sich bitte an die Datenschutzberaterin oder den Datenschutzberater der für die Website zuständigen Bundesstelle
  <div><input class="input-fragen" placeholder="Schreiben Sie mir, wenn Sie eine Frage haben" /> <button class="senden-btn">Senden</button></div>
</div>
