import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: { label: string; url: string }[] = []

  private breadcrumbsUrl: { label: string; url: string }[] = [
    { label: 'Prüfung', url: '/exams' },
    { label: 'Skill', url: '/skills' },
    { label: 'Teil', url: `/skills` },
  ]

  constructor() {}

  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.breadcrumbsUrl)
  }

  private createBreadcrumbs(breadcrumbs: { label: string; url: string }[] = []): { label: string; url: string }[] {
    for (const route of breadcrumbs) {
      if (route.label === 'Teil') {
        route.url += `/${localStorage.getItem('itemSectionID')}`
      }
    }
    return breadcrumbs
  }
}
