<!-- <app-breadcrumb></app-breadcrumb> -->
<div class="content-wrapper">
  <div class="thema-wrapper">
    <app-title-block title="Thema" class="topic-title"></app-title-block>
    <div class="thema-container" *ngIf="cases$ && cases$">
      <app-custom-scroll class="grid-3">
        <div *ngFor="let case of cases$ | async">
          <app-thema-item-component [item]="case" [routerLink]="['/conversation', case.id]"></app-thema-item-component>
        </div>
      </app-custom-scroll>
    </div>
  </div>
  <app-exams-component [detailData]="detailExam" class="topics-page"></app-exams-component>
</div>
