import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectToastDuration, selectToastMessage, selectToastType, selectToastVisibility } from '../../../redux/selectors/app.selectors'
import { hideToast, setTimer, showToast } from '../../../redux/actions/exams.actions'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ToastService } from '../../services/toast/toast.service'

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          visibility: 'hidden',
          right: '-100%',
        })
      ),
      state(
        'open',
        style({
          right: 'calc(-100% + 300px)',
        })
      ),
      transition('open <=> closed', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class CustomToastComponent implements OnInit {
  isVisibleToast$!: Observable<boolean>
  message$!: Observable<string>
  duration$!: Observable<number>
  type$!: Observable<string>

  iconSrc: string = '../../../../assets/toast-icon/error.png'
  textToast: string = 'Ups. Etwas ist schief gelaufen. Versuch es bitte nochmal.'
  typeToast!: string

  @ViewChild('timeToastBar', { static: false }) progressBar!: ElementRef
  progressInterval!: any

  data: any

  constructor(public toastService: ToastService) {}

  ngOnInit() {
    this.toastService.open.subscribe(data => {
      console.log(data.isVisible)
      if (data.isVisible) {
        this.countDown()
        this.typeToast = data.type as string
        this.textToast = data.message
        this.iconSrc = `../../../../assets/toast-icon/${data.type}.png`
      }
    })
  }

  countDown() {
    this.progressBar.nativeElement.style.width = this.toastService.data.durationWidth

    this.progressInterval = setInterval(() => {
      const width = parseInt(this.progressBar.nativeElement.style.width, 10)
      if (width <= 0) {
        this.toastService.hideToast()
        clearInterval(this.progressInterval)
        return
      }
      this.toastService.data.durationWidth = String(width - 2)
      this.progressBar.nativeElement.style.width = this.toastService.data.durationWidth + '%'
    }, 50)
  }

  stopCountDown() {
    clearInterval(this.progressInterval)
  }

  hideToast() {
    clearInterval(this.progressInterval)
    this.toastService.hideToast()
  }

  /*   constructor(private store: Store) {
    this.isVisibleToast$ = this.store.pipe(select(selectToastVisibility()))
    this.message$ = this.store.pipe(select(selectToastMessage()))
    this.duration$ = this.store.pipe(select(selectToastDuration()))
    this.type$ = this.store.pipe(select(selectToastType()))

    this.duration$.subscribe(duration => {
      if (duration > 0) {
        setTimeout(() => {
          this.store.dispatch(hideToast())
        }, duration)
      }
    })
  }

  showToast(message: string, type: string, duration: number = 0) {
    this.store.dispatch(showToast({ messageToast: message, typeToast: type }));
    this.store.dispatch(setTimer({ duration }));
  }

  hideToast() {
    this.store.dispatch(hideToast());
  } */
}
