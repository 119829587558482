import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

// import { AuthGuard } from 'src/app/guards/auth.guard';
import { SharedModule } from 'src/app/shared/shared.module'
import { JuridicalComponent } from 'src/app/lernberg/pages/juridical/juridical.component'
import { RechtlichesComponent } from './rechtlihes/rehtliches.component'
import { RechtlihesRoutingModule } from './rechtliches-routing.module'
import { DatenschutzComponent } from './datenschutzerklärung/datenschutz.component'
import { AgbComponent } from './agb/agb.component'

@NgModule({
  declarations: [JuridicalComponent, RechtlichesComponent, DatenschutzComponent, AgbComponent],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, RechtlihesRoutingModule, SharedModule],
  // providers: [AuthGuard],
})
export class RechtlichesModule {}
