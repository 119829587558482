import { Component, Input, OnInit } from '@angular/core'
import { faqData } from 'src/app/features/contacts/model/faq'

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  data = faqData
  ngOnInit(): void {}
}
