import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-teil-block',
  templateUrl: './teil-block.component.html',
  styleUrls: ['./teil-block.component.scss'],
})
export class TeilBlockComponent {
  @Input() item?: any
}
