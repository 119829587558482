import { Component, Input } from '@angular/core'
import { TooltipPosition } from '../../directive/tooltip.enums'
// import { AuthService } from 'src/app/auth/auth.service'

@Component({
  selector: 'app-profile-layouts',
  templateUrl: './profile-layouts.component.html',
  styleUrls: ['./profile-layouts.component.scss'],
})
export class ProfileLayoutsComponent {
  lawRoute!: string // '/law';
  contactsRoute!: string // '/contacts
  profileRoute!: string // '/profile

  hasUser = false

  tooltipPosition = TooltipPosition
  tooltipUser = false

  constructor() {
    // this.hasUser = this.authService.isLoggedIn()
  }

  // getProfile() {
  //   this.authService.getProfile();
  // }
}
