import { Injectable } from '@angular/core'
import { IToastState } from '../../../redux/state.models'
import { Subject } from 'rxjs'

export enum toastTypes {
  error,
  success,
  warning,
  info,
}

export interface IToastData {
  message: string
  isVisible?: boolean
  type?: string
  durationWidth?: string
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  data!: IToastData
  public open = new Subject<IToastData>()

  initialToast(data: IToastData) {
    this.data = { ...data, isVisible: true, durationWidth: '100%' }
    this.open.next(this.data)
  }

  hideToast() {
    this.data = { ...this.data, isVisible: false }
    this.open.next(this.data)
  }
}
