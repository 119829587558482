import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState } from '../state.models'
import { state } from '@angular/animations'

export const selectAppState = createFeatureSelector<AppState>('app')

export const selectData = createSelector(selectAppState, state => state.data)

export const selectUserData = createSelector(selectAppState, state => state.user)

export const selectAllConversations = createSelector(selectAppState, state => state.conversations)

export const selectTypeConversation = createSelector(selectAppState, state => state.typeConversation)

export const selectExamById = (examId: string) =>
  createSelector(selectData, data => {
    const examsByIdExams = data.find(e => e.id === examId)
    if (examsByIdExams) {
      return examsByIdExams
    }
    return null
  })

export const selectSectionsById = (examId: string) =>
  createSelector(selectData, data => {
    const examsByIdExams = data.find(e => e.id === examId)
    if (examsByIdExams) {
      return examsByIdExams.sections
    }
    return null
  })

export const selectTopicsByExamAndSectionId = (examId: string, sectionId: string) =>
  createSelector(selectData, data => {
    const exam = data.find(e => e.id === examId)
    if (exam) {
      const section = exam.sections.find(s => s.id === sectionId)
      return section ? section.topics : null
    }
    return null
  })

export const selectCasesByExamSectionAndTopicId = (examId: string, sectionId: string, topicId: string) =>
  createSelector(selectData, data => {
    const exam = data.find(e => e.id === examId)
    if (exam) {
      const section = exam.sections.find(s => s.id === sectionId)
      if (section) {
        const topic = section.topics.find(t => t.id === topicId)

        return topic ? topic.cases : null
      }
    }
    return null
  })

export const selectAllQuestions = (caseId: string) =>
  createSelector(selectAppState, state => (state.questions && state.questions[caseId] ? state.questions[caseId].allQuestions : []))

export const selectAskedQuestions = (caseId: string) =>
  createSelector(selectAppState, state => (state && state.questions && state.questions[caseId] ? state.questions[caseId].askedQuestions : null))

export const selectIsOption = (caseId: string) =>
  createSelector(selectAppState, state => (state && state.questions && state.questions[caseId] && state.questions[caseId].isOption ? true : false))

export const selectAnswersForCase = () => createSelector(selectAppState, state => (state.questions ? state.answers : []))

export const selectLastQuestionId = (caseId: string) =>
  createSelector(selectAppState, state =>
    state && state.questions && state.questions[caseId].askedQuestions ? state.questions[caseId].askedQuestions[state.questions[caseId].askedQuestions.length - 1].id : []
  )

export const selectConversationInfoByID = (conversationId: string) =>
  createSelector(selectAllConversations, conversation => {
    if (!conversation || !Array.isArray(conversation)) {
      return null
    }
    return conversation.find((conv: any) => conv.id === conversationId)
  })

// export const selectQuestionsWithOptionFlag = (caseId: string) => createSelector(
//   selectAppState,
//   (state) => {
//     const allQuestions = state.questions && state.questions[caseId] ? state.questions[caseId].allQuestions : [];
//     const askedQuestions = state && state.questions && state.questions[caseId] ? state.questions[caseId].askedQuestions : [];

//     // Создаем новый массив вопросов с флагом isOption
//     return allQuestions.map(question => {
//       const isOption = askedQuestions.some(askedQuestion => askedQuestion.id === question.id && askedQuestion.options && askedQuestion.options.trim() !== '');
//       return { ...question, isOption };
//     });
//   }
// );

export const selectIsVisiblePreloader = () => createSelector(selectAppState, state => state.isVisiblePreloader)

export const selectToastMessage = () => createSelector(selectAppState, (state: AppState) => state.toast.message)

export const selectToastVisibility = () => createSelector(selectAppState, (state: AppState) => state.toast.isVisible)

export const selectToastDuration = () => createSelector(selectAppState, (state: AppState) => state.toast.duration)

export const selectToastType = () => createSelector(selectAppState, (state: AppState) => state.toast.type)

export const selectLevelExam = () =>
  createSelector(selectAppState, (state: AppState) => {
    return state.data.map(elem => ({
      id: elem.id,
      title: elem.title,
    }))
  })

export const selectAntwortByCaseId = (caseId: string) =>
  createSelector(selectAppState, state => (state.conversationAntwort && state.conversationAntwort[caseId] ? state.conversationAntwort[caseId] : ''))

export const selectDetailExam = (examId: string, sectionId: string, topicsId: string, caseId?: string) =>
  createSelector(selectAppState, state => {
    const returnObj = {
      titleExam: '',
      titleSection: '',
      topicsTitle: '',
      conversation_type: '',
      description_full: '',
      introduction: '',
      audioTitle: '',
      audioIntro: '',
    }
    const data = state.data
    let exam = null
    let section = null
    let topics = null

    data.forEach(examItem => {
      if (examItem.id === examId) {
        returnObj.titleExam = examItem.title
        exam = examItem
      }
    })

    exam!.sections.forEach((sectionItem: any) => {
      if (sectionItem.id === sectionId) {
        section = sectionItem
        returnObj.titleSection = sectionItem.title
      }
    })

    section!.topics.forEach((topicItem: any) => {
      if (topicItem.id === topicsId) {
        topics = topicItem
        returnObj.topicsTitle = topicItem.title
        returnObj.conversation_type = topicItem.conversation_type
        returnObj.description_full = topicItem.description_full
        returnObj.introduction = topicItem.introduction
      }
    })

    if (caseId) {
      topics!.cases.forEach((caseItem: any) => {
        if (caseItem.id === caseId) {
          returnObj.audioTitle = caseItem.title
          returnObj.audioIntro = caseItem.description
        }
      })
    }

    return returnObj
  })
