import { Component, ElementRef, Input, ViewChild } from '@angular/core'

@Component({
  selector: 'app-thema-item-component',
  templateUrl: './thema-item-component.component.html',
  styleUrls: ['./thema-item-component.component.scss'],
})
export class TopicItemComponent {
  @Input() item?: any
  @ViewChild('itemElement') itemElement!: ElementRef
}
