import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  Renderer2,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core'
import { TooltipComponent } from '../components/tooltip/tooltip.component'
import { TooltipPosition } from './tooltip.enums'

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') tooltipContent: string = ''
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT

  private componentRef: ComponentRef<any> | null = null

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.initializeTooltip()
  }

  private initializeTooltip() {
    if (this.componentRef === null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent)
      this.componentRef = componentFactory.create(this.injector)
      this.appRef.attachView(this.componentRef.hostView)
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
      document.body.appendChild(domElem)
      this.setTooltipComponentProperties()
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltipContent
      this.componentRef.instance.position = this.position

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect()

      switch (this.position) {
        case TooltipPosition.BELOW: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left)
          this.componentRef.instance.top = Math.round(bottom)
          break
        }
        case TooltipPosition.ABOVE: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left)
          this.componentRef.instance.top = Math.round(top)
          break
        }
        case TooltipPosition.RIGHT: {
          this.componentRef.instance.left = Math.round(right)
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2)
          break
        }
        case TooltipPosition.LEFT: {
          this.componentRef.instance.left = Math.round(left)
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2)
          break
        }
        default: {
          break
        }
      }
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy()
  }

  ngOnDestroy(): void {
    this.destroy()
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView)
      this.componentRef.destroy()
      this.componentRef = null
    }
  }
}
