<app-custom-toast class="toast"></app-custom-toast>
<div class="container">
  <div class="sidebar-container">
    <app-profile-layouts class="header"></app-profile-layouts>
  </div>
  <div class="main-container">
    <app-header></app-header>
    <main class="content">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
