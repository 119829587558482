<div class="container" [class.popular]="item.popular" [class.organization]="isOrganization">
  <div class="info">
    <div class="item-header">
      <div class="header-title">
        <h4>{{ item.id }}</h4>
        <span *ngIf="item.popular">Beliebteste</span>
      </div>
      <p>{{ item.title }}</p>
    </div>
  </div>

  <div class="option-item">
    {{ item.options.limits }}
  </div>

  <div class="option-item" *ngIf="item.options.history">
    {{ item.options.history }}
  </div>
  <div class="option-item" *ngIf="item.options.history === true">
    <img src="../../../../assets/selected-icon.png" alt="Selected icon" />
  </div>

  <div class="option-item" *ngIf="item.options.something1">
    {{ item.options.something1 }}
  </div>
  <div class="option-item" *ngIf="item.options.something1 === true">
    <img src="../../../../assets/selected-icon.png" alt="Selected icon" />
  </div>

  <div class="option-item" *ngIf="item.options.something2; else absent">
    <img src="../../../../assets/selected-icon.png" alt="Selected icon" />
  </div>

  <div class="option-item" *ngIf="item.options.something3; else absent">
    <img src="../../../../assets/selected-icon.png" alt="Selected icon" />
  </div>

  <ng-template #absent>
    <div class="option-item"></div>
  </ng-template>

  <div class="price-info">
    <div class="item-price" *ngIf="item.price">
      <span>{{ item.price | number: '1.2-2' }}€</span>
      <div class="price-conditions">
        <p>per user</p>
        <p>monatlich abgerechnet</p>
      </div>
    </div>

    <button *ngIf="item.isCurrent; else unset" class="button-current">Aktueller Plan</button>
    <ng-template #unset>
      <button>Upgraden auf {{ item.id }}</button>
    </ng-template>
  </div>
</div>
