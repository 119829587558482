import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-title-block',
  templateUrl: './title-block.component.html',
  styleUrls: ['./title-block.component.scss'],
})
export class TitleBlockComponent {
  @Input() title!: string
  @Input() isSelected?: boolean
}
