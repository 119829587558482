import { Component, OnInit } from '@angular/core'
import { ratesData } from '../../models/rates'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],
})
export class RatesComponent implements OnInit {
  ratesData: any = ratesData
  constructor(private pageStateService: PageStateService) {}

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('')
  }
}
