<ng-container *ngIf="askedQuestions$ | async as questions">
  <div class="conversation-container">
    <div *ngIf="isVisiblePreloader$ | async" class="preloader">
      <app-custom-loader></app-custom-loader>
    </div>

    <ng-container *ngIf="questions[0] !== null; else startBlock">
      <div class="conversation-wrapper" *ngIf="!(isVisiblePreloader$ | async)">
        <app-custom-scroll class="conversation" appScrollToBottom>
          <ng-container *ngIf="!(antwortText$ | async)">
            <app-conversation-answer> Um den Beitrag besser zu machen, beantworte bitte einige Fragen. </app-conversation-answer>
            <ng-container *ngFor="let message of questions; let i = index">
              <app-conversation-answer *ngIf="message">
                <p>{{ message.question }}</p>
              </app-conversation-answer>

              <div *ngIf="!message.answer" class="option-block">
                <div *ngIf="message.options">
                  <div *ngFor="let option of message.options.split('],')">
                    <button (click)="sendAnswerFromButtons(option)">{{ option.trim().slice(1, -1) }}</button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="message.answer">
                <app-conversation-answer class="user">
                  <p>{{ message.answer }}</p>
                </app-conversation-answer>
                <ng-container *ngIf="existingQuestions.length - 1 === i || questions[0] === null">
                  <app-conversation-answer> Vielen Dank. Wir sind start klar. </app-conversation-answer>

                  <div class="btn-group">
                    <button type="button" class="startButton" (click)="resetConversation()">Nochmal anfangen</button>
                    <button type="button" (click)="startConversation()" class="startButton">Starten</button>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <div *ngIf="antwortText$ | async as text">
            <div *ngIf="typeConversation === 'audio_dialog'">
              <div *ngFor="let message of text" class="audioDialog">
                <div [ngClass]="{ user: message.role === 'user', assistant: message.role === 'assistant' }">
                  {{ message.content }}
                </div>
              </div>
            </div>
            <div class="antwort" *ngIf="typeConversation === 'text_single'">
              <div *ngFor="let pharagraph of text | splitParagraphs">
                <div class="pharagraph">{{ pharagraph }}</div>
              </div>
            </div>
            <button type="button" class="startButton" (click)="resetConversation()">Nochmal anfangen</button>
          </div>
        </app-custom-scroll>
      </div>
    </ng-container>
    <ng-template #startBlock>
      <div *ngIf="!((isVisiblePreloader$ | async) || (antwortText$ | async))">
        <div class="startBlock">
          <app-conversation-answer> Wir sind start klar. </app-conversation-answer>
        </div>

        <div>
          <button type="button" (click)="startConversation()" class="startButton">Starten</button>
        </div>
      </div>

      <app-custom-scroll class="conversation" appScrollToBottom>
        <div *ngIf="antwortText$ | async as text">
          <div class="antwort">
            <div *ngFor="let pharagraph of text | splitParagraphs">
              <div class="pharagraph">{{ pharagraph }}</div>
            </div>
          </div>
          <button type="button" class="startButton" (click)="resetConversation()">Nochmal anfangen</button>
        </div>
      </app-custom-scroll>
    </ng-template>

    <div
      class="conversation-input-block"
      [formGroup]="answerForm"
      *ngIf="!((isOption$ | async) || existingQuestions.length === 0 || (antwortText$ | async) || (isVisiblePreloader$ | async))">
      <input type="text" placeholder="Write a message..." formControlName="answer" (keydown.enter)="sendAnswer()" />
      <div class="button-block">
        <button [disabled]="isOption$ | async" type="button" (click)="sendAnswer()">Senden</button>
      </div>
    </div>
  </div>
</ng-container>
