<div class="breadcrumb-container">
  <nav aria-label="breadcrumb">
    <span
      ><svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
        <path d="M15 4.75L4 18.6651L15 32.25" stroke="#2E294E" stroke-width="5" />
      </svg>
    </span>
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let first = first; let last = last">
        <span *ngIf="!first" class="arrow">&lt;</span>
        <ng-container>
          <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        </ng-container>
      </li>
    </ol>
  </nav>
</div>
