import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { selectAllConversations, selectData } from '../../../redux/selectors/app.selectors'
import { IResultItem } from 'src/app/redux/state.models'
import { IConversation } from '../../pages/conversation/pipes/searchConveration.pipe'

@Injectable({
  providedIn: 'root',
})
export class SelectorsService {
  data: any
  private dataSkill: string[] = []
  private dataExam: string[] = [] // done
  private dataTeil: string[] = []

  constructor(private store: Store) {
    // this.store.select(selectData).subscribe(val => (this.data = val))
    this.store.select(selectAllConversations).subscribe(val => (this.data = val))
    this.setDataExam()
    this.setDataSkill()
    this.setDataTeil()
    // this.setDataTopics()
  }

  getDataSkill() {
    return this.dataSkill
  }

  getDataExam() {
    return this.dataExam
  }

  getDataTopics() {
    return this.dataTeil
  }

  setDataExam() {
    // this.data.forEach((element: any) => {
    //   this.dataExam.push(this.changeExamDescription(element.description, element.level))
    // })
    if (this.data) {
      const examTitles = this.data.map((item: IResultItem) => item.exam_title)
      const uniqueExamTitles: string[] = [...new Set(examTitles)] as string[]
      this.dataExam.push(...uniqueExamTitles)
    }
  }

  setDataSkill() {
    if (this.data) {
      const skillTitles = this.data.map((item: IResultItem) => item.section_title)
      const uniqueSkillTitles: string[] = [...new Set(skillTitles)] as string[]
      this.dataSkill.push(...uniqueSkillTitles)
      console.log(this.dataSkill)
    }
  }

  setDataTeil() {
    if (this.data) {
      const teilTitles = this.data.map((item: IResultItem) => item.topic_title)
      const uniqueSkillTitles: string[] = [...new Set(teilTitles)] as string[]
      this.dataTeil.push(...uniqueSkillTitles)
      console.log(this.dataTeil)
    }
  }

  // setDataTopics() {
  //   console.log(this.data)
  //   const sectionsArr = this.data
  //     .map((elem: any) => {
  //       return elem.sections
  //     })
  //     .flat()
  //   const topicsArr = sectionsArr
  //     .map((elem: any) => {
  //       return elem.topics
  //     })
  //     .flat()

  //   topicsArr.forEach((element: any) => {
  //     this.dataTopics.push(this.changeTopicsTitle(element.title))
  //   })
  // }

  // changeExamDescription(description: string, level: string): string {
  //   const word = 'telc '.length
  //   const newDescription = description.substring(word, description.length - level.length)
  //   return `${level} ${newDescription}`
  // }

  // changeTopicsTitle(title: string): string {
  //   const defaultWord = 'Default'
  //   if (title === defaultWord) {
  //     return title
  //   }
  //   const newTitle = title.substring(3, title.length)
  //   return newTitle
  // }
}
