<div>
  <app-title-block title="Datenschutzerklärung" class="datenschutz-title title-width"></app-title-block>
</div>
<div class="datenschutz-section">
  <h5>Datenschutzerklärung</h5>
  <div class="datenschutz-blok">
    <div>
      Diese Datenschutzerklärung informiert darüber, wie Lernberg personenbezogene Daten von Kunden verarbeitet und welche Rechte Kunden in Bezug auf ihre personenbezogenen Daten
      haben. Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Lernberg verarbeitet
      personenbezogene Daten gemäß den geltenden Datenschutzvorschriften. Verantwortlich im Sinne dieser Datenschutzerklärung ist die Stelle, die über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten entscheidet.
    </div>
  </div>

  <h5>Inhaltsverzeichnis</h5>
  <div class="datenschutz-blok">
    <div>
      <ol type="1">
        <li>Auf welcher Basis verarbeitet Lernberg personenbezogene Daten?</li>
        <li>Welche Arten von personenbezogenen Daten verarbeitet Lernberg?</li>
        <li>Welche Rechte haben Kunden in Bezug auf ihre personenbezogenen Daten?</li>
        <li>Wo verarbeitet Lernberg personenbezogene Daten?</li>
        <li>Wie verwendet Lernberg Cookies und andere Tracking-Technologien?</li>
        <li>Wie lange speichert Lernberg personenbezogene Daten?</li>
        <li>Wie kann man Lernberg kontaktieren?</li>
        <li>Mit wem teilt Lernberg personenbezogene Daten und warum?</li>
        <li>Welche gemeinsamen Verantwortlichkeiten liegen vor?</li>
        <li>Welche Rechtsgrundlagen gelten nach der Datenschutz-Grundverordnung (DSGVO)?</li>
      </ol>
    </div>
  </div>

  <h5>1. Auf welcher Basis verarbeitet Lernberg personenbezogene Daten?</h5>
  <div class="datenschutz-blok">
    <div>Lernberg verarbeitet personenbezogene Daten von Kunden auf folgenden Rechtsgrundlagen:</div>
    <div>
      <ol type="I">
        <li>
          Mit der Einwilligung des Kunden: Lernberg verarbeitet personenbezogene Daten, wenn der Kunde ausdrücklich zugestimmt hat. Diese Zustimmung kann jederzeit widerrufen
          werden.
        </li>
        <li>
          Zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen: Lernberg verarbeitet personenbezogene Daten, um vertragliche Verpflichtungen zu erfüllen
          oder um Maßnahmen durchzuführen, die auf Anfrage des Kunden vor Vertragsabschluss erforderlich sind.
        </li>
        <li>
          Zur Erfüllung rechtlicher Verpflichtungen: Lernberg verarbeitet personenbezogene Daten, um gesetzlichen Verpflichtungen nachzukommen, wie z.B. Steuer- und
          Handelsgesetzen.
        </li>
        <li>
          Zur Wahrung berechtigter Interessen von Lernberg oder Dritten: Lernberg verarbeitet personenbezogene Daten zur Verfolgung berechtigter Interessen, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten des Kunden überwiegen. Solche Interessen können die Verbesserung der Dienstleistungen oder die Sicherstellung der
          IT-Sicherheit umfassen.
        </li>
      </ol>
    </div>
  </div>

  <h5>2. Welche Arten von personenbezogenen Daten verarbeitet Lernberg?</h5>
  <div class="datenschutz-blok">
    <div>Je nach Interaktion mit Lernberg werden unterschiedliche Arten von personenbezogenen Daten verarbeitet:</div>
    <h6><span>2.1</span> Nutzer der Lernberg-Plattform</h6>
    <div>
      <span>2.1.1</span> Daten bei der Registrierung und Profilerstellung: Lernberg erhebt bei der Registrierung und Profilerstellung Name, E-Mail-Adresse und Zahlungsdaten. Diese
      Daten werden verarbeitet, um die Dienste bereitzustellen, Support und Kommunikation zu ermöglichen sowie Zahlungen abzuwickeln. Die Rechtsgrundlagen hierfür sind die
      Vertragserfüllung, berechtigtes Interesse und rechtliche Verpflichtungen.
    </div>

    <div>
      <span>2.1.2</span> Lernverhalten, Wortschatz und Lernfortschritt: Lernberg erfasst Inhalte und Sprachaufzeichnungen der Nutzer, um die Dienste bereitzustellen, sie an die
      Bedürfnisse der Kunden anzupassen und die Aussprache zu überprüfen. Die Rechtsgrundlagen hierfür sind die Vertragserfüllung, berechtigtes Interesse und rechtliche
      Verpflichtungen.
    </div>

    <div>
      <span>2.1.3</span> Pseudonymisierte Informationen über das Verhalten auf der Plattform: Lernberg sammelt pseudonymisierte Daten wie Server-Logdateien, IP-Adressen und
      Benutzer-IDs zur Sicherheitsgewährleistung, Reichweitenmessung und Fehleranalyse. Die Rechtsgrundlagen hierfür sind berechtigtes Interesse und Einwilligung.
    </div>

    <div>
      <span>2.1.4</span> Feedback und Umfrageinformationen: Lernberg erhebt Feedback und Umfrageinformationen zur Produktoptimierung und möglichen Veröffentlichung zu Werbezwecken.
      Die Rechtsgrundlagen hierfür sind Einwilligung und berechtigtes Interesse.
    </div>

    <h6><span>2.2</span> Besucher der Webseiten</h6>
    <div>
      <span>2.2.1</span> Daten aus Kontaktformularen: Lernberg verarbeitet Namen, E-Mail-Adressen und Telefonnummern, die über Kontaktformulare eingehen, zur Kundenakquise sowie
      für Support und Kommunikation. Die Rechtsgrundlage hierfür ist berechtigtes Interesse.
    </div>

    <div>
      <span>2.2.2</span> Pseudonymisierte Informationen über das Gerät und den Browser: Lernberg erhebt pseudonymisierte Daten über Geräte und Browser zur Sicherheitsgewährleistung
      und Einbindung von Inhalten Dritter. Die Rechtsgrundlage hierfür ist berechtigtes Interesse.
    </div>

    <div>
      <span>2.2.3</span> Informationen über das Verhalten auf der Webseite: Lernberg sammelt IP-Adressen und Benutzer-IDs zur Reichweitenmessung, Fehleranalyse und für
      Remarketing-Zwecke. Die Rechtsgrundlagen hierfür sind Einwilligung und berechtigtes Interesse.
    </div>

    <h6><span>2.3</span> Ansprechpartner bei B2B-Kunden</h6>
    <div>
      Lernberg verarbeitet Namen, E-Mail-Adressen und Telefonnummern von Ansprechpartner bei B2B-Kunden zur Vertragsverwaltung, für Support und Kommunikation sowie zur Erfüllung
      rechtlicher Verpflichtungen. Die Rechtsgrundlagen hierfür sind berechtigtes Interesse und rechtliche Verpflichtung.
    </div>

    <h6><span>2.4</span> Bewerber</h6>
    <div>
      <span>2.4.1</span> Bewerbungsdaten: Lernberg erhebt Daten wie Lebenslauf und Werdegang von Bewerber zur Anbahnung eines Arbeitsverhältnisses und zur Erfüllung rechtlicher
      Aufbewahrungspflichten. Die Rechtsgrundlagen hierfür sind Vertragserfüllung, rechtliche Verpflichtung und Einwilligung.
    </div>

    <div>
      <span>2.4.2</span> Kontaktdaten im Bewerbungsprozess: Lernberg verarbeitet Kontaktdaten im Rahmen des Bewerbungsprozesses zur Dokumentation der Bewerbungen. Die
      Rechtsgrundlage hierfür ist berechtigtes Interesse.
    </div>

    <h6><span>2.5</span> Kontaktpersonen bei Dienstleistern, Lieferanten oder Geschäftspartnern</h6>
    <div>
      Lernberg verarbeitet Namen, E-Mail-Adressen und Telefonnummern von Kontaktpersonen bei Dienstleistern, Lieferanten oder Geschäftspartnern zur Vertragsverwaltung und zur
      Erfüllung rechtlicher Verpflichtungen. Die Rechtsgrundlagen hierfür sind berechtigtes Interesse und rechtliche Verpflichtung.
    </div>

    <h6><span>2.6</span> Besucher von Social-Media-Kanälen</h6>
    <div>
      Lernberg verarbeitet Namen, Kontaktinformationen und Nachrichten von Besucher der Social-Media-Kanäle zur Kontaktaufnahme und zur Teilnahme an Gewinnspielen. Die
      Rechtsgrundlagen hierfür sind berechtigtes Interesse und Vertragserfüllung
    </div>
  </div>

  <h5>Datenschutzerklärung</h5>
  <div class="datenschutz-blok">
    <div>
      Diese Datenschutzerklärung informiert darüber, wie Lernberg personenbezogene Daten von Kunden verarbeitet und welche Rechte Kunden in Bezug auf ihre personenbezogenen Daten
      haben. Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Lernberg verarbeitet
      personenbezogene Daten gemäß den geltenden Datenschutzvorschriften. Verantwortlich im Sinne dieser Datenschutzerklärung ist die Stelle, die über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten entscheidet.
    </div>
  </div>

  <h5>3. Welche Rechte haben Kunden in Bezug auf ihre personenbezogenen Daten?</h5>
  <div class="datenschutz-blok">
    Kunden haben nach geltendem Datenschutzrecht verschiedene Rechte. Dazu gehören:
    <div>
      <ul>
        <li>Das Recht auf Auskunft über die von Lernberg verarbeiteten personenbezogenen Daten.</li>
        <li>Das Recht auf Berichtigung unrichtiger oder unvollständiger personenbezogener Daten.</li>
        <li>Das Recht auf Löschung der personenbezogenen Daten, sofern keine gesetzlichen Aufbewahrungspflichten bestehen.</li>
        <li>Das Recht auf Einschränkung der Verarbeitung personenbezogener Daten.</li>
        <li>Das Recht auf Datenübertragbarkeit, d.h. die Bereitstellung der personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format.</li>
        <li>Das Recht, eine erteilte Einwilligung jederzeit zu widerrufen.</li>
        <li>
          Das Recht, Beschwerde bei einer Aufsichtsbehörde einzulegen, wenn Kunden der Ansicht sind, dass die Verarbeitung der personenbezogenen Daten gegen Datenschutzrecht
          verstößt.
        </li>
      </ul>
    </div>
  </div>

  <h5>4. Wo verarbeitet Lernberg personenbezogene Daten?</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg verarbeitet personenbezogene Daten sowohl innerhalb der Europäischen Union (EU) als auch in Drittländern. Bei der Übermittlung von personenbezogenen Daten in
      Drittländer stellt Lernberg sicher, dass angemessene Schutzmaßnahmen gemäß den geltenden Datenschutzvorschriften getroffen werden.
    </div>
  </div>

  <h5>5. Wie verwendet Lernberg Cookies und andere Tracking-Technologien?</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg verwendet Cookies und andere Tracking-Technologien, um die Nutzung der Dienste zu analysieren, die Dienstleistungen zu verbessern und für Marketingzwecke. Cookies
      sind kleine Textdateien, die auf deinem Gerät gespeichert werden, wenn du die Website besuchst. Weitere Informationen zur Verwendung von Cookies und anderen
      Tracking-Technologien findest du in der Cookie-Richtlinie von Lernberg.
    </div>
  </div>

  <h5>6. Wie lange speichert Lernberg personenbezogene Daten?</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg speichert personenbezogene Daten nur so lange, wie es für die Erfüllung der jeweiligen Zwecke notwendig ist oder gesetzliche Aufbewahrungspflichten bestehen. Nach
      Ablauf der Aufbewahrungsfrist werden die Daten sicher gelöscht oder anonymisiert.
    </div>
  </div>

  <h5>7. Wie kann man Lernberg kontaktieren?</h5>
  <div class="datenschutz-blok">
    <div>
      Für Fragen zur Datenschutzerklärung oder zur Ausübung von Rechten können Kunden Lernberg unter den folgenden Kontaktdaten erreichen: Lernberg
      <ul>
        <li>[Adresse]</li>
        <li>[Telefonnummer]</li>
        <li>[E-Mail-Adresse]</li>
      </ul>
    </div>
  </div>

  <h5>8. Mit wem teilt Lernberg personenbezogene Daten und warum?</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg teilt personenbezogene Daten nur mit Dritten, wenn dies für die Erfüllung der Dienstleistung notwendig ist, eine rechtliche Verpflichtung besteht oder der Kunde
      eingewilligt hat. Zu den Dritten gehören:

      <ul>
        <li>Dienstleister, die Lernberg bei der Bereitstellung der Dienste unterstützen.</li>
        <li>Behörden, wenn dies gesetzlich vorgeschrieben ist.</li>
        <li>Andere Unternehmen, wenn dies zur Erfüllung vertraglicher Verpflichtungen notwendig ist.</li>
      </ul>
    </div>
  </div>

  <h5>9. Welche gemeinsamen Verantwortlichkeiten liegen vor?</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg und bestimmte Drittanbieter können gemeinsam für die Verarbeitung personenbezogener Daten verantwortlich sein. In solchen Fällen werden die Verantwortlichkeiten in
      einer Vereinbarung festgelegt, die regelt, wie die gemeinsamen Verantwortlichkeiten wahrgenommen werden.
    </div>
  </div>

  <h5>10. Welche Rechtsgrundlagen gelten nach der Datenschutz-Grundverordnung (DSGVO)?</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg stützt die Verarbeitung personenbezogener Daten auf die in der DSGVO festgelegten Rechtsgrundlagen. Diese umfassen:

      <ul>
        <li><span>Einwilligung:</span> Wenn Kunden der Verarbeitung ihrer personenbezogenen Daten zugestimmt haben. Relevanter Artikel der DSGVO: Art. 6 (1) (a) DSGVO</li>
        <li>
          <span>Vertragserfüllung:</span> Wenn die Verarbeitung notwendig ist, um einen Vertrag zu erfüllen oder um auf Anfrage des Kunden vor Vertragsabschluss Maßnahmen zu
          ergreifen. Relevanter Artikel der DSGVO: Art. 6 (1) (b) DSGVO
        </li>
        <li>
          <span>Rechtliche Verpflichtung:</span> Wenn Lernberg personenbezogene Daten verarbeiten muss, um gesetzlichen Verpflichtungen nachzukommen. Relevanter Artikel der DSGVO:
          Art. 6 (1) (c) DSGVO
        </li>
        <li>
          <span>Berechtigtes Interesse:</span> Wenn die Verarbeitung zur Wahrung der berechtigten Interessen von Lernberg oder Dritten notwendig ist, es sei denn, die Interessen
          oder Grundrechte und Grundfreiheiten des Kunden überwiegen. Relevanter Artikel der DSGVO: Art. 6 (1) (f) DSGVO
        </li>
      </ul>
    </div>
  </div>

  <h5>11. Änderungen dieser Datenschutzerklärung</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg behält sich das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Kunden werden über wesentliche Änderungen informiert. Die jeweils aktuelle Version der
      Datenschutzerklärung kann auf der Webseite von Lernberg eingesehen werden.
    </div>
  </div>

  <h5>12. Kontaktinformationen</h5>
  <div class="datenschutz-blok">
    <div>
      Für Fragen zur Datenschutzerklärung oder zur Ausübung deiner Rechte kannst du Lernberg unter den folgenden Kontaktdaten erreichen: Lernberg
      <ul>
        <li>[Adresse]</li>
        <li>[Telefonnummer]</li>
        <li>[E-Mail-Adresse]</li>
      </ul>
    </div>
  </div>

  <h5>13. Beschwerderecht bei Aufsichtsbehörden</h5>
  <div class="datenschutz-blok">
    <div>
      Sollten Kunden der Ansicht sein, dass die Verarbeitung ihrer personenbezogenen Daten gegen die DSGVO verstößt, haben sie das Recht, Beschwerde bei einer Aufsichtsbehörde
      einzulegen. Die für Lernberg zuständige Aufsichtsbehörde ist:
    </div>
    <div>[Name und Adresse der Aufsichtsbehörde]</div>
  </div>

  <h5>14. Sicherheitsmaßnahmen</h5>
  <div class="datenschutz-blok">
    <div>
      Lernberg ergreift geeignete technische und organisatorische Maßnahmen, um die Sicherheit personenbezogener Daten zu gewährleisten und sie vor unbefugtem Zugriff, Verlust oder
      Zerstörung zu schützen. Dazu gehören unter anderem Verschlüsselung, Firewalls und Zugangskontrollen.
    </div>
  </div>
</div>

<div class="datenschutz__data">Stand: 19.07.2024</div>
