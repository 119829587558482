import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchValueSubject: BehaviorSubject<string> = new BehaviorSubject<string>('')
  searchValue$ = this.searchValueSubject.asObservable()

  private searchValueSubjectConversationDone: BehaviorSubject<string> = new BehaviorSubject<string>('')
  searchValueDone$ = this.searchValueSubjectConversationDone.asObservable()

  settingsSearchValue: string = ''

  constructor() {}

  public changeSearchValue(str: string) {
    this.searchValueSubject.next(str)
  }

  public changeSearchValueDone(str: string) {
    this.searchValueSubjectConversationDone.next(str)
  }
}
