import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-sections-block',
  templateUrl: './sections-block.component.html',
  styleUrls: ['./sections-block.component.scss'],
})
export class SectionsBlockComponent {
  @Input() item?: any
}
