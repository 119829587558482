import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-audio-user',
  templateUrl: './audio-user.component.html',
  styleUrls: ['./audio-user.component.scss'],
})
export class AudioUserComponent implements OnInit {
  @Input() person!: string

  name!: string
  source!: string

  leraName: string = 'Lera Berg (KI)'
  userName: string = 'Begleiter (Du)'

  srcLera: string = '../../../../assets/leraBerg.jpg'
  srcUser: string = '../../../../assets/testImg.jpg'

  ngOnInit() {
    this.checkPerson(this.person)
  }

  checkPerson(personId: string) {
    if (personId == 'user') {
      this.name = this.userName
      this.source = this.srcUser
    } else {
      this.name = this.leraName
      this.source = this.srcLera
    }
  }
}
