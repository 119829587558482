import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function ForbiddenPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const customErrors: ValidationErrors = {}
    const upper = /[A-Z]/.test(control.value)
    const lower = /[a-z]/.test(control.value)
    const num = /[0-9]/.test(control.value)
    const char = /[!@#$%^&*(){}[\]<>?/|.:;_-]/.test(control.value)

    if (control.value.length < 8) {
      customErrors['minLength'] = { message: 'at least 8 characters' }
    }
    if (!upper || !lower) {
      customErrors['toCase'] = {
        message: 'a mixture of both uppercase and lowercase letters',
      }
    }
    if (!(num && (lower || upper))) {
      customErrors['numLetter'] = {
        message: 'a mixture of letters and numbers',
      }
    }
    if (!char) {
      customErrors['specialChar'] = {
        message: 'inclusion of at least one special character, e.g., ! @ # ? ]',
      }
    }
    return Object.keys(customErrors).length !== 0 ? customErrors : null
  }
}
