import { Component, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-exercise-component',
  templateUrl: './exercise-component.component.html',
  styleUrls: ['./exercise-component.component.scss'],
})
export class ExerciseComponent {
  @Input() detailData!: any
}
