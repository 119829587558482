import { Pipe, PipeTransform } from '@angular/core'

export interface IConversation {
  case_id: string
  case_title: string
  conversation_type: string
  exam_title: string
  finished_at: string
  id: string
  section_title: string
  started_at: string
  status: string
  topic_title: string
  user_id: string
}

@Pipe({
  name: 'searchconversation',
})
export class SearchConversationPipe implements PipeTransform {
  transform(topicsArr: IConversation[] | any | null, value: string) {
    return topicsArr!.filter((topic: IConversation) => {
      const lowerCaseTitle = topic.case_title.toLocaleLowerCase()
      const lowerCaseValue = value.toLowerCase()
      return lowerCaseTitle.includes(lowerCaseValue)
    })
  }
}
