import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { selectExamById, selectSectionsById, selectTopicsByExamAndSectionId } from 'src/app/redux/selectors/app.selectors'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-detailsexam',
  templateUrl: './detailsexam.component.html',
  styleUrls: ['./detailsexam.component.scss'],
})
export class DetailsexamComponent implements OnInit {
  sections$: Observable<any> | undefined
  exam$!: Observable<any> | null
  teil$!: Observable<any> | null
  itemId!: string
  sectionId!: string
  topicId!: string
  activeSkillBlock: string | null = null
  activeTheilBlock: string | null = null

  case$!: Observable<any> | null

  isSkillSelected = false
  isTeilSelected = false

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private location: Location,
    private pageStateService: PageStateService
  ) {
    if (this.router.url.length > 7) {
      this.isSkillSelected = true
      this.activeSkillBlock = this.router.url.substring(8)
    }
  }

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('back')
    setTimeout(() => {
      this.itemId = localStorage.getItem('examID') as string

      if (this.itemId) {
        this.exam$ = this.store.select(selectExamById(this.itemId))
        this.sections$ = this.store.select(selectSectionsById(this.itemId))
      }
    }, 100)
  }

  isSelectedSkill(itemSectionID: string) {
    this.isSkillSelected = true

    this.activeTheilBlock = null
    this.teil$ = this.store.select(selectTopicsByExamAndSectionId(this.itemId, itemSectionID))
    localStorage.setItem('itemSectionID', itemSectionID)
    this.activeSkillBlock = this.activeSkillBlock === itemSectionID ? null : itemSectionID

    this.router.navigate(['skills', itemSectionID])
  }

  // isSelectedTeil(itemTeilID: string) {
  //   this.isTeilSelected = true
  //   localStorage.setItem('itemTeilID', itemTeilID)

  //   this.activeTheilBlock = this.activeTheilBlock === itemTeilID ? null : itemTeilID
  //   this.router.navigate(['/topics', itemTeilID])
  // }

  goBack(): void {
    this.location.back()
  }
}
