import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-exams-component',
  templateUrl: './exams-component.component.html',
  styleUrls: ['./exams-component.component.scss'],
})
export class ExamsComponent implements OnInit {
  @Input() detailData!: any
  examField!: string

  ngOnInit(): void {
    this.examField = this.changeNameField(this.detailData.titleExam)
  }

  changeNameField(data: string): string {
    const examName = 'DTB B2'
    const examLabel = data.substring(0, examName.length)
    return `telc ${examLabel}`
  }
}
