<!-- <app-breadcrumb class="conversation-breadcrumb"></app-breadcrumb> -->
<!-- 
  <div>
  <button (click)="handleStartRecording()">Начать запись</button>
  <button (click)="handleStopRecording()">Остановить запись</button>

  <hr />

  <audio #audioPlayer controls [src]="audioRef">
    <source src="../../../../assets/audio/test.ogg" type="audio/ogg" />
  </audio>

  <button (click)="handlePlayRecording()">Воспроизвести запись</button>
  <button (click)="handlePauseRecording()">Остановить воспроизведение</button>

</div> -->

<!-- Нужно перенести, заготовка для всех loader -->
<!-- <div *ngIf="isVisiblePreloader$ | async" class="preloader">
  <app-custom-loader></app-custom-loader>
</div> -->

<div class="container">
  <div class="audio-wrapper">
    <div class="audio-container">
      <div class="audio-users"></div>
      <div class="audio-main">
        <div class="audio-animation">
          <app-audio-animation [ngClass]="{ containerStartBlur: isFirstVisit }" [ngClass]="{ containerStartBlur: isStopTimer }"></app-audio-animation>

          <div class="container-start" *ngIf="isStopTimer">
            <button class="custom-buttons button-start" (click)="transitionToNextPage()">Die Zeit ist um</button>
          </div>

          <div class="container-start" *ngIf="isFirstVisit">
            <button class="custom-buttons button-start" (click)="startAudioConversation()">Jetzt starten</button>
          </div>
        </div>

        <div class="audio-button" *ngIf="!isFirstVisit && !isStopTimer" [ngClass]="{ disabledButtons: isLeraBerg || isLoading }">
          <button *ngIf="!isUserRecording; else stopRecording" (click)="handleStartRecording()" class="button-mic">
            <span class="material-symbols-outlined icon-button"> mic </span>Sprechen
          </button>
          <ng-template #stopRecording>
            <button class="button-send" (click)="handleStopRecording()">
              <img src="../../../../assets/telegram_line.png" class="icon-button" alt="Telegram icon" />
              Senden
            </button>
          </ng-template>
        </div>
      </div>
      <div class="audio-users">
        <app-audio-user person="chat" [ngClass]="{ activeUser: isLeraBerg, inactiveUser: !isLeraBerg }"></app-audio-user>
        <app-audio-user person="user" [ngClass]="{ activeUser: isUser, inactiveUser: !isUser }"></app-audio-user>
      </div>

      <div class="position-dop">
        <!-- *ngIf="!isFirstVisit" -->
        <app-timer [ngClass]="{ endOfTime: isTimeOver }" class="position-dop-timer"></app-timer>
      </div>
    </div>

    <button *ngIf="!isFirstVisit && !isStopTimer" (click)="stopAudioConversation()" class="custom-buttons button-close">
      <span class="material-symbols-outlined icon-button"> close </span>Konversation beenden
    </button>
  </div>
  <div class="exam-container">
    <app-exercise-component [detailData]="detailExam"></app-exercise-component>
  </div>
</div>
