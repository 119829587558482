import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core'

@Directive({
  selector: '[appHighlight]',
})
export class HighlightDirective implements AfterViewInit {
  @Input('appHighlight') highlightWords: string[] = []

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.highlight()
  }

  private highlight() {
    let text = this.el.nativeElement.innerHTML
    this.highlightWords.forEach(word => {
      const regex = new RegExp(`(${word})`, 'gi')
      text = text.replace(regex, `<span class="highlight-welcome">${word}</span>`)
    })
    console.log(text)
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', text)
  }
}
