<!-- <div *ngIf="isVisibleToast$ | async"> -->

<div
  class="toast"
  [class.error]="typeToast === 'error' ? true : null"
  [class.success]="typeToast === 'success' ? true : null"
  (mouseenter)="stopCountDown()"
  (mouseleave)="countDown()"
  [@openClose]="toastService.data && toastService.data.isVisible ? 'open' : 'closed'">
  <div class="close" (click)="hideToast()">
    <svg width="12.828125" height="12.828125" viewBox="0 0 12.8281 12.8281" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector 1"
        d="M6.41406 3.58594L2.82812 0L0 2.82812L3.58594 6.41406L0 10L2.82812 12.8281L6.41406 9.24219L10 12.8281L12.8281 10L9.24219 6.41406L12.8281 2.82812L10 0L6.41406 3.58594Z"
        fill="#CCEAC4"
        fill-opacity="1.000000"
        fill-rule="evenodd" />
    </svg>
  </div>

  <div class="content">
    <div class="icon">
      <img src="{{ iconSrc }}" alt="Toast icon" />
    </div>

    <p>{{ textToast }}</p>
  </div>

  <div class="time" #timeToastBar></div>
</div>

<!-- </div> -->
