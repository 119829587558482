import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ExamService } from '../../pages/levels/service/exam.service'
import { Store } from '@ngrx/store'
import { selectConversationInfoByID } from 'src/app/redux/selectors/app.selectors'

@Component({
  selector: 'app-chat-result',
  templateUrl: './chat-result.component.html',
  styleUrls: ['./chat-result.component.scss'],
})
export class ChatResultComponent {
  conversationId!: string
  conversationText!: string
  conversationDialog!: any[]

  conversationInfo!: any
  conversationType = 'audio_dialog'

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private examService: ExamService
  ) {
    this.route.paramMap.subscribe(params => {
      this.conversationId = params.get('id') || ''
      this.store.select(selectConversationInfoByID(this.conversationId)).subscribe((res: any) => {
        if (res) {
          this.conversationType = res.conversation_type
          this.conversationInfo = res
        }
      })
    })

    this.getConversationById(this.conversationId)
  }

  getConversationById(conversationId: string) {
    this.examService.getOneConversation(conversationId).subscribe(res => {
      this.conversationType === 'audio_dialog' ? (this.conversationDialog = res.dialog_json) : (this.conversationText = res.dialog_json[0].content)
    })
  }
}
