<div class="wrapper">
  <div class="container">
    <div class="close-button">
      <button>
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>

    <div class="register-container">
      <div class="register-block">
        <h1>Registrierung</h1>

        <form [formGroup]="registrationForm">
          <div class="display-block gap-4">
            <div class="display-block gap-3">
              <div class="input-fields-container">
                <div class="input-container">
                  <div class="error" *ngIf="userName?.invalid && (userName?.touched || userName?.dirty); else userNameLabel">
                    <span *ngIf="userName?.hasError('required')">Vorname</span>
                  </div>

                  <ng-template #userNameLabel> <label for="userName">Vorname</label> </ng-template>

                  <input type="text" id="userName" formControlName="userName" [class.error-border]="userName?.invalid && (userName?.touched || userName?.dirty)" />
                </div>

                <div class="input-container">
                  <div class="error" *ngIf="userLastName?.invalid && (userLastName?.touched || userLastName?.dirty); else userLastNameLabel">
                    <span *ngIf="userLastName?.hasError('required')">Nachname</span>
                  </div>

                  <ng-template #userLastNameLabel><label for="userLastName">Nachname</label></ng-template>

                  <input
                    type="text"
                    id="userLastName"
                    formControlName="userLastName"
                    [class.error-border]="userLastName?.invalid && (userLastName?.touched || userLastName?.dirty)" />
                </div>

                <div class="input-container">
                  <div class="error" *ngIf="userEmail?.invalid && (userEmail?.touched || userEmail?.dirty); else emailLabel">
                    <span *ngIf="userEmail?.hasError('required')">E-Mail-Adresse</span>
                    <span *ngIf="userEmail?.hasError('email')">The login email is invalid</span>
                  </div>

                  <ng-template #emailLabel> <label for="email">E-Mail-Adresse</label> </ng-template>
                  <input type="email" id="email" formControlName="userEmail" [class.error-border]="userEmail?.invalid && (userEmail?.touched || userEmail?.dirty)" />
                </div>

                <div class="input-container">
                  <div class="error" *ngIf="password?.invalid && (password?.touched || password?.dirty); else passwordLabel">
                    <span *ngIf="password?.hasError('required')">Passwort</span>
                    <span *ngIf="canShowCustomErrors()">Your password isn't strong enough {{ showPasswordErrors() }}</span>
                  </div>

                  <ng-template #passwordLabel><label for="password">Passwort</label></ng-template>

                  <div class="input-wrapper">
                    <div *ngIf="!isVisiblePassword; else visible">
                      <span class="material-symbols-outlined password-icon" (click)="toggleVisiblePassword()"> visibility_off </span>
                    </div>

                    <ng-template #visible>
                      <span class="material-symbols-outlined password-icon" (click)="toggleVisiblePassword()">visibility</span>
                    </ng-template>

                    <input
                      id="password"
                      [type]="!isVisiblePassword ? 'password' : 'text'"
                      formControlName="password"
                      [class.error-border]="password?.invalid && (password?.touched || password?.dirty)" />
                  </div>
                </div>

                <div class="input-container">
                  <div class="error" *ngIf="confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty); else passwordRepeatLabel">
                    <span *ngIf="confirmPassword?.hasError('required')">Passwort bestätigen</span>
                  </div>

                  <span class="error" *ngIf="registrationForm.hasError('passwordMismatch') && confirmPassword?.touched"> Passwords do not match. </span>

                  <ng-template #passwordRepeatLabel><label for="password">Passwort bestätigen</label></ng-template>

                  <div class="input-wrapper">
                    <div *ngIf="!isVisibleRepeatPassword; else visibleRepeat">
                      <span class="material-symbols-outlined password-icon" (click)="toggleVisibleRepeatPassword()"> visibility_off </span>
                    </div>

                    <ng-template #visibleRepeat>
                      <span class="material-symbols-outlined password-icon" (click)="toggleVisibleRepeatPassword()">visibility</span>
                    </ng-template>

                    <input
                      id="passwordRepeat"
                      [type]="!isVisibleRepeatPassword ? 'password' : 'text'"
                      formControlName="passwordRepeat"
                      [class.error-border]="confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty)" />
                  </div>
                </div>
              </div>

              <div class="agreement-block align-block" [class.error-checkbox]="captcha?.invalid && (captcha?.touched || captcha?.dirty)">
                <input type="checkbox" name="captcha" id="captcha" formControlName="captcha" />
                <span>Ich bin kein Roboter</span>
              </div>
              <div class="agreement-block">
                <input type="checkbox" name="agreement" id="agreement" formControlName="agreement" />

                <span>Mit der Registrierung erkläre ich mich mit den AGB und den Datenschutzbestimmungen einverstanden.</span>
              </div>

              <div class="action-buttons-wrapper">
                <!-- <button (click)="submit()" [disabled]="registrationForm.invalid"> -->
                <button (click)="submit()">Jetzt registrieren</button>
                <div class="link-wrapper">Ich bin bereits registriert. <span routerLink="/auth/login">Anmelden</span></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
