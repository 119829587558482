import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AuthRoutingModule } from './auth-routing.module'
import { ReactiveFormsModule } from '@angular/forms'
import { ResetPasswordComponent } from './components/reset-password-page/components/reset-password/reset-password.component'
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component'
import { PasswordResultComponent } from './components/reset-password-page/components/password-result/password-result.component'
import { SavePasswordComponent } from './components/reset-password-page/components/save-password/save-password.component'
import { AuthService } from './services/auth.service'
import { RegistrationPageComponent } from './components/registration/registration-page.component'
import { LoginComponent } from './components/login/login.component'
import { RegistrationComponent } from './components/registration/components/registration/registration.component'
import { VerifyPasswordComponent } from './components/registration/components/verify-password/verify-password.component'

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ResetPasswordPageComponent,
    ResetPasswordComponent,
    PasswordResultComponent,
    SavePasswordComponent,
    RegistrationPageComponent,
    VerifyPasswordComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, ReactiveFormsModule],
  providers: [AuthService],
})
export class AuthModule {}
