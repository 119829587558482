import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs'
import { selectCasesByExamSectionAndTopicId, selectDetailExam } from 'src/app/redux/selectors/app.selectors'
import { SearchService } from './service/search.service'
import { ChatService } from '../../components/chat/services/chat.service'
import { PageStateService } from './service/page-state.service'

export interface Question {
  title: string
  question: string
  options: string
  id: string
  answer?: string
}

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements OnInit, AfterViewInit {
  caseId!: string
  examId = localStorage.getItem('examID') || ''
  itemSectionID = localStorage.getItem('itemSectionID') || ''
  itemTeilID = localStorage.getItem('itemTeilID') || ''

  cases$!: Observable<any>

  valuesSearch: string = ''
  settingsSearchValue: string = ''

  detailExam!: any

  @ViewChildren('themaItem') themaItems!: QueryList<ElementRef>

  constructor(
    private store: Store,
    private router: Router,
    private searchService: SearchService,
    private chatService: ChatService,
    private pageStateService: PageStateService
  ) {
    this.searchService.searchValue$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchTitle => {
      this.settingsSearchValue = searchTitle
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.chatService.caseId.subscribe(val => {
        this.caseId = val
      })

      this.scrollToSelectedItem()
    }, 0)
  }

  scrollToSelectedItem() {
    const selectedElement = this.themaItems.filter((item: any) => item.item.id === this.caseId)[0]

    if (selectedElement) {
      const selectedItem = (selectedElement as any).itemElement.nativeElement
      selectedItem.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('breadcrumbs')
    if (this.examId && this.itemTeilID && this.itemSectionID) {
      this.cases$ = this.store.select(selectCasesByExamSectionAndTopicId(this.examId, this.itemSectionID, this.itemTeilID))
      this.store.select(selectDetailExam(this.examId, this.itemSectionID, this.itemTeilID)).subscribe(val => (this.detailExam = val))
    }
  }

  changeConversation(caseId: string) {
    this.caseId = caseId
    // this.askedQuestions$ = this.store.select(selectAskedQuestions(this.caseId))
    // this.isOption$ = this.store.select(selectIsOption(this.caseId))
    // this.answerForm.reset()

    this.router.navigate([`conversation`, caseId])
  }

  changeSearch(changed: string) {
    this.searchService.changeSearchValue(changed)
  }
}
