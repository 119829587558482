import { ApplicationRef, Component, OnInit } from '@angular/core'
import { AudioService } from '../../services/audio.service'
import { TimerService } from '../../services/timer.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { ExamService } from '../levels/service/exam.service'
import { selectAskedQuestions, selectDetailExam, selectIsVisiblePreloader, selectToastVisibility, selectUserData } from '../../../redux/selectors/app.selectors'
import * as examsActions from '../../../redux/actions/exams.actions'
import { Observable } from 'rxjs'
import { ToastService } from '../../../shared/services/toast/toast.service'
import { errorMessage } from '../../../constants/constants'
import { PageStateService } from '../conversation/service/page-state.service'

interface IResponseAudio {
  id: string
  audio: string
}

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit {
  mediaRecorder: any
  audioChunks: any[] = []

  audio: HTMLAudioElement = new Audio()

  isLoading!: boolean
  isShadowAnimation!: boolean

  isLeraBerg!: boolean
  isUser!: boolean
  isUserRecording!: boolean

  recordingDuration: number = 0
  currentPlaybackTime: number = 0
  totalDuration: number = 0

  audioRef: any = null

  isFirstVisit!: boolean
  isTimeOver: boolean = false
  isStopTimer: boolean = false

  data!: any
  caseId!: string
  idConversation!: string

  examId = localStorage.getItem('examID') || ''
  itemSectionID = localStorage.getItem('itemSectionID') || ''
  itemTeilID = localStorage.getItem('itemTeilID') || ''

  userId!: string

  isVisiblePreloader$: Observable<boolean>

  detailExam!: any

  constructor(
    public audioService: AudioService,
    public timerService: TimerService,
    private route: ActivatedRoute,
    private store: Store,
    private examService: ExamService,
    private toast: ToastService,
    private router: Router,
    private pageStateService: PageStateService
  ) {
    this.isFirstVisit = this.audioService.getIsFirstVisit()

    this.audioService.isLeraBerg.subscribe((val: boolean) => (this.isLeraBerg = val))
    this.audioService.isUser.subscribe((val: boolean) => (this.isUser = val))

    this.audioService.isLoading.subscribe((val: boolean) => (this.isLoading = val))

    this.audioService.isUserRecording.subscribe((val: boolean) => (this.isUserRecording = val))

    this.audioService.isShadowAnimation.subscribe((val: boolean) => (this.isShadowAnimation = val))

    this.timerService.isTimeOver.subscribe(val => (this.isTimeOver = val))
    this.timerService.isStopTimer.subscribe(val => (this.isStopTimer = val))

    this.route.paramMap.subscribe(params => {
      this.caseId = params.get('id') || ''

      this.store.select(selectUserData).subscribe(s => (this.userId = s.userId))
    })

    this.isVisiblePreloader$ = this.store.select(selectIsVisiblePreloader())

    this.store.select(selectDetailExam(this.examId, this.itemSectionID, this.itemTeilID, this.caseId)).subscribe(val => (this.detailExam = val))
  }

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('breadcrumbs')
  }
  convertURIToBinary(dataURI: string) {
    const raw = atob(dataURI)
    const rawLength = raw.length
    const arr = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
      arr[i] = raw.charCodeAt(i)
    }
    return arr
  }

  audioBlobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        const base64Data = reader.result as string

        const BASE64_MARKER = ';base64,'
        const base64Index = base64Data.indexOf(BASE64_MARKER) + BASE64_MARKER.length
        const base64 = base64Data.substring(base64Index)

        resolve(base64)
      }

      reader.onerror = () => {
        reject(new Error('Ошибка при чтении файла'))
      }

      reader.readAsDataURL(blob)
    })
  }

  startAudioConversation() {
    console.log('Начать audio conversation')

    this.startConversation()
    this.audioService.setFirstVisit(false)
    this.isFirstVisit = this.audioService.getIsFirstVisit()
    this.startTimer()
  }

  stopAudioConversation() {
    console.log('Закончить audio conversation')
    this.stopTimer()
    this.handlePauseRecording()
    // this.handleStopRecording()

    if (this.isUser) {
      this.handleStopRecording()
    }
    if (this.isLeraBerg) {
      this.handlePauseRecording()
    }

    this.audioService.setFirstVisit(true)

    // !! Завершение конверсации на сервере
    this.examService.stopAudioConversation(this.idConversation).subscribe({
      next: response => {
        console.log('Post messages of group response was successful', response)
      },
      error: err => {
        console.log(err)
      },
    })

    // !Переход на страницу с выбором тем
    this.transitionToNextPage()
  }

  handlePlayRecording() {
    console.log('Начать воспроизведение')
    this.audioService.setIsShadowAnimation(true)
    this.audioService.setIsLeraBerg(true)

    const binary = this.convertURIToBinary(this.data)
    const blob = new Blob([binary], {
      type: 'audio/ogg',
    })
    const blobUrl = URL.createObjectURL(blob)

    //const audioUrl = '../../../../assets/audio/test.ogg'
    this.audio.src = blobUrl
    this.audio.play()

    // this.audioService.setIsRecording(true)

    this.audio.onended = () => {
      this.handlePauseRecording()
    }
  }

  handlePauseRecording() {
    console.log('Остановить воспроизведение')

    this.audio.pause()
    this.audio.currentTime = 0

    this.audioService.setIsShadowAnimation(false)
    this.audioService.setIsLeraBerg(false)
  }

  handleStartRecording() {
    console.log('Начать запись')

    this.audioService.setIsShadowAnimation(true)
    this.audioService.setIsUser(true)
    this.audioService.setIsUserRecording(true)

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        this.mediaRecorder = new MediaRecorder(stream)

        this.mediaRecorder.ondataavailable = (e: { data: any }) => {
          this.audioChunks.push(e.data)
        }

        this.mediaRecorder.onstop = () => {
          const blob = new Blob(this.audioChunks, { type: 'audio/ogg; codecs=opus' })

          const audioURL = URL.createObjectURL(blob)
          this.audioRef = audioURL

          this.audioBlobToBase64(blob)
            .then(base64Data => {
              this.sendRecording(base64Data)
            })
            .catch(error => {
              console.error('Ошибка:', error)
            })

          this.audioChunks = []
        }

        this.mediaRecorder.start()
      })
      .catch(err => {
        console.log('Нет доступа к микрофону:', err)
      })
  }

  handleStopRecording() {
    console.log('Остановить запись')

    const tracks = this.mediaRecorder.stream.getTracks() as MediaStreamTrack[]
    tracks.forEach(track => track.stop())
    if (this.mediaRecorder) {
      this.mediaRecorder.stop()
    }

    this.audioService.setIsShadowAnimation(false)
    this.audioService.setIsUser(false)
    this.audioService.setIsUserRecording(false)
    // this.audioService.setIsRecording(false);
  }

  sendRecording(audioBlob: string) {
    console.log('Отправить на сервер')
    // this.store.dispatch(examsActions.loadingPreloader({ isVisible: true }))
    this.audioService.setIsLoading(true)
    console.log(this.isLoading)
    this.examService.sendAudioMessage(this.idConversation, audioBlob).subscribe({
      next: response => {
        console.log('Post messages of group response was successful', response)
        // this.store.dispatch(examsActions.loadingPreloader({ isVisible: false }))
        this.audioService.setIsLoading(false)
        console.log(this.isLoading)
        this.data = (response as IResponseAudio).audio
        this.handlePlayRecording()
      },
      error: err => {
        this.store.dispatch(examsActions.loadingPreloader({ isVisible: false }))

        this.toast.initialToast({
          message: errorMessage,
          type: 'error',
        })
        console.log(err)
      },
    })
  }

  startConversation() {
    this.audioService.setIsLoading(true)
    //  this.audioService.setIsPlaying(true);
    this.audioService.setIsLeraBerg(true)
    this.audioService.setIsUser(false)

    let extraInfo
    this.store.select(selectAskedQuestions(this.caseId)).subscribe(res => {
      if (res) {
        extraInfo = res.map(item => ({ title: item.title, answer: item.answer }))
      } else {
        extraInfo = []
      }
    })

    if (extraInfo) {
      this.examService.startAudioConversation(this.userId, this.caseId, extraInfo).subscribe(res => {
        this.data = res.audio
        this.idConversation = res.id
        if (this.data) {
          this.audioService.setIsLoading(false)
          this.handlePlayRecording()
        }
      })
    }
  }

  startTimer() {
    this.timerService.startTimer()
  }

  stopTimer() {
    this.timerService.stopTimer()
  }

  getConversationById(conversationId: string) {
    this.examService
      .getOneConversation(conversationId)
      .subscribe(res => this.store.dispatch(examsActions.setConversationsAntwort({ caseId: this.caseId, antwort: res.dialog_json })))
    // это править, чтобы было и на конверсацию аудио тоже!!!
  }

  transitionToNextPage() {
    console.log('Переход на страницу транскрибации')
    this.getConversationById(this.idConversation)
    this.router.navigate(['/conversation', this.caseId])
  }
}
