import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-levels-block',
  templateUrl: './levels-block.component.html',
  styleUrls: ['./levels-block.component.scss'],
})
export class LevelsBlockComponent {
  @Input() item?: any
  @Input() selected?: boolean

  saveToLS(itemId: string) {
    setTimeout(() => {
      localStorage.setItem('examID', itemId)
    }, 0)
  }

  getImageUrl(examId: number): string {
    return `assets/exams/${examId}.png`
  }
}
