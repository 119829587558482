import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environment'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    withCredentials: true,
  }
  private mainApiEndpoint = environment.backend.baseUrl

  login(payload: { email: string; password: string }) {
    return this.http.post(`${this.mainApiEndpoint}/login`, payload, this.httpOptions)
    //получить токен и сохранить
  }

  registration(payload: { email: string; password: string }) {
    return this.http.post(`${this.mainApiEndpoint}/registration`, payload, this.httpOptions)
  }

  logout() {
    return this.http.get(`${this.mainApiEndpoint}/logout`, this.httpOptions)
    //удалить токен
  }

  isAuth() {
    //проверка на наличие токена, на залогиненность
    return !!localStorage.getItem('token')
  }
}
