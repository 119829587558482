<div class="welcome-container">
  <header class="header">
    <app-title-block [title]="isUserSelect ? sliderTitleConst : welcomeTitleConst" class="welcome-page"></app-title-block>
    <div class="role-title">
      <p>Aktueller plan:</p>
      <span>{{ role }}</span>
    </div>
  </header>

  <div *ngIf="!isUserSelect && role !== premiumAcc; else slider" class="user-select">
    <h2>Sie haben sich erfolgreich angemeldet und Sie sind jetzt startklar. Wählen Sie die Prüfung aus, die Sie trainieren möchten.</h2>
    <div class="level-actions">
      <button *ngFor="let level of levels$ | async" [class.active]="level.id === userSelectLevel" (click)="handleSelectedLevel(level)">
        <span>{{ level.title }}</span>
      </button>
    </div>

    <div *ngIf="userSelectLevel" class="next-button-wrapper">
      <button (click)="handleSaveLevel()" class="next-button"><span>Weiter</span></button>
    </div>
  </div>

  <ng-template #slider>
    <div class="slider-wrapper">
      <h2>Was kann ich nun trainieren</h2>
      <div class="slider-container">
        <div class="slider" *ngFor="let item of rolesIno[activeRole].items; index as i" [ngClass]="{ activeSlide: item.active }">
          <div class="slider-image">
            <div class="slider-image-container">
              <img [src]="item.image" alt="slide" />
            </div>
          </div>
          <div class="slider-content-container">
            <div class="slider-text-container">
              <p *ngFor="let text of item.text" [appHighlight]="rolesIno[activeRole].items[i].highlightText">{{ text }}</p>
            </div>
            <div class="slider-count-container">
              <span>{{ i + 1 }}</span
              >/<span>3</span>
            </div>
          </div>
        </div>

        <div class="slider-actions">
          <div *ngIf="isLastSlide; else firstSlide" class="actions">
            <button class="start-button" (click)="handleRedirectToProfile()"><span>Ich will mehr</span></button>
            <button class="next-button" (click)="handleRedirectToNextStep()"><span>Starten</span></button>
          </div>

          <ng-template #firstSlide>
            <button class="next-button" (click)="handleNextSlide()"><span>Weiter</span></button>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</div>
