import { Pipe, PipeTransform } from '@angular/core'

interface ITopics {
  description: string
  gpt_prompt: string
  id: string
  title: string
  topic_id: string
}

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(topicsArr: ITopics[] | any | null, value: string) {
    return topicsArr!.filter((topic: ITopics) => {
      const lowerCaseTitle = topic.title.toLocaleLowerCase()
      const lowerCaseValue = value.toLowerCase()
      return lowerCaseTitle.includes(lowerCaseValue)
    })
  }
}
