import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PageStateService } from '../conversation/service/page-state.service'
import { ExamService } from '../levels/service/exam.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private pageStateService: PageStateService,
    private examService: ExamService
  ) {}

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('')
    //  this.examService.authMe().subscribe(res => console.log(res))
  }

  start() {
    this.router.navigate(['/exams'])
  }
}
