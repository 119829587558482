import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs'
import { SearchService } from '../conversation/service/search.service'
import { SelectorsService } from '../../services/selectors/selectors.service'
import { ExamService } from '../levels/service/exam.service'
import { Store } from '@ngrx/store'
import { selectAllConversations, selectUserData } from '../../../redux/selectors/app.selectors'

import * as examsActions from '../../../redux/actions/exams.actions'
import { PageStateService } from '../conversation/service/page-state.service'

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsComponent implements OnInit {
  allArray!: any[]
  currentPage: number = 1
  itemsPerPage: number = 12
  totalPages: number[] = []
  p: number = 1

  valuesSearch: string = ''
  settingsSearchValue: string = ''

  dataSkill!: string[]
  selectedSkill: string | null = 'all'
  dataExam!: string[]
  selectedExam: string | null = 'all'
  dataTeils!: string[]
  selectedTeil: string | null = 'all'

  userId!: string

  allConversations$!: Observable<any>
  filteredConversations$!: Observable<any[]>
  filteredConversations: any

  constructor(
    private searchService: SearchService,
    private selectorsService: SelectorsService,
    private examService: ExamService,
    private store: Store,
    private pageStateService: PageStateService
  ) {
    this.store.select(selectUserData).subscribe(s => (this.userId = s.userId))
  }

  ngOnInit(): void {
    this.pageStateService.setBreadcrumbs('back')
    this.getAllConverstion()
    this.allConversations$ = this.store.select(selectAllConversations)
    this.allConversations$.subscribe(res => {
      this.allArray = res
      this.filteredConversations = [...res]
    })

    console.log(this.allArray)

    this.searchService.searchValueDone$.pipe(debounceTime(100), distinctUntilChanged()).subscribe(searchTitle => {
      this.settingsSearchValue = searchTitle
      this.p = 1
    })

    this.dataSkill = this.selectorsService.getDataSkill()
    this.dataExam = this.selectorsService.getDataExam()
    this.dataTeils = this.selectorsService.getDataTopics()
  }

  getAllConverstion() {
    this.examService.getAllConversation(this.userId).subscribe(res => this.store.dispatch(examsActions.loadConversationsSuccess({ conversations: res })))
  }

  changeSearch(changed: string) {
    this.searchService.changeSearchValueDone(changed)
  }

  filterConversations() {
    let filteredConversations: any[] = [...this.allArray]

    if (this.selectedExam !== 'all') {
      filteredConversations = filteredConversations.filter(conversation => conversation.exam_title === this.selectedExam)
    }

    if (this.selectedSkill !== 'all') {
      filteredConversations = filteredConversations.filter(conversation => conversation.section_title === this.selectedSkill)
    }

    if (this.selectedTeil !== 'all') {
      filteredConversations = filteredConversations.filter(conversation => conversation.topic_title === this.selectedTeil)
    }

    this.filteredConversations = filteredConversations
  }

  resetFilters() {
    this.selectedExam = null
    this.selectedSkill = null
    this.selectedTeil = null
    this.valuesSearch = ''
    this.searchService.changeSearchValueDone('')
    this.filterConversations()
  }
}
