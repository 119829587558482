<div class="container">
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <div>
    <div class="submenu">
      <h3>Rechtliches</h3>
      <div>
        <a routerLink="impressum" routerLinkActive="active">Impressum</a>
      </div>
      <div>
        <a routerLink="agb" routerLinkActive="active">AGB</a>
      </div>
      <div>
        <a routerLink="datenschutzerklärung" routerLinkActive="active">Datenschutzerklärung</a>
      </div>
    </div>
  </div>
</div>
