<h2>{{ detailData.topicsTitle }}</h2>

<div class="exam-container">
  <div class="exam-image">
    <img src="../../../../assets/telcC1.png" alt="Exam" />
  </div>
  <div class="exam-text">
    <h6>{{ examField }}</h6>
    <p>{{ detailData.titleSection }}</p>
  </div>
</div>

<div class="list">
  <div class="detailList" *ngFor="let pharagraph of detailData.description_full | splitParagraphs">
    <div class="pharagraph">{{ pharagraph }}</div>
  </div>

  <!-- <p>Deine Aufgabe: Du bist aufgefordert, ein formelles Gespräch mit Kolleg:innen zu führen. </p>
  <ol>
    <li>Du bekommst zwei von acht Themen, wählst ein davon und sprichst darüber etwa zwei Minuten. </li>
    <li>Anschließend stellt die Prüferin bzw. der Prüfer dir einige vertiefende Fragen, die du beantworten sollst.  </li>
    <li>Danach bittet die bzw. der zweite Prüfende deine Gesprächspartnerin bzw. deinen Gesprächspartner, einen Teilaspekt mit eigenen Worten zu erläutern.</li>
  </ol> -->
</div>

<div class="list">
  {{ detailData.introduction }}
</div>

<div class="switcher">
  <p>Ergebnis speichern</p>
  <div class="switcher-container">
    <label class="switch">
      <input type="checkbox" />
      <span class="slider round"></span>
    </label>
  </div>
</div>

<a class="conversation-result-link" routerLink="/results">Alle Ergebnisse ansehen</a>
