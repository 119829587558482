<div class="header-container">
  <div *ngIf="hasUser" (click)="navigateToUserInfo()" (keyup)="navigateToUserInfo()">
    <div class="user-image-wrapper" *ngIf="userAvatar; else iconAvatar" [appTooltip]="'Persönliches Konto'" [position]="tooltipPosition.BELOW">
      <img [src]="userAvatar" alt="photo-profile" tabindex="0" />
    </div>

    <ng-template #iconAvatar>
      <div class="icon-user-wrapper" [appTooltip]="'Persönliches Konto'" [position]="tooltipPosition.BELOW">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#737088">
          <path
            d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
        </svg>
      </div>
    </ng-template>
  </div>

  <span class="header-language soon" [appTooltip]="'Kommt bald'" [position]="tooltipPosition.BELOW">EN</span>

  <div *ngIf="hasUser; else loginTemplate" (click)="logout()">
    <a routerLinkActive="active-svg" class="icon-user-wrapper logout" [appTooltip]="'Logout'" [position]="tooltipPosition.BELOW">
      <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 8C8 6.34315 9.34315 5 11 5H18.5C19.3284 5 20 5.67157 20 6.5C20 7.32843 19.3284 8 18.5 8H11V32H18.5C19.3284 32 20 32.6716 20 33.5C20 34.3284 19.3284 35 18.5 35H11C9.34315 35 8 33.6569 8 32V8Z"
          fill="#737088" />
        <path
          d="M26.2551 12.419C26.8291 11.8603 27.7597 11.8603 28.3337 12.419L34.5695 18.4885C34.9818 18.8898 35.098 19.4701 34.918 19.9723C34.8474 20.1693 34.7312 20.3542 34.5695 20.5116L28.3337 26.5811C27.7597 27.1398 26.829 27.1398 26.255 26.5811C25.6811 26.0224 25.6811 25.1166 26.2551 24.558L29.9672 20.9449H19.4306C18.6405 20.9449 18 20.3044 18 19.5143C18 18.7242 18.6405 18.0837 19.4306 18.0837H29.9964L26.2551 14.4422C25.6811 13.8835 25.6811 12.9777 26.2551 12.419Z"
          fill="#737088" />
      </svg>
    </a>
  </div>

  <ng-template #loginTemplate>
    <a (click)="login()" routerLinkActive="active-svg" class="icon-user-wrapper logout" [appTooltip]="'Sign in/ Sign up'" [position]="tooltipPosition.BELOW">
      <svg width="40.000000" height="40.000000" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path
          id="Union"
          d="M8 8C8 6.34375 9.34375 5 11 5L18.5 5C19.3281 5 20 5.67188 20 6.5C20 7.32812 19.3281 8 18.5 8L11 8L11 32L18.5 32C19.3281 32 20 32.6719 20 33.5C20 34.3281 19.3281 35 18.5 35L11 35C9.34375 35 8 33.6562 8 32L8 8Z"
          clip-rule="evenodd"
          fill="#737088"
          fill-opacity="1.000000"
          fill-rule="evenodd" />
        <path
          id="Union"
          d="M26.71 26.58C26.14 27.14 25.21 27.14 24.64 26.58L18.4 20.51C17.99 20.1 17.87 19.53 18.05 19.02C18.13 18.83 18.24 18.64 18.4 18.48L24.64 12.41C25.21 11.85 26.14 11.85 26.71 12.41C27.29 12.97 27.29 13.88 26.71 14.44L23 18.05L33.54 18.05C34.33 18.05 34.97 18.69 34.97 19.48C34.97 20.27 34.33 20.91 33.54 20.91L22.97 20.91L26.71 24.55C27.29 25.11 27.29 26.02 26.71 26.58Z"
          fill="#737088"
          fill-opacity="1.000000"
          fill-rule="nonzero" />
      </svg>
    </a>
  </ng-template>
</div>

<div *ngIf="breadcrumbs === 'back'" class="breadcrumb-position">
  <div class="back-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none" (click)="goBack()">
      <path d="M15 1.75L4 15.6651L15 29.25" stroke="#2E294E" stroke-width="5" />
    </svg>
    <span class="btn-back" (click)="goBack()" (keyup)="goBack()" tabindex="1">Back</span>
  </div>
</div>

<div *ngIf="breadcrumbs === 'breadcrumbs'" class="breadcrumb-position">
  <app-breadcrumb></app-breadcrumb>
</div>
