import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  public isScroll: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public isScrollTopic: BehaviorSubject<boolean> = new BehaviorSubject(false)

  changeScroll(val: boolean) {
    this.isScroll.next(val)
  }

  changeScrollTopic(val: boolean) {
    this.isScrollTopic.next(val)
  }
}
