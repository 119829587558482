import { RouterModule, Routes } from '@angular/router'
// import { AuthGuard } from 'src/app/guards/auth.guard'
import { ContactsHelpComponent } from 'src/app/lernberg/pages/contacts-help/contacts-help.component'
import { FaqComponent } from './faq/faq.component'
import { ContactComponent } from './contact/contact.component'
import { NgModule } from '@angular/core'

const routes: Routes = [
  {
    path: '',
    component: ContactsHelpComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'faq', pathMatch: 'full' },
      { path: 'faq', component: FaqComponent },
      { path: 'contact', component: ContactComponent },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactsRoutingModule {}
