import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PageStateService } from 'src/app/lernberg/pages/conversation/service/page-state.service'
import { Location } from '@angular/common'

import { TooltipPosition } from '../../directive/tooltip.enums'
import { AuthService } from '../../../auth/services/auth.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  tooltipPosition = TooltipPosition

  hasUser = false
  userAvatar!: string // Путь к фото пользователя

  breadcrumbs!: string
  constructor(
    private router: Router,
    private pageStateService: PageStateService,
    private location: Location,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.pageStateService.breadcrumbs$.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs
    })

    this.hasUser = this.authService.isAuth()
    this.userAvatar = '../../../../assets/photoProfile.png'
  }

  goBack(): void {
    this.location.back()
  }

  logout(): void {
    this.authService.logout()
  }

  login(): void {
    this.router.navigate(['/auth/login'])
  }

  navigateToUserInfo(): void {
    this.router.navigate(['/user'])
  }
}
