<div class="container">
  <app-title-block title="Wähle einen Plan" class="rates-title title-width"></app-title-block>

  <div class="nav-buttons">
    <button class="button-nav inactive">Monatliche Abrechnung</button>
    <button class="button-nav active">Jährlich <span>(Bis zu 40% sparen)</span></button>
  </div>

  <div class="content">
    <div class="rates-first-item">
      <div class="empty-block"></div>
      <div class="option-item">Verfügbare Funktionen</div>
      <div class="option-item">Wiederholung Konversationen</div>
      <div class="option-item">Wiederholung Texte</div>
      <div class="option-item">Feedback & Fehlerbericht</div>
      <div class="option-item">Personalisierte Empfehlungen</div>
      <div class="empty-block"></div>
    </div>

    <div class="rates-item" *ngFor="let rateItem of ratesData"><app-rates-item [item]="rateItem"></app-rates-item></div>
  </div>
</div>
