<div class="container">
  <app-title-block title="Hoppla, Seite nicht gefunden" class="title-width"></app-title-block>

  <div class="main-block">
    <div class="main-content">
      <h2>404</h2>
      <span>Wir haben mehr als 404 Szenarien zum Üben, diese Seite aber nicht </span>
    </div>

    <button class="button">Zurück zur Startseite</button>
  </div>
</div>
