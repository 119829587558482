<div class="wrapper">
  <div class="image-wrapper">
    <span class="material-symbols-outlined"> check </span>
  </div>

  <h1>Bestätigen Sie Ihre E-Mail</h1>
  <p>Wir haben Ihnen einen Link an Ihre E-Mail-Adresse alexheller&#64;mail.com geschickt.</p>

  <div class="actions-buttons">
    <button (click)="routerNavigation()">Zur Anmeldung</button>
    <div class="link-wrapper">
      Sie haben keine E-Mail erhalten?
      <span>Erneut senden</span>
    </div>
  </div>
</div>
